import React from 'react';

import { InstanceData } from './instanceReducer';

const LinksValues = ({ instance }: { instance?: InstanceData }) => (
  <>
    <h2>Links</h2>
    <div className="ValueGrid">
      <span>GXWeb Link</span>
      <a href={instance?.gxweb_link} target="_blank" rel="noopener noreferrer">
        {instance?.gxweb_link}
      </a>

      <span>Gxweb Portal Link</span>
      <a href={instance?.portal_link} target="_blank" rel="noopener noreferrer">
        {instance?.portal_link}
      </a>
    </div>
  </>
);

export default LinksValues;
