import { get, post } from '../../utils/api';
import {
  DBServerActions,
  DBServerType,
  DB_SERVER_ACTIONS,
} from './databaseServerReducer';

export const fetchDBServer = async (
  dispatch: React.Dispatch<DBServerActions>,
  id?: string
) => {
  const resp = await get(`/database_server/${id}`);
  if (resp.ok) {
    dispatch({
      type: DB_SERVER_ACTIONS.DATABASE_SERVER,
      value: await resp.json(),
    });
  }
};

export const fetchHostingLocations = async (
  dispatch: React.Dispatch<DBServerActions>
) => {
  const resp = await get('/hosting_location');
  if (resp.ok) {
    dispatch({
      type: DB_SERVER_ACTIONS.HOSTING_LOCATIONS,
      value: await resp.json(),
    });
  }
};

export const saveDatabaseServer = async (
  dbServer?: DBServerType,
  id?: string
) => {
  if (!dbServer || !id) {
    return undefined;
  }

  const resp = await post(`/database_server/${id}`, {
    db_pass: dbServer.db_pass,
    db_user: dbServer.db_user,
    hosting_location_id: dbServer.hosting_location.id,
    hostname: dbServer.hostname,
    ip: dbServer.ip,
    nagios_check: dbServer.nagios_check,
    port: dbServer.port,
  });

  if (resp.ok) {
    window.location.reload();
  }
};
