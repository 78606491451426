import React from 'react';

import styles from './Buttons.module.scss';
import call, { IDType } from './call';

const HAProxyConfigButtons = ({ instanceID }: IDType) => {
  const haproxyCreate = async () =>
    await call('haproxy_configs_create', instanceID);
  const haproxyRemove = async () =>
    await call('haproxy_configs_remove', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={haproxyCreate}>HaProxy Configs Create</button>
      <button onClick={haproxyRemove}>HaProxy Configs Remove</button>
    </div>
  );
};

export default HAProxyConfigButtons;
