import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './employee_contracts.module.scss';
import { FinancialDetail,fileLT2MBValidator } from './EmployeeContractDetail';
import { post, get } from '../../utils/api';
import { useParams } from 'react-router-dom';
import InputUpload from '../../components/EditableInputs/InputUpload';

export type BankArray = {
    bank: BankData[];
};

export type BankData = {
    id: number;
    name: string;
};

interface ISecondChildProps {
    updateFinancialDetail: (arg: any) => void;
    currentContract: FinancialDetail;
}

const EmployeeFinancialInfo: React.FC<ISecondChildProps> = ({ updateFinancialDetail, currentContract }) => {
    const { jwt_token }: any = useParams();
    const [banks, setBanks] = useState([] as BankArray[]);
    const [selectBank, setSelectedBank] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [branchNumber, setBranchNumber] = useState('');
    const [bankAccountHolder, setBankAccountHolder] = useState('');
    const [taxNumber, setTaxNumber] = useState('');
    const [accountType, setAccountType] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [financialDetailComplete, setFinancialDetailComplete] = useState(true)
    const [errorBanks, setErrorSelectedBanks] = useState('');
    const [errorAccountNumber, setErrorAccountNumber] = useState('');
    const [errorBranchNumber, setErrorBranchNumber] = useState('');
    const [errorBankAccountHolder, setErrorBankAccountHolder] = useState('');
    const [errorTaxNumber, setErrorTaxNumber] = useState('');
    const [errorAccountType, setErrorAccountType] = useState('');
    const [error, setError] = useState(false);
    const [proofOfBanking, setProofOfBanking] = useState('');
    const [financialDetail, setFinancialDetail] = useState({
        'selectBank': selectBank,
        'accountNumber': accountNumber,
        'branchNumber': branchNumber,
        'bankAccountHolder': bankAccountHolder,
        'taxNumber': taxNumber,
        'accountType': accountType,
        'proofOfBanking':proofOfBanking,
    } as FinancialDetail);


    const fetchFromDb = async (url: any, setFn: any) => {
        const resp = await get(url);
        if (resp.ok) {
            setFn(await resp.json());
        }
        return resp.ok;
    };


    const handleChange = (e: any) => {
        setError(false);
        const { name, value } = e.target;
        setFinancialDetail(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        updateFinancialDetail(financialDetail);
    },);

    const formValidate = async (e: { preventDefault: () => void }) => {
        setError(false)
        if (financialDetail.selectBank === '' || financialDetail.selectBank === null) {
            setErrorSelectedBanks('Please select a bank');
            setError(true);
        } else {
            setErrorSelectedBanks('');
        }
        if (financialDetail.accountNumber === '' || financialDetail.accountNumber === null) {
            setErrorAccountNumber('Please enter your account number');
            setError(true);
        } else {
            setErrorAccountNumber('');
        }
        if (financialDetail.branchNumber === '' || financialDetail.branchNumber === null) {
            setErrorBranchNumber('Please enter branch number');
            setError(true);
        } else {
            setErrorBranchNumber('');
        }
        if (financialDetail.bankAccountHolder === '' || financialDetail.bankAccountHolder === null) {
            setErrorBankAccountHolder('Please enter bank account holder name');
            setError(true);
        } else {
            setErrorBankAccountHolder('');
        }
        if (financialDetail.taxNumber === '' || financialDetail.taxNumber === null) {
            setErrorTaxNumber('Please enter tax number');
            setError(true);
        } else {
            setErrorTaxNumber('');
        }
        if (financialDetail.accountType === '' || financialDetail.accountType === null) {
            setErrorAccountType('Please select a account type');
            setError(true);
        } else {
            setErrorAccountType('');
        }
        if(error===true){
            e.preventDefault();
        }
      };

    useEffect(() => {
        fetchFromDb('/get_simplepay_banks', setBanks);
    }, []);

    const loadCurrentContract = () => {
        if (currentContract) {
            setFinancialDetail(currentContract)
        }
    }

    useEffect(() => {
        setTimeout(function () {
            if (loaded === false) {
                loadCurrentContract()
                setLoaded(true)
            }
        }, 1000);
    },);

    const save_employee_personal_details = async (e: any) => {
        if (financialDetail.selectBank === '' || financialDetail.selectBank === null) {
            setError(true);
        }else if (financialDetail.accountNumber === '' || financialDetail.accountNumber === null) {
            setError(true);
        }else if (financialDetail.branchNumber === '' || financialDetail.branchNumber === null) {
            setError(true);
        }else if (financialDetail.bankAccountHolder === '' || financialDetail.bankAccountHolder === null) {
            setError(true);
        }else if (financialDetail.taxNumber === '' || financialDetail.taxNumber === null) {
            setError(true);
        }else if (financialDetail.accountType === '' || financialDetail.accountType === null) {
            setError(true);
        }else if(error===false){
            setFinancialDetailComplete(true);
            const resp = await post(`/save_employee/save_financial_detail/${jwt_token}`, {
                'financialInfo':financialDetail,
                'financialDetailComplete':financialDetailComplete,
                'proofOfBanking':proofOfBanking
            });
            if (resp.ok) {
                return resp.ok;
            };
        }
        
    };

    const InputSelecter = ({ test_case }: any) => (
        <option value={test_case.name} key={test_case.id}>{test_case.name}</option>
    );

    return (
        <form onSubmit={formValidate}>
            <div className={styles.FormWrapper}>
                <div className={styles.form}>
                    <div className={styles.column}>
                        <div>
                            <label>Bank:</label>
                            <select className={styles.input}
                                name="selectBank"
                                value={financialDetail.selectBank}
                                onChange={handleChange}>
                                <option> </option>
                                {banks?.map(bit => (
                                    bit?.bank.map(bankse => (
                                        <InputSelecter test_case={bankse} />
                                    ))
                                ))}
                            </select>
                            <span className={styles.errormsg}>{errorBanks}</span>
                        </div>
                        <br />
                        <div>
                            <label>Account Number:</label>
                            <input
                                className={styles.input}
                                type="text"
                                name="accountNumber"
                                value={financialDetail.accountNumber}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorAccountNumber}</span>
                        </div>
                        <br />
                        <div>
                            <label>Branch Number:</label>
                            <input
                                className={styles.input}
                                type="text"
                                name="branchNumber"
                                value={financialDetail.branchNumber}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorBranchNumber}</span>
                        </div>
                        <br />
                        <div>
                            <label>Bank Acccount Holder:</label>
                            <input
                                className={styles.input}
                                type="text"
                                name="bankAccountHolder"
                                value={financialDetail.bankAccountHolder}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorBankAccountHolder}</span>
                        </div>
                        <br />
                        <div>
                            <label>Income Tax Number(Compulsory - contact SARS if you dont have the number):</label>
                            <input
                                className={styles.input}
                                type="text"
                                name="taxNumber"
                                value={financialDetail.taxNumber}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorTaxNumber}</span>
                        </div>
                        <br />
                        <div>
                            <label>Account Type:</label>
                            <select className={styles.input}
                                name="accountType"
                                value={financialDetail.accountType}
                                onChange={(handleChange)}>
                                <option> </option>
                                <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                                <option key={2} value="Savings">Savings</option>
                            </select>
                            <span className={styles.errormsg}>{errorAccountType}</span>
                        </div>
                        <br />
                        <div>
                            <label>Proof Of Banking Details: </label>
                                <InputUpload
                                    className="input"
                                    accept="image/*, application/pdf"
                                    errors={fileLT2MBValidator(proofOfBanking) ? fileLT2MBValidator(proofOfBanking) : null}
                                    onChange={(event: any) => setProofOfBanking(event)}
                                />
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.btn_footer}>
                        <button className={styles.save_button} onClick={save_employee_personal_details}>Complete</button>
                    </div>
                </div>
            </div>
        </form>
    )

}

export default EmployeeFinancialInfo; 
