import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import Many2OneInput from '../../components/EditableInputs/Many2OneInput/Many2OneInput';
import TextInput from '../../components/EditableInputs/TextInput';
import EditSaveDiscardButtons from '../../components/EditSaveDiscardButtons/EditSaveDiscardButtons';
import {
  fetchDBLogin,
  fetchDatabaseServers,
  saveDatabaseLogin,
} from './databaseLoginAPI';
import reducer, {
  DBLoginInitialState,
  DB_LOGIN_ACTIONS,
} from './databaseLoginReducer';

const DatabaseLogin: React.FC = () => {
  const { id }: any = useParams();

  const [{ editing, dbLogin, dbServers }, dispatch] = useReducer(
    reducer,
    DBLoginInitialState
  );

  useEffect(() => {
    if (id !== undefined) {
      fetchDBLogin(dispatch, id);
    }
  }, [id]);

  useEffect(() => {
    if (editing) {
      fetchDatabaseServers(dispatch);
    }
  }, [editing]);

  if (id === undefined) {
    return null;
  }

  return (
    <div className="Page">
      <EditSaveDiscardButtons
        editing={editing}
        saveFn={() => saveDatabaseLogin(dbLogin, id)}
        setEditing={(value) =>
          dispatch({ type: DB_LOGIN_ACTIONS.EDITING, value })
        }
      />
      <h1>{dbLogin?.name}</h1>

      <h2>Database Login Settings</h2>
      <div className="ValueGrid">
        <TextInput
          editing={editing}
          name="Database Name"
          onChange={(value) =>
            dispatch({ type: DB_LOGIN_ACTIONS.DB_LOGIN_NAME, value })
          }
          value={dbLogin?.name}
        />

        <TextInput
          editing={editing}
          name="Login User"
          onChange={(value) =>
            dispatch({ type: DB_LOGIN_ACTIONS.DB_LOGIN_USER, value })
          }
          value={dbLogin?.user}
        />

        <TextInput
          editing={editing}
          name="Login Password"
          onChange={(value) =>
            dispatch({ type: DB_LOGIN_ACTIONS.DB_LOGIN_PASSWORD, value })
          }
          value={dbLogin?.password}
        />

        <Many2OneInput
          editing={editing}
          name="DB Server"
          link={`/database_server/${dbLogin?.server.id}`}
          onChange={(value) =>
            dispatch({ type: DB_LOGIN_ACTIONS.DB_LOGIN_SERVER, value })
          }
          value={dbLogin?.server}
          values={dbServers}
        />
      </div>
    </div>
  );
};

export default DatabaseLogin;
