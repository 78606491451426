import React from 'react';

import styles from './Buttons.module.scss';
import call, { IDType } from './call';

const GXWebButtons = ({ instanceID }: IDType) => {
  const assignPort = async () => await call('gxweb_port_assign', instanceID);
  const gxwebImagePull = async () => await call('gxweb_image_pull', instanceID);
  const gxwebRemove = async () => await call('gxweb_remove', instanceID);
  const gxwebRestart = async () => await call('gxweb_restart', instanceID);
  const gxwebStart = async () => await call('gxweb_start', instanceID);
  const unassignPort = async () =>
    await call('gxweb_port_unassign', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={assignPort}>Assign Port Number</button>
      <button onClick={unassignPort}>Unassign Port Number</button>
      <button onClick={gxwebImagePull}>Pull Image</button>
      <button onClick={gxwebStart}>GXWeb Start</button>
      <button onClick={gxwebRestart}>GXWeb Restart</button>
      <button onClick={gxwebRemove}>GXWeb Remove</button>
    </div>
  );
};

export default GXWebButtons;
