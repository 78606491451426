import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import CheckBoxInput from '../../components/EditableInputs/CheckBoxInput';
import Many2OneInput from '../../components/EditableInputs/Many2OneInput/Many2OneInput';
import NumberInput from '../../components/EditableInputs/NumberInput';
import TextInput from '../../components/EditableInputs/TextInput';
import EditSaveDiscardButtons from '../../components/EditSaveDiscardButtons/EditSaveDiscardButtons';
import {
  fetchDBServer,
  fetchHostingLocations,
  saveDatabaseServer,
} from './databaseServerAPI';
import reducer, {
  DBServerInitialState,
  DB_SERVER_ACTIONS,
} from './databaseServerReducer';

const DatabaseServer: React.FC = () => {
  const { id }: any = useParams();

  const [{ editing, dbServer, hostingLocations }, dispatch] = useReducer(
    reducer,
    DBServerInitialState
  );

  useEffect(() => {
    if (id !== undefined) {
      fetchDBServer(dispatch, id);
    }
  }, [id]);

  useEffect(() => {
    if (editing) {
      fetchHostingLocations(dispatch);
    }
  }, [editing]);

  if (id === undefined) {
    return null;
  }

  return (
    <div className="Page">
      <EditSaveDiscardButtons
        editing={editing}
        saveFn={() => saveDatabaseServer(dbServer, id)}
        setEditing={(value: boolean) =>
          dispatch({ type: DB_SERVER_ACTIONS.EDITING, value })
        }
      />
      <h1>{dbServer?.hostname}</h1>

      <h2>Database Server Settings</h2>
      <div className="ValueGrid">
        <TextInput
          editing={editing}
          name="IP Address"
          onChange={(value) =>
            dispatch({ type: DB_SERVER_ACTIONS.DB_SERVER_IP, value })
          }
          value={dbServer?.ip}
        />
        <TextInput
          editing={editing}
          name="Hostname"
          onChange={(value) =>
            dispatch({ type: DB_SERVER_ACTIONS.DB_SERVER_HOSTNAME, value })
          }
          value={dbServer?.hostname}
        />
        <NumberInput
          editing={editing}
          name="Port"
          onChange={(value) =>
            dispatch({ type: DB_SERVER_ACTIONS.DB_SERVER_PORT, value })
          }
          value={dbServer?.port}
          min={0}
          max={65535}
          step={1}
        />
        <Many2OneInput
          editing={editing}
          link={`/hosting_location/${dbServer?.hosting_location.id}`}
          name="Hosting Location"
          onChange={(value) =>
            dispatch({
              type: DB_SERVER_ACTIONS.DB_SERVER_HOSTING_LOCATION,
              value,
            })
          }
          value={dbServer?.hosting_location}
          values={hostingLocations}
        />
        <TextInput
          editing={editing}
          name="DB Username"
          onChange={(value) =>
            dispatch({ type: DB_SERVER_ACTIONS.DB_SERVER_DB_USER, value })
          }
          value={dbServer?.db_user}
        />
        <TextInput
          editing={editing}
          name="DB Password"
          onChange={(value) =>
            dispatch({ type: DB_SERVER_ACTIONS.DB_SERVER_DB_PASS, value })
          }
          value={dbServer?.db_pass}
        />
        <CheckBoxInput
          editing={editing}
          name="Nagios Checks"
          onChange={(value) =>
            dispatch({ type: DB_SERVER_ACTIONS.DB_SERVER_NAGIOS, value })
          }
          checked={dbServer?.nagios_check}
        />
      </div>
    </div>
  );
};

export default DatabaseServer;
