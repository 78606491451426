import React from 'react';

import { get } from './utils/api';

export type User =
  | {
      partner_id: number;
      user_id: number;
      employee_id: number;
      email: string;
      name: string;
    }
  | undefined;

export const fetchUser = async (
  setUser: React.Dispatch<React.SetStateAction<User>>
) => {
  const resp = await get('/user/me', false);

  if (resp.ok) {
    setUser((await resp.json()) as User);
  } else {
    return (window.location.href = '/login');
  }
};

const UserContext = React.createContext(undefined as User);
export default UserContext;
