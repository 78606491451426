import Many2OneType from '../components/EditableInputs/Many2OneInput/Many2OneTypes';
import { get } from './api';

type Many2OneCallback = (value?: Many2OneType[]) => void;

const fetchMany2One = async (url: string, callback: Many2OneCallback) => {
  const resp = await get(url);
  if (resp.ok) {
    callback(await resp.json());
  }
};

export const fetchEmployees = async (callback: Many2OneCallback) =>
  fetchMany2One('/employee', callback);
export const fetchHostingLocations = async (callback: Many2OneCallback) =>
  fetchMany2One('/hosting_location', callback);
export const fetchQuotePriceLists = async (
  specialityID: number,
  callback: Many2OneCallback
) =>
  fetchMany2One(
    `/quotation/price_list?speciality_id=${specialityID}`,
    callback
  );
export const fetchSpecialities = async (callback: Many2OneCallback) =>
  fetchMany2One('/quotation/speciality', callback);

export const fetchCategories = async (callback: Many2OneCallback) =>
fetchMany2One('/category', callback);
