import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import SelectInput from '../../components/EditableInputs/SelectInput';
import TextInput from '../../components/EditableInputs/TextInput';
import EditSaveDiscardButtons from '../../components/EditSaveDiscardButtons/EditSaveDiscardButtons';
import {
  fetchHostingLocation,
  saveHostingLocation,
} from './hostingLocationAPI';
import reducer, {
  HLGroupTypeMap,
  HLInitialState,
  HL_ACTIONS,
} from './hostingLocationReducer';

const HostingLocation: React.FC = () => {
  const { id }: any = useParams();

  const [{ editing, hostingLocation }, dispatch] = useReducer(
    reducer,
    HLInitialState
  );

  useEffect(() => {
    if (id !== undefined) {
      fetchHostingLocation(dispatch, id);
    }
  }, [id]);

  return (
    <div className="Page">
      <EditSaveDiscardButtons
        editing={editing}
        saveFn={() => saveHostingLocation(hostingLocation, id)}
        setEditing={(value: boolean) =>
          dispatch({ type: HL_ACTIONS.EDITING, value })
        }
      />
      <h1>{hostingLocation?.name}</h1>

      <h2>Hosting Location Settings</h2>
      <div className="ValueGrid">
        <TextInput
          editing={editing}
          name="Name"
          onChange={(value) => dispatch({ type: HL_ACTIONS.HL_NAME, value })}
          value={hostingLocation?.name}
        />
        <SelectInput
          editing={editing}
          name="Group"
          onChange={(value) => dispatch({ type: HL_ACTIONS.HL_GROUP, value })}
          options={HLGroupTypeMap}
          value={hostingLocation?.group}
        />
        <TextInput
          editing={editing}
          name="Public IP"
          onChange={(value) =>
            dispatch({ type: HL_ACTIONS.HL_PUBLIC_IP, value })
          }
          value={hostingLocation?.public_ip}
        />
        <TextInput
          editing={editing}
          name="Root Domain"
          onChange={(value) =>
            dispatch({ type: HL_ACTIONS.HL_ROOT_DOMAIN, value })
          }
          value={hostingLocation?.root_domain}
        />
      </div>
    </div>
  );
};

export default HostingLocation;
