export type ActiveType =
  | 'active'
  | 'testing'
  | 'never_activated'
  | 'deactivated';

export const activeTypeMap: Record<ActiveType, string> = {
  active: 'Active',
  testing: 'Testing',
  never_activated: 'Never Activated',
  deactivated: 'Deactivated',
};

export const activeTypeName = (type: ActiveType) => activeTypeMap[type];

export type InstanceListItem = {
  id: number;
  active_type: ActiveType;
  link: string;
  version: string;
  data_center: string;
  hosting_group: string;
  cluster: string;
  database: string;
};
