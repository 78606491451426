import { get, post } from '../../utils/api';
import {
  HOSTING_SERVER_ACTIONS,
  HostingServerActions,
  HostingServerType,
} from './HostingServerReducer';

export const fetchHostingServer = async (
  dispatch: React.Dispatch<HostingServerActions>,
  id?: string
) => {
  const resp = await get(`/hosting_server/${id}`);
  if (resp.ok) {
    dispatch({
      type: HOSTING_SERVER_ACTIONS.HOSTING_SERVER,
      value: await resp.json(),
    });
  } else {
    console.error(await resp.text());
  }
};

export const saveHostingServer = async (
  hostingServer?: HostingServerType,
  id?: string
) => {
  if (!hostingServer || !id) {
    return undefined;
  }

  const resp = await post(`/hosting_server/${id}`, {
    hostname: hostingServer.hostname,
    local_ip: hostingServer.local_ip,
    hosting_location_id: hostingServer.hosting_location?.id,
  });

  if (resp.ok) {
    window.location.reload();
  }
};
