import React from 'react';

import Links from './Links/Links';


const Nav = () => (
  <nav>
    {/* <div className={styles.NavLogo}>
      <img src="/icon.svg" alt="home" />
  	</div> */}
    <Links />
  </nav>
);

export default Nav;
