import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './employee_contracts.module.scss';
import { QualificationDetail, fileLT2MBValidator } from './EmployeeContractDetail';
import { useParams } from 'react-router-dom';
import { post } from '../../utils/api';
import InputUpload from '../../components/EditableInputs/InputUpload';

interface IThirdChildProps {
    updateQualification: (arg: any) => void;
    currentContract: QualificationDetail;
}


const EmployeeQualification: React.FC<IThirdChildProps> = ({ updateQualification, currentContract }) => {
    const { jwt_token }: any = useParams();
    const [qualification, setQualification] = useState('');
    const [detailsOfQualification, setDetailsOfQualification] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [qualificationDetailComplete,setQualificationDetailComplete] = useState(true)
    const [errorQualification, setErrorQualification] = useState('');
    const [errorDetailsOfQualification, setErrorDetailsOfQualification] = useState('');
    const [error, setError] = useState(false);
    const [qualificationDocument, setQualificationDocument] = useState('');
    const [qualificationDetail, setQualificationDetail] = useState({
        'qualification': qualification,
        'detailsOfQualification': detailsOfQualification,
        'qualificationDetailComplete':qualificationDetailComplete,
        'qualificationDocument':qualificationDocument,
    } as QualificationDetail);

    const handleChange = (e: any) => {
        setError(false);
        const { name, value } = e.target;
        setQualificationDetail(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        updateQualification(qualificationDetail);
    },);

    const loadCurrentContract = () => {
        if (currentContract) {
            setQualificationDetail(currentContract);
        }
    }

    useEffect(() => {
        setTimeout(function () {
            if (loaded === false) {
                loadCurrentContract();
                setLoaded(true);
            }
        }, 1000);
    },);

    const formValidate = async (e: { preventDefault: () => void }) => {
        if (qualificationDetail.qualification === '' || qualificationDetail.qualification === null) {
            setErrorQualification('Please select your highest qualification');
            setError(true);
        } else {
            setErrorQualification('');
        }
        if (qualificationDetail.detailsOfQualification === '' || qualificationDetail.detailsOfQualification === null) {
            setErrorDetailsOfQualification('Please enter the details of your qualification');
            setError(true);
        } else {
            setErrorDetailsOfQualification('');
        }
        if (error === true) {
            e.preventDefault();
        }
      };

    const save_qualification_detail = async (e: any) => {
        if (qualificationDetail.qualification === '' || qualificationDetail.qualification === null) {
            setError(true);
        }else if (qualificationDetail.detailsOfQualification === '' || qualificationDetail.detailsOfQualification === null) {
            setError(true);
        }else if (error === false) {
            setQualificationDetailComplete(true);
            const resp = await post(`/save_employee/save_qualification_detail/${jwt_token}`, {
                'qualification': qualificationDetail,
                'qualificationDetailComplete':qualificationDetailComplete,
                'qualificationDocument':qualificationDocument,
            });
            if (resp.ok) {
                return resp.ok;
            }
        }
        
    };

    return (
        <form onSubmit={formValidate}>
            <div className={styles.FormWrapper}>
                <div className={styles.form}>
                    <div>
                        <label>Highest Qualification:</label>
                        <select className={styles.input}
                            name="qualification"
                            onChange={(handleChange)}
                            value={qualificationDetail.qualification}>
                            <option value={''} key={0}></option>
                            <option value={'grade12'} key={1}>Grade 12</option>
                            <option value={'certificate'} key={2}>Certificate</option>
                            <option value={'diploma'} key={3}>Diploma</option>
                            <option value={'degree'} key={4}>Degree</option>
                        </select>
                        <span className={styles.errormsg}>{errorQualification}</span>
                    </div>
                    <br />
                    <div>
                        <label>Details Of Qualification:</label>
                        <input
                            className={styles.input}
                            type="text"
                            value={qualificationDetail.detailsOfQualification}
                            name="detailsOfQualification"
                            onChange={(handleChange)}
                        />
                        <span className={styles.errormsg}>{errorDetailsOfQualification}</span>
                    </div>
                    <br />
                    <div>
                        <label>Highest Qualification Document</label>
                            <InputUpload
                                className="input"
                                accept="image/*, application/pdf"
                                errors={fileLT2MBValidator(qualificationDocument) ? fileLT2MBValidator(qualificationDocument) : null}
                                onChange={(event: any) => setQualificationDocument(event)}
                            />
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.btn_footer}>
                        <button className={styles.save_button} onClick={save_qualification_detail}>Complete</button>
                    </div>
                </div>
            </div>
        </form>
    )

}

export default EmployeeQualification; 
