import React from 'react';

import ACMECertificateButtons from './ACMECertificateButtons';
import { IDType } from './call';
import CloudflareButtons from './CloudflareButtons';
import GXWebButtons from './GXWebButtons';
import GXWebNagiosButtons from './GXWebNagiosButtons';
import HAProxyConfigButtons from './HAProxyConfigButtons';
import PortalButtons from './PortalButtons';

const ActionButtons = ({ instanceID }: IDType) => (
  <>
    <ACMECertificateButtons {...{ instanceID }} />
    <CloudflareButtons {...{ instanceID }} />
    <GXWebButtons {...{ instanceID }} />
    <HAProxyConfigButtons {...{ instanceID }} />
    <GXWebNagiosButtons {...{ instanceID }} />
    <PortalButtons {...{ instanceID }} />
  </>
);

export default ActionButtons;
