import React from 'react';

import styles from './Buttons.module.scss';
import call, { IDType } from './call';

const PortalButtons = ({ instanceID }: IDType) => {
  const portalRemove = async () => await call('portal_remove', instanceID);
  const portalStart = async () => await call('portal_start', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={portalStart}>Portal Start</button>
      <button onClick={portalRemove}>Portal Remove</button>
    </div>
  );
};

export default PortalButtons;
