import React, { useEffect, useState } from 'react';

import styles from './Inputs.module.scss';

type NumberInputPropTypes = {
  editing: boolean;
  onChange: (value: number) => void;
  name?: string;
  max?: number;
  min?: number;
  step?: number;
  value?: number | string;
};

const clipValue = (value: number, min?: number, max?: number) => {
  if (min !== undefined && value < min) {
    return min;
  }
  if (max !== undefined && value > max) {
    return max;
  }
  return value;
};

const roundToStep = (value: number, step?: number) =>
  step === undefined
    ? value
    : Math.round(value * (1 / step) + Number.EPSILON) * step;

const NumberInput = ({
  editing,
  onChange,
  name,
  max,
  min,
  step,
  value,
}: NumberInputPropTypes) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!editing) {
      setTouched(false);
    }
  }, [editing]);

  const changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    onChange(e.target.valueAsNumber);
  };

  const blur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (touched) {
      onChange(clipValue(roundToStep(e.target.valueAsNumber, step), min, max));
    }
  };

  return (
    <>
      {name ? <span>{name}</span> : null}
      {editing ? (
        <input
          type="number"
          value={value}
          onChange={changed}
          onBlur={blur}
          className="control"
          step={step}
        />
      ) : (
        <span>{value}</span>
      )}
    </>
  );
};

export default NumberInput;
