import React, { useEffect, useState } from 'react';

import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';

type SelectInputPropTypes = {
  name: string;
  value: any;
  editing: boolean;
  onChange: (value: any) => void;
  options: Record<any, string>;
};

const SelectInput = ({
  name,
  value,
  editing,
  onChange,
  options,
}: SelectInputPropTypes) => {
  const [touched, setTouched] = useState(false);

  const setChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTouched(true);
    onChange(e.target.value);
  };

  useEffect(() => {
    if (!editing) {
      setTouched(false);
    }
  }, [editing]);

  return (
    <>
    <DesktopLayout>
      <span>{name}</span>
      {editing ? (
        <select
          onChange={setChanged}
          value={value}
        >
          {Object.entries(options).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
      ) : (
        <span>{options[value]}</span>
      )}
      </DesktopLayout>
      <MobileLayout>
        <span>{name}</span>
        {editing ? (
          <select
            onChange={setChanged}
            value={value}
            className={responsive.input}
          >
            {Object.entries(options).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        ) : (
          <span>{options[value]}</span>
        )}
      </MobileLayout>
    </>
  );
};

export default SelectInput;
