import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';

export enum HOSTING_SERVER_ACTIONS {
  EDITING,
  HOSTING_SERVER,
  SERVER_HOSTNAME,
  SERVER_LOCAL_IP,
  SERVER_LOCATION,
  SERVER_LOCATIONS,
}

export type HostingServerType = {
  hostname: string;
  local_ip: string;
  hosting_location?: Many2OneType;
};

export type HostingServerActions =
  | { type: HOSTING_SERVER_ACTIONS.EDITING; value: boolean }
  | { type: HOSTING_SERVER_ACTIONS.HOSTING_SERVER; value: HostingServerType }
  | { type: HOSTING_SERVER_ACTIONS.SERVER_HOSTNAME; value: string }
  | { type: HOSTING_SERVER_ACTIONS.SERVER_LOCAL_IP; value: string }
  | { type: HOSTING_SERVER_ACTIONS.SERVER_LOCATION; value?: Many2OneType }
  | { type: HOSTING_SERVER_ACTIONS.SERVER_LOCATIONS; value?: Many2OneType[] };

type HostingServerStateType = {
  editing: boolean;
  hostingServer?: HostingServerType;
  serverLocations?: Many2OneType[];
};

export const HostingServerInitialState = {
  editing: false,
  hostingServer: undefined,
  serverLocations: undefined,
} as HostingServerStateType;

const reducer = (
  state: HostingServerStateType,
  action: HostingServerActions
): HostingServerStateType => {
  switch (action.type) {
    case HOSTING_SERVER_ACTIONS.EDITING:
      return {
        ...state,
        editing: action.value,
      };
    case HOSTING_SERVER_ACTIONS.HOSTING_SERVER:
      return {
        ...state,
        hostingServer: action.value,
      };
    case HOSTING_SERVER_ACTIONS.SERVER_HOSTNAME:
      return {
        ...state,
        hostingServer: {
          ...state.hostingServer,
          hostname: action.value,
        } as HostingServerType,
      };
    case HOSTING_SERVER_ACTIONS.SERVER_LOCAL_IP:
      return {
        ...state,
        hostingServer: {
          ...state.hostingServer,
          local_ip: action.value,
        } as HostingServerType,
      };
    case HOSTING_SERVER_ACTIONS.SERVER_LOCATION:
      return {
        ...state,
        hostingServer: {
          ...state.hostingServer,
          hosting_location: action.value,
        } as HostingServerType,
      };
    case HOSTING_SERVER_ACTIONS.SERVER_LOCATIONS:
      return {
        ...state,
        serverLocations: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
