import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';

export enum DB_LOGIN_ACTIONS {
  DATABASE_LOGIN,
  DB_LOGIN_NAME,
  DB_LOGIN_PASSWORD,
  DB_LOGIN_SERVER,
  DB_LOGIN_SERVERS,
  DB_LOGIN_USER,
  EDITING,
}

export type DBLoginActions =
  | { type: DB_LOGIN_ACTIONS.EDITING; value: boolean }
  | { type: DB_LOGIN_ACTIONS.DATABASE_LOGIN; value: DBLoginType }
  | { type: DB_LOGIN_ACTIONS.DB_LOGIN_NAME; value: string }
  | { type: DB_LOGIN_ACTIONS.DB_LOGIN_PASSWORD; value: string }
  | { type: DB_LOGIN_ACTIONS.DB_LOGIN_SERVER; value?: Many2OneType }
  | { type: DB_LOGIN_ACTIONS.DB_LOGIN_SERVERS; value: Many2OneType[] }
  | { type: DB_LOGIN_ACTIONS.DB_LOGIN_USER; value: string };

export type DBLoginType = {
  name: string;
  password: string;
  server: Many2OneType;
  user: string;
};

type DBLoginStateType = {
  editing: boolean;
  dbLogin?: DBLoginType;
  dbServers?: Many2OneType[];
};

export const DBLoginInitialState = {
  editing: false,
  dbLogin: undefined,
  dbServers: undefined,
};

const reducer = (
  state: DBLoginStateType,
  action: DBLoginActions
): DBLoginStateType => {
  switch (action.type) {
    case DB_LOGIN_ACTIONS.DATABASE_LOGIN:
      return { ...state, dbLogin: action.value };
    case DB_LOGIN_ACTIONS.DB_LOGIN_NAME:
      return {
        ...state,
        dbLogin: { ...state.dbLogin, name: action.value } as DBLoginType,
      };
    case DB_LOGIN_ACTIONS.DB_LOGIN_PASSWORD:
      return {
        ...state,
        dbLogin: {
          ...state.dbLogin,
          password: action.value,
        } as DBLoginType,
      };
    case DB_LOGIN_ACTIONS.DB_LOGIN_SERVER:
      return {
        ...state,
        dbLogin: {
          ...state.dbLogin,
          server: action.value,
        } as DBLoginType,
      };
    case DB_LOGIN_ACTIONS.DB_LOGIN_SERVERS:
      return { ...state, dbServers: action.value };
    case DB_LOGIN_ACTIONS.DB_LOGIN_USER:
      return {
        ...state,
        dbLogin: { ...state.dbLogin, user: action.value } as DBLoginType,
      };
    case DB_LOGIN_ACTIONS.EDITING:
      return { ...state, editing: action.value };
    default:
      return state;
  }
};

export default reducer;
