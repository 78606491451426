import React from 'react';

import Many2OneInput from '../../components/EditableInputs/Many2OneInput/Many2OneInput';
import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';
import TextInput from '../../components/EditableInputs/TextInput';
import DatabaseButtons from './Buttons/DatabaseButtons';
import {
  INSTANCE_ACTIONS,
  InstanceActions,
  InstanceData,
} from './instanceReducer';

type InstanceValuesPropTypes = {
  instanceID: string | number[];
  instance?: InstanceData;
  editing: boolean;
  databases?: Many2OneType[];
  dispatch: React.Dispatch<InstanceActions>;
};

const InstanceValues = ({
  instanceID,
  instance,
  editing,
  databases,
  dispatch,
}: InstanceValuesPropTypes) => (
  <>
    <h2>Instance</h2>
    <div className="ValueGrid">
      <TextInput
        name="Identifier"
        value={instance?.identifier}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_IDENTIFIER, value })
        }
      />
      <Many2OneInput
        name="Database"
        value={instance?.database}
        link={`/database_login/${instance?.database.id}`}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_DATABASE, value })
        }
        values={databases}
      />
    </div>
    <DatabaseButtons {...{ instanceID }} />
  </>
);

export default InstanceValues;
