import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactPDF, { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import 'react-toastify/dist/ReactToastify.css';
import EmployeePersonalDetail from './EmployeePersonalDetail'
import styles from './employee_contracts.module.scss';
import IconButton from '@material-ui/core/IconButton';
import EmployeeContractTemplate from './EmployeeContractsTemplate';
import EmployeeContractTemplateSave from './EmployeeContractTemplatesave';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import EmployeeFinancialInfo from './EmployeeFinancialInfo';
import EmployeeQualification from './EmployeeQualification';
import EmployeeEmergencyContact from './EmployeeEmergencyContact';
import EmployeeMedicalAidInfo from './EmployeeMedicalAidInfo';
import EmployeeEmploymentDetails from './EmployeeEmploymentDetails';
import { ContractLine, PersonalDetail } from './EmployeeContractDetail';
import { post, get } from '../../utils/api';
import OTPFormValidateEmployeeSubmit from './OTPSubmitForm';


const viewer = StyleSheet.create({
    page: {
        position: 'absolute',
        height: "91%",
        width: "50%",
        right: 10,
        top: 70,
    },
});

const EmployeeContracts: React.FC = () => {
    const { jwt_token }: any = useParams();
    const [contractID, setContractId] = React.useState();
    const [openFinancial, setOpenFinancial] = React.useState(false);
    const [openQualification, setOpenQualification] = React.useState(false);
    const [openEmergencyContact, setOpenEmergencyContact] = React.useState(false);
    const [openEmploymentDetails, setOpenEmploymentDetails] = React.useState(false);
    const [personalDetails, setPersonalDetails] = React.useState({} as PersonalDetail);
    const [financialInfo, setFinancialInfo] = React.useState({} as any);
    const [qualification, setQualification] = React.useState({} as any);
    const [emergencyContactDetail, setEmergencyContactDetail] = React.useState({} as any);
    const [medicalAidDetail, setMedicalAidDetail] = React.useState({} as any);
    const [employmentDetails, setEmploymentDetail] = React.useState({} as any);
    const [currentContract, setCurrentContract] = React.useState({} as ContractLine);
    const [openMedicalAidInfo, setOpenMedicalAidInfo] = React.useState(false);
    const [openPersonal, setOpenPersonal] = React.useState(false);
    const [visible, setVisible] = useState(false);
    const [submitted, setSubmit] = useState(false);
    const [test, setTest] = useState(false);
    const [error, setError] = useState('')
    const [readyToSubmit, setReadyToSubmit] = useState(true);
    const updatePersonalDetail = (name: PersonalDetail): void => {
        setPersonalDetails(name)
    };

    const updateFinancialDetail = (name: any): void => {
        setFinancialInfo(name)
    };

    const updateQualification = (name: any): void => {
        setQualification(name)
    };

    const updateEmergencyContact = (name: any): void => {
        setEmergencyContactDetail(name)
    };

    const updateMedicalAidDetail = (name: any): void => {
        setMedicalAidDetail(name)
    };

    const updateEmploymentDetail = (name: any): void => {
        setEmploymentDetail(name)
    };

    const warningSubmit = (e: any) => {
        e.preventDefault();
        if(personalDetails.personalDetailComplete === false || financialInfo.financialDetailComplete === false ) {
            setReadyToSubmit(false);
            setError('Complete Personal Details Section To Submit Form')
        }else if(qualification.qualificationDetailComplete === false || qualification.qualificationDetailComplete === null) {
            setReadyToSubmit(false);
            setError('Complete Qualification Details Section To Submit Form')
        }else if(emergencyContactDetail.emergencyContactComplete === false || emergencyContactDetail.emergencyContactComplete === null) {
            setReadyToSubmit(false);
            setError('Complete Emergency Contact Details Section To Submit Form')
        }else if(employmentDetails.employmentDetailsComplete === false || employmentDetails.employmentDetailsComplete === null) {
            setReadyToSubmit(false);
            setError('Complete Employment Details Section To Submit Form')
        }else if(medicalAidDetail.medicalInformationComplete === false || medicalAidDetail.medicalInformationComplete === null) {
            setReadyToSubmit(false);
            setError('Complete Medical Aid Details Section To Submit Form')
        }else if(financialInfo.financialDetailComplete === false || financialInfo.financialDetailComplete === null) {
            setReadyToSubmit(false);
            setError('Complete Financial Info Section To Submit Form')
        }else if(readyToSubmit === true) {
            setVisible(!visible);
            setOpenPersonal(true);
            setOpenFinancial(true);
            setOpenQualification(true);
            setOpenEmergencyContact(true);
            setOpenMedicalAidInfo(true);
            setOpenEmploymentDetails(true);
            setTest(true);
        }
    }

    useEffect(() => {
        const onPageLoad = async () => {
            if(visible){
          const signedPdfRef = ReactPDF.pdf(<EmployeeContractTemplateSave personalDetail={await currentContract?.current_contract.personalDetail} financialDetail={await currentContract.current_contract?.financialInfo} qualificationDetail={await currentContract.current_contract?.qualification} emergencyContactDetail={await currentContract.current_contract?.emergencyContactDetail} medicalAidDetail={await currentContract.current_contract?.medicalAidDetail} employmentDetails={await currentContract.current_contract?.employmentDetails} />).toBlob();
          if(signedPdfRef){
            const signedPdfRefs = await signedPdfRef;
            const url = URL.createObjectURL(signedPdfRefs)
            new Promise((resolves) => {
              var reader = new FileReader();
              reader.readAsDataURL(signedPdfRefs); 
              reader.onloadend = function() {
                var base64data:any = reader.result;
                const base64s = base64data?.split(',')[1];
                post(`/save_employee/save_employee_information_form_pdf/${jwt_token}`, { 'base64s':base64s });
              }
          })
        };
            }
        }
    
        // Check if the page has already loaded
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
        }
      }, [test]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        setSubmit(true);
        
    };

    const fetchFromDb = async (url: any, setFn: any) => {
        const resp = await get(url);
        if (resp.ok) {
            setFn(await resp.json());
        }
        return resp.ok;
    };

    const save_employee = async (e: any) => {
        const resp = await post(`/save_employee/${jwt_token}`, {
            'personalDetails': personalDetails,
            'financialInfo': financialInfo,
            'qualification': qualification,
            'emergencyContactDetail': emergencyContactDetail,
            'medicalAidDetail': medicalAidDetail,
            'employmentDetails': employmentDetails,
            'contractID': contractID
        });
        if (resp.ok) {
            return resp.ok;
        }
    };

    useEffect(() => {
        fetchFromDb(`/get_employee_contract_from_jwt/${jwt_token}`, setCurrentContract);
    }, []);

    return (
        <>
            { currentContract ?
            <>
            {submitted ?
                <div className={styles.form_container}>
                    <PDFViewer style={viewer.page}>
                        <EmployeeContractTemplate personalDetail={currentContract?.current_contract.personalDetail} financialDetail={currentContract.current_contract?.financialInfo} qualificationDetail={currentContract.current_contract?.qualification} emergencyContactDetail={currentContract.current_contract?.emergencyContactDetail} medicalAidDetail={currentContract.current_contract?.medicalAidDetail} employmentDetails={currentContract.current_contract?.employmentDetails} />
                    </PDFViewer>
                    <div style={{ visibility: !visible ? "hidden" : "initial" }}>
                        <OTPFormValidateEmployeeSubmit></OTPFormValidateEmployeeSubmit>
                    </div>
                </div> :
                    <div className={styles.form_container}>
                        <br />
                        <label className={styles.heading}>NEW EMPLOYEE INFORMATION: EVERY FIELD MUST BE COMPLETED. IF NOT APPLICABLE, WRITE N/A</label>
                        <br />
                        <label className={styles.heading}>AFTER COMPLETING A SECTION PRESS THE COMPLETE BUTTON BEFORE MOVING ON.</label>
                        <br />
                        <br />
                        {openPersonal?
                            <div className={openPersonal ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenPersonal(!openPersonal)}
                                    >
                                        {openPersonal ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Personal Detail</label>
                                    <br />
                                </div>
                                <Collapse in={openPersonal} timeout="auto">
                                    <EmployeePersonalDetail updatePersonalDetail={updatePersonalDetail} currentContract={currentContract.current_contract?.personalDetail} />
                                </Collapse>
                            </div>:
                            <div className={!currentContract.current_contract?.personalDetail.personalDetailComplete ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenPersonal(!openPersonal)}
                                    >
                                        {!currentContract.current_contract?.personalDetail.personalDetailComplete ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Personal Detail</label>
                                    <br />
                                </div>
                                <Collapse in={!currentContract.current_contract?.personalDetail.personalDetailComplete} timeout="auto">
                                <EmployeePersonalDetail updatePersonalDetail={updatePersonalDetail} currentContract={currentContract.current_contract?.personalDetail} />
                                </Collapse>
                            </div>
                        }
                        {openFinancial ?
                            <div className={openFinancial ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenFinancial(!openFinancial)}
                                    >
                                        {openFinancial ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Financial Information</label>
                                    <br />
                                </div>
                                <Collapse in={openFinancial} timeout="auto">
                                    <EmployeeFinancialInfo updateFinancialDetail={updateFinancialDetail} currentContract={currentContract.current_contract?.financialInfo} />
                                </Collapse>
                            </div>:
                            <div className={!currentContract.current_contract?.financialInfo.financialDetailComplete ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenFinancial(!openFinancial)}
                                    >
                                        {!currentContract.current_contract?.financialInfo.financialDetailComplete ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Financial Information</label>
                                    <br />
                                </div>
                                <Collapse in={!currentContract.current_contract?.financialInfo.financialDetailComplete} timeout="auto">
                                    <EmployeeFinancialInfo updateFinancialDetail={updateFinancialDetail} currentContract={currentContract.current_contract?.financialInfo} />
                                </Collapse>
                            </div>
                        }
                        {openQualification ?
                            <div className={openQualification ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenQualification(!openQualification)}
                                    >
                                        {openQualification ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Qualification</label>
                                    <br />
                                </div>
                                <Collapse in={openQualification} timeout="auto">
                                    <EmployeeQualification updateQualification={updateQualification} currentContract={currentContract.current_contract?.qualification} />
                                </Collapse>
                            </div>:
                            <div className={!currentContract.current_contract?.qualification.qualificationDetailComplete ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenQualification(!openQualification)}
                                    >
                                        {!currentContract.current_contract?.qualification.qualificationDetailComplete ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Qualification</label>
                                    <br />
                                </div>
                                <Collapse in={!currentContract.current_contract?.qualification.qualificationDetailComplete} timeout="auto">
                                    <EmployeeQualification updateQualification={updateQualification} currentContract={currentContract.current_contract?.qualification} />
                                </Collapse>
                            </div>
                        }
                        {openEmergencyContact ?
                            <div className={openEmergencyContact ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenEmergencyContact(!openEmergencyContact)}
                                    >
                                        {openEmergencyContact ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Emergency Contact</label>
                                    <br />
                                </div>
                                <Collapse in={openEmergencyContact} timeout="auto">
                                    <EmployeeEmergencyContact updateEmergencyContact={updateEmergencyContact} currentContract={currentContract.current_contract?.emergencyContactDetail} />
                                </Collapse>
                            </div>:
                            <div className={!currentContract.current_contract?.emergencyContactDetail.emergencyContactComplete ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenEmergencyContact(!openEmergencyContact)}
                                    >
                                        {!currentContract.current_contract?.emergencyContactDetail.emergencyContactComplete ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Emergency Contact</label>
                                    <br />
                                </div>
                                <Collapse in={!currentContract.current_contract?.emergencyContactDetail.emergencyContactComplete} timeout="auto">
                                    <EmployeeEmergencyContact updateEmergencyContact={updateEmergencyContact} currentContract={currentContract.current_contract?.emergencyContactDetail} />
                                </Collapse>
                            </div>
                        }
                        {openMedicalAidInfo? 
                        <div className={openMedicalAidInfo ? styles.card_open : styles.card}>
                        <div style={{ backgroundColor: "#0081c6" }}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpenMedicalAidInfo(!openMedicalAidInfo)}
                            >
                                {openMedicalAidInfo ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                            </IconButton>
                            <label className={styles.card_heading}>Medical Information</label>
                            <br />
                        </div>
                        <Collapse in={openMedicalAidInfo} timeout="auto">
                            <EmployeeMedicalAidInfo updateMedicalAidDetail={updateMedicalAidDetail} currentContract={currentContract.current_contract?.medicalAidDetail} />
                        </Collapse>
                    </div>:
                    <div className={!currentContract.current_contract?.medicalAidDetail.medicalInformationComplete ? styles.card_open : styles.card}>
                            <div style={{ backgroundColor: "#0081c6" }}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenMedicalAidInfo(!openMedicalAidInfo)}
                                >
                                    {!currentContract.current_contract?.medicalAidDetail.medicalInformationComplete ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                </IconButton>
                                <label className={styles.card_heading}>Medical Information</label>
                                <br />
                            </div>
                            <Collapse in={!currentContract.current_contract?.medicalAidDetail.medicalInformationComplete} timeout="auto">
                                <EmployeeMedicalAidInfo updateMedicalAidDetail={updateMedicalAidDetail} currentContract={currentContract.current_contract?.medicalAidDetail} />
                            </Collapse>
                        </div>
                        }
                        {openEmploymentDetails ?
                            <div className={openEmploymentDetails ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenEmploymentDetails(!openEmploymentDetails)}
                                    >
                                        {openEmploymentDetails ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Employment Details</label>
                                    <br />
                                </div>
                                <Collapse in={openEmploymentDetails} timeout="auto">
                                    <EmployeeEmploymentDetails updateEmploymentDetail={updateEmploymentDetail} currentContract={currentContract.current_contract?.employmentDetails} />
                                </Collapse>
                            </div>:
                            <div className={!currentContract.current_contract?.employmentDetails.employmentDetailsComplete ? styles.card_open : styles.card}>
                                <div style={{ backgroundColor: "#0081c6" }}>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpenEmploymentDetails(!openEmploymentDetails)}
                                    >
                                        {!currentContract.current_contract?.employmentDetails.employmentDetailsComplete ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </IconButton>
                                    <label className={styles.card_heading}>Employment Details</label>
                                    <br />
                                </div>
                                <Collapse in={!currentContract.current_contract?.employmentDetails.employmentDetailsComplete} timeout="auto">
                                    <EmployeeEmploymentDetails updateEmploymentDetail={updateEmploymentDetail} currentContract={currentContract.current_contract?.employmentDetails} />
                                </Collapse>
                            </div>
                        }
                        <div className={styles.btn_footer_submit}>
                            <div style={{ visibility: visible ? "hidden" : "initial" }}>
                                <button className={styles.btn_submit} type="submit" onClick={warningSubmit}>Submit</button>
                            </div>
                            <span className={styles.errormsg}>{error}</span>
                        </div>
                        <div style={{ visibility: !visible ? "hidden" : "initial" }}>
                            <button className={styles.btn_submit_confirmation} type="submit" onClick={onSubmit}>Submit</button>
                            <h2 className={styles.confirmation_msg}>You will not be able to edit this again, click on submit to continue</h2>
                        </div>
                    </div>
            }
            </>
        : <></>}
        </>
    );
};

export default EmployeeContracts; 