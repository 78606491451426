import React, { useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import EditSaveDiscardButtons from '../../components/EditSaveDiscardButtons/EditSaveDiscardButtons';
import Messages from '../../components/Messages/Messages';
import makeClass from '../../utils/makeClass';
import AnsibleJobs from './AnsibleJobs/AnsibleJobs';
import ActionButtons from './Buttons/ActionButtons';
import ComboButtons from './Buttons/ComboButtons';
import WSClientButtons from './Buttons/WSClientButtons';
import GxwebSettingsValues from './GxwebSettingsValues';
import styles from './Instance.module.scss';
import {
  fetchDatabases,
  fetchGxwebVersions,
  fetchHostingLocations,
  fetchHostingServers,
  fetchInstance,
  saveInstance,
} from './instanceAPI';
import InstanceLogsButtons from './InstanceLogsButtons/InstanceLogsButtons';
import reducer, {
  INSTANCE_ACTIONS,
  instanceInitialState,
} from './instanceReducer';
import InstanceValues from './InstanceValues';
import LinksValues from './LinksValues';
import WSClientSettingsValues from './WSClientValues';

const Instance: React.FC = () => {
  const { id }: any = useParams();
  const history = useHistory();

  const [
    {
      databases,
      editing,
      gxwebVersions,
      hostingLocations,
      hostingServers,
      instance,
    },
    dispatch,
  ] = useReducer(reducer, instanceInitialState);

  useEffect(() => {
    if (id !== undefined) {
      fetchInstance(dispatch, id);
    }
  }, [id]);

  useEffect(() => {
    if (editing) {
      fetchDatabases(dispatch);
      fetchGxwebVersions(dispatch);
      fetchHostingLocations(dispatch);
      fetchHostingServers(dispatch);
    }
  }, [editing]);

  if (id === undefined) {
    return null;
  }

  return (
    <div className={makeClass('Page', styles.Instance)}>
      <EditSaveDiscardButtons
        editing={editing}
        saveFn={() => saveInstance(instance, id)}
        setEditing={(value: boolean) =>
          dispatch({ type: INSTANCE_ACTIONS.EDITING, value })
        }
      />
      <InstanceLogsButtons
        history={history}
        identifier={instance?.identifier}
      />
      <h1>{instance?.name}</h1>

      <LinksValues instance={instance} />
      <InstanceValues
        {...{ instanceID: id, instance, editing, databases, dispatch }}
      />
      <GxwebSettingsValues
        {...{ instance, editing, hostingLocations, gxwebVersions, dispatch }}
      />

      <div>
        <ComboButtons instanceID={id} />
        <ActionButtons instanceID={id} />
      </div>

      <WSClientSettingsValues
        {...{ instance, editing, hostingServers, dispatch }}
      />

      <div>
        <WSClientButtons instanceID={id} />
      </div>

      <div className={styles.Messages}>
        <Messages model="res.partner" id={id} />
        <AnsibleJobs id={id} />
      </div>
    </div>
  );
};

export default Instance;
