import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { get } from '../../../../utils/api';

const logout = async (historyPush: (path: string) => void) => {
  const resp = await get('/auth/revoke_token');

  if (resp.ok) {
    historyPush('/login');
  }
};

const Logout: React.FC = () => {
  const history = useHistory();

  return (
    <div onClick={() => logout(history.push)}>
      <FontAwesomeIcon icon={faSignOutAlt} />
      <span>Logout</span>
    </div>
  );
};

export default Logout;
