import { faAngleDoubleRight, faCheck, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OTPInput from '../../components/EditableInputs/OTPInput';
import { post,get } from '../../utils/api';
import { fetchQuoteApi } from '../Quotation/quotationAPI';
import { QuoteType } from '../Quotation/quotationReducer';
import style from '../../neumorpism.module.scss';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';

const OTPFormValidate: React.FC = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const jwt_token = history.location.search.replace('?token=', '');
  const [quote, setQuote] = useState({} as QuoteType);
  const [signedOtp, setSignedOtp] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useState('');
  const [signedCellNo, setSignedCellNo] = useState('');
  const [isQuoteLoaded, setQuoteLoaded] = useState(false);
  const [errors, setError] = useState('');
  const [cellNoReadOnly, setCellNoReadOnly] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);

  const otpValidate = async (
    jwt_token?: string,
    signedOtp?: string,
  ) => {
    const resp = await post('/quotation/business_details/validate_otp', {
      signedOtp: signedOtp,
      token: jwt_token,
    });

    if (!resp.ok) {
      setError('Something went wrong. Please send OTP again.');
      return false;
    }
    else {
      setError('Unexpected error occured. Invalid Token');
    }

    const json = await resp.json();
    if (!json.exists) {
      setError('The OTP you entered is invalid');
    }
    else {
      setError('');
    }

    return json.exists;
  };

  const sendOTP = async (e: any) => {
    e.preventDefault()
    setDisabled(false);
    setSentOtp(true);
    if (signedCellNo === "") {
      setError("Please enter a cellphone number.")
      setSentOtp(false);
    }
    else {
      setError("")
    }
    const resp = await post('/quotation/business_details/set_otp', {
      signedCellNo: signedCellNo,
      uuid: quote.id
    });
    const respText = await resp.text();
    if (resp.ok) {
      setSentOtp(true);
      setMessage('Please check your cellphone for your one time pin.');
    } else {
      setSentOtp(false);
      setMessage('Something went wrong. Try to request an OTP again. ' + respText);
    }
    return resp.ok;
  };

  const sendEmailOTP = async (e: any) => {
    e.preventDefault()
    setDisabled(false);
    if (!quote.email) {
      setError("There is no email address linked to this quotation.")
    }
    else {
      setError("")
    }
    const resp = await post('/quotation/business_details/set_otp_email', {
      email: quote.email,
      uuid: quote.id
    });

    if (resp.ok) {
      setMessage('Please check your email for your one time pin.');
      setCellNoReadOnly(true);
    } else {
      setMessage('Something went wrong. Try to request an OTP again.');
    }
    
    return resp.ok;
  };

  const submitOTP = async(e: any) => {
    setError('');
    e.preventDefault();
    let otpValid = await otpValidate(jwt_token, signedOtp);

    if (otpValid) {
      const resp = await get(`/get_new_otp_happy_quote_link/${jwt_token}`);
        if (resp.ok) {
          history.push({ pathname: await resp.json() })
        }
    }
  };

  useEffect(() => {
    fetchQuoteApi(`/quotation/${id}`, setQuote, setQuoteLoaded);
  }, [id]);

  return (
    <>
    <DesktopLayout>
    <div >
      <form className={style.FormWrapper}>
        <div></div>
        <div>
          <div className={style.FormHeader}>
            <h1>Quotation Form</h1>
          </div>
          <br />
          <h3 className={style.InfoMsg}>Note!!! Complete the form below and enter a valid OTP</h3>
          <label className={style.FieldLabel}>Cell No: </label>
          <input
            type="text"
            onChange={(event) => setSignedCellNo(event.target.value)}
            disabled={cellNoReadOnly}
            className={style.FieldInput}
          />
          {!sentOtp ?
            <button type="button" className={style.ActionBtn} onClick={sendOTP}>
              <FontAwesomeIcon icon={faAngleDoubleRight} color="white" />
              SMS OTP
            </button>
            : null
          }
          <button type="button" className={style.ActionBtn} onClick={sendEmailOTP}>
            <FontAwesomeIcon icon={faEnvelope} color="white" />
            Email OTP
          </button>
          <br />
          <OTPInput state={[signedOtp, setSignedOtp]} setMessage={setMessage} disabled={disabled} />
          <br />
          <span className={style.ErrorMsg}>{message}</span>
          <br />
          <br />
          <button className={style.SubmitBtn} onClick={submitOTP}>
            <FontAwesomeIcon icon={faCheck} color="white" />
            Submit
          </button>
          <br />
          <span className={style.ErrorMsg}>{errors}</span>
      </div>
      </form>
    </div>
    </DesktopLayout>
    <MobileLayout>
      <form className={responsive.PageWrapper}>
        <div></div>
        <div>
          <div className={responsive.OverviewFirstRow}>
            <h1>Quotation Form</h1>
          </div>
          <br />
          <h3 className={responsive.InfoMsg}>Note!!! Complete the form below and enter a valid OTP</h3>
          <label >Cell No: </label>
          <input
            type="text"
            onChange={(event) => setSignedCellNo(event.target.value)}
            disabled={cellNoReadOnly}
            className={style.FieldInput}
          />
          {!sentOtp ?
            <button type="button" className={responsive.EscalateBtn} onClick={sendOTP}>
              <FontAwesomeIcon icon={faAngleDoubleRight} color="white" />
              SMS OTP
            </button>
            : null
          }
          <button type="button" className={responsive.EscalateBtn} onClick={sendEmailOTP}>
            <FontAwesomeIcon icon={faEnvelope} color="white" />
            Email OTP
          </button>
          <br />
          <br />
          <OTPInput state={[signedOtp, setSignedOtp]} setMessage={setMessage} disabled={disabled} />
          <br />
          <span className={responsive.ErrorMsg}>{message}</span>
          <br />
          <button className={responsive.SubmitButton} onClick={submitOTP}>
            <FontAwesomeIcon icon={faCheck} color="white" />
          </button>
          <br />
          <span className={responsive.ErrorMsg}>{errors}</span>
      </div>
      </form>
    </MobileLayout>
    </>
  )
};

export default OTPFormValidate;
