export enum GXWEB_VERSION_ACTIONS {
  EDITING,
  GXWEB_VERSION,
  VERSION_GXWEB_BRANCH,
  VERSION_GXWEB_IMAGE,
  VERSION_IS_LTS,
  VERSION_PORTAL_BRANCH,
  VERSION_PORTAL_IMAGE,
  VERSION_VERSION,
}

export type GxwebVersionType = {
  is_lts: boolean;
  version: string;
  gxweb_image: string;
  gxweb_branch: string;
  portal_image: string;
  portal_branch: string;
  gxweb_image_identifier: string;
  portal_image_identifier: string;
  numeric_version: number;
};

export type GxwebVersionActions =
  | { type: GXWEB_VERSION_ACTIONS.EDITING; value: boolean }
  | { type: GXWEB_VERSION_ACTIONS.GXWEB_VERSION; value?: GxwebVersionType }
  | { type: GXWEB_VERSION_ACTIONS.VERSION_GXWEB_BRANCH; value: string }
  | { type: GXWEB_VERSION_ACTIONS.VERSION_GXWEB_IMAGE; value: string }
  | { type: GXWEB_VERSION_ACTIONS.VERSION_IS_LTS; value: boolean }
  | { type: GXWEB_VERSION_ACTIONS.VERSION_PORTAL_BRANCH; value: string }
  | { type: GXWEB_VERSION_ACTIONS.VERSION_PORTAL_IMAGE; value: string }
  | { type: GXWEB_VERSION_ACTIONS.VERSION_VERSION; value: string };

type GxwebVersionStateType = {
  editing: boolean;
  gxwebVersion?: GxwebVersionType;
};

export const GxwebVersionInitialState = {
  editing: false,
  gxwebVersion: undefined,
} as GxwebVersionStateType;

const reducer = (
  state: GxwebVersionStateType,
  action: GxwebVersionActions
): GxwebVersionStateType => {
  switch (action.type) {
    case GXWEB_VERSION_ACTIONS.EDITING:
      return { ...state, editing: action.value };
    case GXWEB_VERSION_ACTIONS.GXWEB_VERSION:
      return {
        ...state,
        gxwebVersion: action.value,
      };
    case GXWEB_VERSION_ACTIONS.VERSION_GXWEB_BRANCH:
      return {
        ...state,
        gxwebVersion: {
          ...state.gxwebVersion,
          gxweb_branch: action.value,
          gxweb_image_identifier: `${state.gxwebVersion?.gxweb_image}:${action.value}`,
        } as GxwebVersionType,
      };
    case GXWEB_VERSION_ACTIONS.VERSION_GXWEB_IMAGE:
      return {
        ...state,
        gxwebVersion: {
          ...state.gxwebVersion,
          gxweb_image: action.value,
          gxweb_image_identifier: `${action.value}:${state.gxwebVersion?.gxweb_branch}`,
        } as GxwebVersionType,
      };
    case GXWEB_VERSION_ACTIONS.VERSION_IS_LTS:
      return {
        ...state,
        gxwebVersion: {
          ...state.gxwebVersion,
          is_lts: action.value,
        } as GxwebVersionType,
      };
    case GXWEB_VERSION_ACTIONS.VERSION_PORTAL_BRANCH:
      return {
        ...state,
        gxwebVersion: {
          ...state.gxwebVersion,
          portal_branch: action.value,
          portal_image_identifier: `${state.gxwebVersion?.gxweb_image}:${action.value}`,
        } as GxwebVersionType,
      };
    case GXWEB_VERSION_ACTIONS.VERSION_PORTAL_IMAGE:
      return {
        ...state,
        gxwebVersion: {
          ...state.gxwebVersion,
          portal_image: action.value,
          portal_image_identifier: `${action.value}:${state.gxwebVersion?.portal_branch}`,
        } as GxwebVersionType,
      };
    case GXWEB_VERSION_ACTIONS.VERSION_VERSION:
      return {
        ...state,
        gxwebVersion: {
          ...state.gxwebVersion,
          version: action.value,
          numeric_version: calculateNumericVersion(action.value),
        } as GxwebVersionType,
      };
    default:
      return state;
  }
};

const calculateNumericVersion = (version: string): number =>
  version
    .split('.')
    .reduce(
      (total: number, value: string, index: number) =>
        total + parseInt(value || '0') * 1000 ** (2 - index),
      0 as number
    );

export default reducer;
