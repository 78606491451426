import React from 'react';

import styles from './Buttons.module.scss';
import call from './call';

const DatabaseButtons = ({ instanceID }: { instanceID: string | number[] }) => {
  const databaseCreate = async () => await call('database_create', instanceID);
  const nagiosPostgresCreate = async () =>
    await call('nagios_postgres_create', instanceID);
  const nagiosPostgresRemove = async () =>
    await call('nagios_postgres_remove', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={databaseCreate}>Create Database</button>
      <button onClick={nagiosPostgresCreate}>Nagios Postgres Create</button>
      <button onClick={nagiosPostgresRemove}>Nagios Postgres Remove</button>
    </div>
  );
};

export default DatabaseButtons;
