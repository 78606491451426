import React from 'react';

import { ActiveType, activeTypeName } from '../InstanceListItem';
import styles from './ActiveTypeToggles.module.scss';

const toggle = (
  setFilterList: React.Dispatch<React.SetStateAction<ActiveType[]>>,
  type: ActiveType
) =>
  setFilterList((old) =>
    old.includes(type) ? old.filter((t) => t !== type) : [...old, type]
  );

const ToggleButton = ({
  activeTypeFilter,
  setActiveTypeFilter,
  type,
}: {
  activeTypeFilter: ActiveType[];
  setActiveTypeFilter: React.Dispatch<React.SetStateAction<ActiveType[]>>;
  type: ActiveType;
}) => (
  <button
    className={activeTypeFilter.includes(type) ? styles.Active : ''}
    onClick={() => toggle(setActiveTypeFilter, type)}
  >
    {activeTypeName(type)}
  </button>
);

const ActiveTypeToggles = ({
  activeTypeFilter,
  setActiveTypeFilter,
}: {
  activeTypeFilter: ActiveType[];
  setActiveTypeFilter: React.Dispatch<React.SetStateAction<ActiveType[]>>;
}) => (
  <div className={styles.ActiveTypeToggleContainer}>
    <div>
      <ToggleButton
        {...{ activeTypeFilter, setActiveTypeFilter }}
        type="active"
      />
      <ToggleButton
        {...{ activeTypeFilter, setActiveTypeFilter }}
        type="testing"
      />
      <ToggleButton
        {...{ activeTypeFilter, setActiveTypeFilter }}
        type="deactivated"
      />
      <ToggleButton
        {...{ activeTypeFilter, setActiveTypeFilter }}
        type="never_activated"
      />
    </div>
  </div>
);

export default ActiveTypeToggles;
