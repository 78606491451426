import { get, post } from '../../utils/api';
import {
  HLActions,
  HL_ACTIONS,
  HostingLocationType,
} from './hostingLocationReducer';

export const fetchHostingLocation = async (
  dispatch: React.Dispatch<HLActions>,
  id?: string
) => {
  const resp = await get(`/hosting_location/${id}`);
  if (resp.ok) {
    dispatch({ type: HL_ACTIONS.HOSTING_LOCATION, value: await resp.json() });
  }
};

export const saveHostingLocation = async (
  hostingLocation?: HostingLocationType,
  id?: string
) => {
  if (!hostingLocation || !id) {
    return undefined;
  }

  const resp = await post(`/hosting_location/${id}`, hostingLocation);

  if (resp.ok) {
    window.location.reload();
  }
};
