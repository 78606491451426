export const fileLT2MBValidator = (value: any) =>
  value
    ? value.length > 3 * 2048 * 2048 * 1.3
      ? 'File Larger than 15MB'
      : null
    : null;

export type ContractLine = {
  current_contract: ContractDetail;
}

export type ContractDetail = {
  personalDetail: PersonalDetail;
  financialInfo: FinancialDetail;
  qualification: QualificationDetail;
  emergencyContactDetail: EmergencyContactDetail;
  medicalAidDetail: MedicalAidDetail;
  employmentDetails: EmploymentDetails;
};

export type PersonalDetail = {
    calledName: string;
    id:string,
    firstName: string;
    lastName: string;
    birthday: string;
    cellNumber: string;
    idNumber: string;
    unitNumber: string;
    complexName: string;
    streetNumber:string;
    streetName: string;
    suburb: string;
    city: string;
    postalCode: string;
    poBox: boolean;
    line1: string;
    line2: string;
    line3: string;
    postalCodePoBox: string;
    personalDetailComplete:boolean;
  };

  export type FinancialDetail = {
    selectBank:string;
    accountNumber:string;
    branchNumber:string;
    bankAccountHolder:string;
    taxNumber:string;
    accountType:string;
    financialDetailComplete:boolean;
    proofOfBanking:string;
  };

  export type QualificationDetail = {
    qualification: string;
    detailsOfQualification: string;
    qualificationDetailComplete:boolean;
    qualificationDocument: string;
  };

  export type EmergencyContactDetail = {
    contactName: string;
    contactCellNo: string;
    relationship: string;
    emergencyContactComplete: boolean;
  };

  export type MedicalAidDetail = {
    medicalAidNamePlan:string;
    medicalAidNumber:string;
    healthIssueBool:boolean;
    healthIssue:string;
    medicalInformationComplete:boolean;
    medicalAidCard:string
  }

  export type EmploymentDetails ={
    selectDepartment:string;
    companyAppointmentDate:string;
    goodxGroupAppointmentDate:string;
    workHoursStart:string;
    workHoursEnd:string;
    employmentDetailsComplete:boolean;
  };
