export type HLGroupType = 'gxweb' | 'goodxwin';

export const HLGroupTypeMap: Record<HLGroupType, string> = {
  goodxwin: 'GoodX Windows',
  gxweb: 'GoodX Web',
};

export enum HL_ACTIONS {
  EDITING,
  HL_GROUP,
  HL_NAME,
  HL_PUBLIC_IP,
  HL_ROOT_DOMAIN,
  HOSTING_LOCATION,
}

export type HLActions =
  | { type: HL_ACTIONS.EDITING; value: boolean }
  | { type: HL_ACTIONS.HL_GROUP; value: HLGroupType }
  | { type: HL_ACTIONS.HL_NAME; value: string }
  | { type: HL_ACTIONS.HL_PUBLIC_IP; value: string }
  | { type: HL_ACTIONS.HL_ROOT_DOMAIN; value: string }
  | { type: HL_ACTIONS.HOSTING_LOCATION; value: HostingLocationType };

export type HostingLocationType = {
  group: HLGroupType;
  name: string;
  public_ip: string;
  root_domain: string;
};

type HLStateType = {
  editing: boolean;
  hostingLocation?: HostingLocationType;
};

export const HLInitialState = {
  editing: false,
  hostingLocation: undefined,
};

const reducer = (state: HLStateType, action: HLActions): HLStateType => {
  switch (action.type) {
    case HL_ACTIONS.EDITING:
      return { ...state, editing: action.value };
    case HL_ACTIONS.HL_GROUP:
      return {
        ...state,
        hostingLocation: {
          ...state.hostingLocation,
          group: action.value,
        } as HostingLocationType,
      };
    case HL_ACTIONS.HL_NAME:
      return {
        ...state,
        hostingLocation: {
          ...state.hostingLocation,
          name: action.value,
        } as HostingLocationType,
      };
    case HL_ACTIONS.HL_PUBLIC_IP:
      return {
        ...state,
        hostingLocation: {
          ...state.hostingLocation,
          public_ip: action.value,
        } as HostingLocationType,
      };
    case HL_ACTIONS.HL_ROOT_DOMAIN:
      return {
        ...state,
        hostingLocation: {
          ...state.hostingLocation,
          root_domain: action.value,
        } as HostingLocationType,
      };
    case HL_ACTIONS.HOSTING_LOCATION:
      return {
        ...state,
        hostingLocation: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
