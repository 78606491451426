import { History } from 'history';
import React from 'react';

const LogsLinkButton = ({
  history,
  disabled,
  containerName,
  buttonName,
}: {
  history: History<any>;
  disabled: boolean;
  containerName?: string;
  buttonName: string;
}) => (
  <button
    disabled={disabled}
    onClick={() => history.push(`/logs/${containerName}`)}
  >
    {buttonName}
  </button>
);

export default LogsLinkButton;
