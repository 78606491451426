import React, { useEffect, useState } from 'react';

import styles from './Inputs.module.scss';

type TextAreaInputPropTypes = {
  name: string;
  value?: string;
  editing: boolean;
  onChange: (value: string) => void;
};

const TextAreaInput = ({
  name,
  value,
  editing,
  onChange,
}: TextAreaInputPropTypes) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!editing) {
      setTouched(false);
    }
  }, [editing]);

  const changed = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTouched(true);
    onChange(e.target.value);
  };

  return (
    <>
      <span>{name}</span>
      {editing ? (
        <textarea
          value={value}
          onChange={changed}
          rows={(value?.split('\n').length || 0) + 2}
          cols={
            Math.max(...(value?.split('\n').map((s) => s.length) || [0])) + 2
          }
          className={touched ? styles.Touched : ''}
        />
      ) : (
        <pre style={{ margin: '0', fontFamily: 'Roboto, sans-serif' }}>{value}</pre>
      )}
    </>
  );
};

export default TextAreaInput;
