import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';

import UserContext, { User } from '../../../User.context';
import styles from './Profile.module.scss';
import ProfileDropDown from './ProfileDropDown/ProfileDropDown';

const Profile = () => {
  const [isProfileOpen, setProfileOpen] = useState(false);
  const user: User = useContext(UserContext);

  return (
    <div className={styles.MoreMenuBtn} onClick={() => setProfileOpen((old) => !old)}>
      <div className={styles.Picture}>
        <FontAwesomeIcon icon={faUser} />
      </div>
      {isProfileOpen ? (
        <ProfileDropDown
          setProfileOpen={setProfileOpen}
          userName={user?.name}
        />
      ) : null}
    </div>
  );
};

export default Profile;
