import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
  } from '@react-pdf/renderer';
  import React from 'react';
  import { PersonalDetail, FinancialDetail, QualificationDetail, EmergencyContactDetail, MedicalAidDetail, EmploymentDetails } from './EmployeeContractDetail';
  import { GetHeaderImage } from '../Quotation/QuoteTemplate';
  
  interface ContractDetails {
    personalDetail: PersonalDetail;
    financialDetail: FinancialDetail;
    qualificationDetail: QualificationDetail;
    emergencyContactDetail: EmergencyContactDetail;
    medicalAidDetail: MedicalAidDetail;
    employmentDetails: EmploymentDetails;
    headerImage?: GetHeaderImage;
  };
  
  
  const EmployeeContractTemplateSave: React.FC<ContractDetails> = ({ personalDetail, financialDetail, qualificationDetail, emergencyContactDetail, medicalAidDetail, employmentDetails, headerImage }) => {
    const styles = StyleSheet.create({
      page: {
        flexDirection: 'row',
      },
      body: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 20,
      },
      textAlignCenter: {
        textAlign: 'center',
        fontSize: '18px',
      },
      table_header: {
        display: 'flex',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
      },
      tableRow_header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      },
      tableCell_header: {
        width: '100%',
        textAlign: 'left',
        padding: '2mm',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#dddddd',
      },
      heading: {
        fontSize: 14,
        fontWeight: 'bold',
        left: '240px',
      },
      termsHeading: {
        fontSize: 14,
        textAlign: 'left',
        fontWeight: 'bold',
        marginLeft: 10,
      },
      terms: {
        fontSize: 10,
        textAlign: 'left',
        marginLeft: 10,
      },
      termsContact: {
        fontSize: 10,
        textDecoration: 'underline',
        fontWeight: 'bold',
        marginLeft: 10,
      },
      logo: {
        width: 100,
        position: 'absolute',
        right: '4%',
        transform: '-50%, -50%',
      },
      h2: {
        fontFamily: 'Roboto Condensed',
        fontSize: '18pt',
        textTransform: 'uppercase',
        color: '#005e91',
        fontWeight: 700,
      },
      h3: {
        fontFamily: 'Roboto Condensed',
        fontSize: '14pt',
        textTransform: 'uppercase',
        color: '#47a3d3',
        fontWeight: 700,
      },
      spaceNone: {
        marginTop: 0,
        marginBottom: 0,
      },
      colWidth20: {
        width: '20%',
      },
      colWidth40: {
        width: '40%',
      },
      colWidth50: {
        width: '50%',
      },
      colWidth60: {
        width: '60%',
      },
      colWidth25: {
        width: '25%',
      },
      colHeightImg50: {
        height: '40px',
      },
      textAlignRight: {
        textAlign: 'right',
      },
      bankDetailsHeading: {
        fontSize: 14,
        textAlign: 'left',
        fontWeight: 'bold',
      },
      bankDetailsFooter: {
        fontSize: 12,
        fontWeight: 'bold',
      },
      acceptedDate: {
        fontWeight: 'bold',
      },
      header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '5mm',
  
      },
      headerimg: {
        marginLeft: '-5mm',
        marginTop: '-5mm',
        marginRight: '-5mm',
        width: '200mm',
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingBottom: '5mm',
        paddingTop: '5mm',
      },
      columnR: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        flexGrow: 1,
      },
      columnL: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexGrow: 2,
      },
      icon: {
        fontFamily: 'Material Icons',
      },
      center: {
        textAlign: 'center',
      },
      centered: {
        margin: 'auto',
      },
      title: {
        fontFamily: 'Roboto Condensed',
        fontSize: '30pt',
        textTransform: 'uppercase',
        fontWeight: 700,
      },
      subtitle: {
        fontFamily: 'Roboto Condensed',
        fontSize: '20pt',
        textTransform: 'uppercase',
        fontWeight: 300,
      },
      h1: {
        fontFamily: 'Roboto Condensed',
        fontSize: '24pt',
        textTransform: 'uppercase',
        color: '#0081c6',
        fontWeight: 700,
      },
      h4: {
        fontFamily: 'Roboto Condensed',
        fontSize: '12pt',
        textTransform: 'uppercase',
        color: '#505050',
        fontWeight: 700,
      },
      space5: {
        marginBottom: '5mm',
      },
      space2: {
        marginBottom: '2.5mm',
      },
      italic: {
        fontStyle: 'italic',
      },
      bold: {
        fontWeight: 'bold',
      },
      boldItalic: {
        fontStyle: 'italic',
        fontWeight: 'bold',
      },
  
      footer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: 'absolute',
        bottom: '5mm',
        marginTop: '5mm',
        marginLeft: '10mm',
        marginRight: '10mm',
        fontSize: '9pt',
        color: '#bbbbbb',
        fontStyle: 'italic',
        textAlign: 'center',
      },
      tableHeader: {
        fontWeight: 'bold',
      },
      tableHeaderBlue: {
        fontWeight: 'bold',
        color: '#ffffff',
        backgroundColor: '#0081c6',
      },
      textRight: {
        textAlign: 'right',
      },
      colWidth10: {
        width: '10%',
      },
      colWidth15: {
        width: '15%',
      },
      colWidth55: {
        width: '55%',
      },
      colWidth85: {
        width: '85%',
      },
      bgGrey: {
        backgroundColor: '#eeeeee',
      },
      table: {
        display: 'flex',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
      },
      tableRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      },
      tableCell: {
        width: '100%',
        textAlign: 'left',
        padding: '2mm',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#dddddd',
        fontSize: 10,
      },
      text_view: {
        fontSize: '12px',
      },
    })
  
    return (
      <Document>
        <Page size="A4" style={styles.body}>
          <View style={styles.header} fixed>
            <View style={styles.column}>
              <Image style={styles.headerimg} src={`${headerImage?.header}`}></Image>
            </View>
          </View>
          
            <Text style={styles.h1} fixed>Employee Information Form</Text>
  
            <>
            <Text style={styles.h2}>Personal Details</Text>
            <View style={styles.row}>
              <View style={[styles.column, styles.centered]}>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Called Name::</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.calledName}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>First Names:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.firstName}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Last Name:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.lastName}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Birthday:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.birthday}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Cell Number:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.cellNumber}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>ID Number:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.idNumber}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Complex Name:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.complexName}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Unit Numbe:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.unitNumber}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Street Number:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.streetNumber}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Street Name:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.streetName}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Suburb:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.birthday}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>City:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.city}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Postal Code:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.postalCode}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Mail Address Same As street Address:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.poBox? 'Yes':'No'}</Text>
                </View>
  
                {personalDetail.poBox?
                <></>:<>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Line 1:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.line1}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Line 2:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.line2}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Line 3:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.line3}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Postal Code:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{personalDetail.postalCodePoBox}</Text>
                </View>
                </>
                }
  
              </View>
            </View>
  
            <Text style={styles.h2}>Financial Information</Text>
            <View style={styles.row}>
              <View style={[styles.column, styles.centered]}>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Bank:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{financialDetail.selectBank}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Account Number:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{financialDetail.accountNumber}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Branch Number:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{financialDetail.branchNumber}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Bank Account Holder:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{financialDetail.bankAccountHolder}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Income Tax Number:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{financialDetail.taxNumber}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Account Type:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{financialDetail.accountType}</Text>
                </View>
              </View>
            </View>
  
            <Text style={styles.h2}>Qualification Information</Text>
            <View style={styles.row}>
              <View style={[styles.column, styles.centered]}>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Highest Qualification:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{qualificationDetail.qualification}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Details Of Qualification:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{qualificationDetail.detailsOfQualification}</Text>
                </View>
              </View>
            </View>
  
            <Text style={styles.h2}>Emergency Contact Details</Text>
            <View style={styles.row}>
              <View style={[styles.column, styles.centered]}>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Emergency Contact Name:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{emergencyContactDetail.contactName}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Emergency Contact Cellphone Number:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{emergencyContactDetail.contactCellNo}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Relationship:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{emergencyContactDetail.relationship}</Text>
                </View>
              </View>
            </View>
  
            <Text style={styles.h2}>Medical Information</Text>
            <View style={styles.row}>
              <View style={[styles.column, styles.centered]}>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Medical Aid Name And Plan:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{medicalAidDetail.medicalAidNamePlan}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Medical Aid Number:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{medicalAidDetail.medicalAidNumber}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Health conditions that could influence your capacity to fulfil your work duties:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{medicalAidDetail.healthIssueBool? 'Yes': 'No'}</Text>
                </View>
                {medicalAidDetail.healthIssueBool?
                <>
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Detail about health issue:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{medicalAidDetail.healthIssue}</Text>
                </View>
                </>:<></>}
              </View>
            </View>
  
            <Text style={styles.h2}>Employment Details</Text>
            <View style={styles.row}>
              <View style={[styles.column, styles.centered]}>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Company Appointment Date:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{employmentDetails.companyAppointmentDate}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>GoodX Group Appointment Date:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{employmentDetails.goodxGroupAppointmentDate}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Work Hours Start:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{employmentDetails.workHoursStart}</Text>
                </View>
  
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth50]}>Work Hours End:</Text>
                  <Text style={[styles.tableCell, styles.colWidth50]}>{employmentDetails.workHoursEnd}</Text>
                </View>
              </View>
            </View>
          </>
        </Page>
      </Document>
    );
  };
  
  export default EmployeeContractTemplateSave;
