const requestOptions = (method: string, json?: Object) =>
  ({
    method,
    headers:
      json === undefined
        ? undefined
        : {
            'Content-Type': 'application/json',
          },
    body: json === undefined ? undefined : JSON.stringify(json),
    credentials: 'include',
  } as RequestInit);

const checkFetch = async (
  url: string,
  options: RequestInit,
  redirectToLogin: Boolean = true
) => {
  const resp = await fetch(
    new URL(url, process.env.REACT_APP_API_BASE_HTTP_URL).href,
    options
  );

  if (redirectToLogin && resp.status === 401) {
    window.location.pathname = '/login';
  }

  if (!resp.ok) {
    console.error(await resp.clone().text());
  }

  return resp;
};

export const get = async (url: string, redirectToLogin: Boolean = true) =>
  await checkFetch(url, requestOptions('GET'), redirectToLogin);

export const post = async (
  url: string,
  json: Object,
  redirectToLogin: Boolean = true
) => await checkFetch(url, requestOptions('POST', json), redirectToLogin);

export const put = async (
  url: string,
  json: Object,
  redirectToLogin: Boolean = true
) => await checkFetch(url, requestOptions('PUT', json), redirectToLogin);

export const websocket = (url: string) =>
  new WebSocket(new URL(url, process.env.REACT_APP_API_BASE_WS_URL).href);
