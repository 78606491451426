import { get, post } from '../../utils/api';
import {
  DBLoginActions,
  DBLoginType,
  DB_LOGIN_ACTIONS,
} from './databaseLoginReducer';

export const fetchDBLogin = async (
  dispatch: React.Dispatch<DBLoginActions>,
  id?: string
) => {
  const resp = await get(`/database_login/${id}`);
  if (resp.ok) {
    dispatch({
      type: DB_LOGIN_ACTIONS.DATABASE_LOGIN,
      value: await resp.json(),
    });
  }
};

export const fetchDatabaseServers = async (
  dispatch: React.Dispatch<DBLoginActions>
) => {
  const resp = await get(`/database_server`);
  if (resp.ok) {
    dispatch({
      type: DB_LOGIN_ACTIONS.DB_LOGIN_SERVERS,
      value: await resp.json(),
    });
  }
};

export const saveDatabaseLogin = async (dbLogin?: DBLoginType, id?: string) => {
  if (!dbLogin || !id) {
    return undefined;
  }

  const resp = await post(`/database_login/${id}`, {
    name: dbLogin.name,
    password: dbLogin.password,
    server_id: dbLogin.server.id,
    user: dbLogin.user,
  });

  if (resp.ok) {
    window.location.reload();
  }
};
