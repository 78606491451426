import React from 'react';

const Dashboards: React.FC = () => (
  <>
    <h1>Dashboards</h1>
    <div>
      <img
        src={`https://cataas.com/cat?uniqueNum=${Math.random()}`}
        alt="Cat"
      />
    </div>
  </>
);

export default Dashboards;
