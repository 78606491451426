import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './FilterInput.module.scss';

const FilterInput = ({
  filterInput,
  setFilter,
}: {
  filterInput: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className={styles.FilterInput}>
    <FontAwesomeIcon icon={faFilter} />
    <input
      placeholder="Filter"
      value={filterInput}
      onChange={(e) => setFilter(e.target.value)}
      autoFocus
    />
  </div>
);

export default FilterInput;
