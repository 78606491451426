import React from 'react';

import Many2OneInput from '../../components/EditableInputs/Many2OneInput/Many2OneInput';
import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';
import TextAreaInput from '../../components/EditableInputs/TextAreaInput';
import TextInput from '../../components/EditableInputs/TextInput';
import {
  INSTANCE_ACTIONS,
  InstanceActions,
  InstanceData,
} from './instanceReducer';

type WSClientSettingsValuesPropTypes = {
  instance?: InstanceData;
  editing: boolean;
  hostingServers?: Many2OneType[];
  dispatch: React.Dispatch<InstanceActions>;
};

const WSClientSettingsValues = ({
  instance,
  editing,
  hostingServers,
  dispatch,
}: WSClientSettingsValuesPropTypes) => (
  <>
    <h2>WSclient Settings</h2>
    <div className="ValueGrid">
      <Many2OneInput
        name="WSClient Server"
        value={instance?.wsclient_host}
        link={`/hosting_server/${instance?.wsclient_host.id}`}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_WSCLIENT_HOST, value })
        }
        values={hostingServers}
      />

      <TextInput
        name="WFAPI URL"
        value={instance?.wsclient_wfapi_url}
        editing={editing}
        onChange={(value) =>
          dispatch({
            type: INSTANCE_ACTIONS.INSTANCE_WSCLIENT_WFAPI_URL,
            value,
          })
        }
      />

      <TextAreaInput
        name="WSclient ENV"
        value={instance?.wsclient_env}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_WSCLIENT_ENV, value })
        }
      />
    </div>
  </>
);

export default WSClientSettingsValues;
