import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './employee_contracts.module.scss';
import { MedicalAidDetail, fileLT2MBValidator } from './EmployeeContractDetail';
import { post } from '../../utils/api';
import { useParams } from 'react-router-dom';
import InputUpload from '../../components/EditableInputs/InputUpload';

interface IFifthChildProps {
    updateMedicalAidDetail: (arg: any) => void;
    currentContract: MedicalAidDetail;
}

const EmployeeMedicalAidInfo: React.FC<IFifthChildProps> = ({ updateMedicalAidDetail, currentContract }) => {
    const { jwt_token }: any = useParams();
    const [medicalAidNamePlan, setMedicalAidNameAndPlan] = useState('');
    const [medicalAidNumber, setMedicalAidNumber] = useState('');
    const [healthIssueBool, setHealthIssueBool] = useState(false);
    const [healthIssue, setHealthIssue] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [medicalInformationComplete, setMedicalInformationComplete] = useState(true);
    const [errorMedicalAidNamePlan,setErrorMedicalAidNamePlan] = useState('');
    const [errorMedicalAidNumber, setErrorMedicalAidNumber] = useState('');
    const [error, setError] = useState(false);
    const [medicalAidCard, setMedicalAidCard] = useState('');
    const [medicalAidDetail, setMedicalAidDetail] = useState({
        'medicalAidNamePlan': medicalAidNamePlan,
        'medicalAidNumber': medicalAidNumber,
        'healthIssueBool': healthIssueBool,
        'healthIssue': healthIssue,
        'medicalInformationComplete':medicalInformationComplete,
        'medicalAidCard':medicalAidCard,
    } as MedicalAidDetail);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setMedicalAidDetail(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const loadCurrentContract = () => {
        if (currentContract) {
            setMedicalAidDetail(currentContract);
            setHealthIssueBool(currentContract.healthIssueBool);
        }
    }

    const formValidate = async (e: { preventDefault: () => void }) => {
        setError(false);
        if (medicalAidDetail.medicalAidNamePlan === '' || medicalAidDetail.medicalAidNamePlan === null) {
            setErrorMedicalAidNamePlan('Please enter your medical aid plan name or N/A');
            setError(true);
        } else {
            setErrorMedicalAidNamePlan('');
        }
        if (medicalAidDetail.medicalAidNumber === '' || medicalAidDetail.medicalAidNumber === null) {
            setErrorMedicalAidNumber('Please enter your medical aid number or N/A');
            setError(true);
        } else {
            setErrorMedicalAidNumber('');
        }
        if(error===true){
            e.preventDefault();
        }
      };

    const save_medical_detail = async (e: any) => {
        setError(false);
        if (medicalAidDetail.medicalAidNamePlan === '' || medicalAidDetail.medicalAidNamePlan === null) {
            setError(true);
        }else if (medicalAidDetail.medicalAidNumber === '' || medicalAidDetail.medicalAidNumber === null) {
            setError(true);
        }else if(error===false){
            setMedicalInformationComplete(true);
            const resp = await post(`/save_employee/save_medical_detail/${jwt_token}`, {
                'medicalAidDetail':medicalAidDetail,
                'healthIssueBool':healthIssueBool,
                'medicalInformationComplete':medicalInformationComplete,
                'medicalAidCard':medicalAidCard,
            });
            if (resp.ok) {
                return resp.ok;
            }
        }
        
    };

    useEffect(() => {
        updateMedicalAidDetail(medicalAidDetail);
    },);

    useEffect(() => {
        setTimeout(function () {
            if (loaded === false) {
                loadCurrentContract();
                setLoaded(true);
            }
        }, 1000);
    },);

    return (
        <form onSubmit={formValidate}>
            <div className={styles.FormWrapper}>
                <div className={styles.form}>
                    <div className={styles.column}>
                        <div>
                            <label>Medical Aid Name And Plan:</label>
                            <input
                                className={styles.input}
                                type="text"
                                name="medicalAidNamePlan"
                                value={medicalAidDetail.medicalAidNamePlan || ''}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorMedicalAidNamePlan}</span>
                        </div>
                        <br />
                        <div>
                            <label>Medical Aid Number:</label>
                            <input
                                className={styles.input}
                                type="text"
                                name="medicalAidNumber"
                                value={medicalAidDetail.medicalAidNumber || ''}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorMedicalAidNumber}</span>
                        </div>
                        <br />
                        <div>
                            <label>Medical Aid Card:</label>
                                <InputUpload
                                    className="input"
                                    accept="image/*, application/pdf"
                                    errors={fileLT2MBValidator(medicalAidCard) ? fileLT2MBValidator(medicalAidCard) : null}
                                    onChange={(event: any) => setMedicalAidCard(event)}
                                />
                        </div>
                        <div>
                            <label>Tick the box if you have any health conditions:</label>
                            <br />
                            <label>Yes</label>
                            <input type="checkbox"
                                className={styles.input}
                                name="healthIssueYes"
                                style={{ marginLeft: '0px' }}
                                checked={healthIssueBool || false}
                                onChange={() => setHealthIssueBool(true)}
                            />
                        </div>
                        <br />
                        <div>
                            <label>No</label>
                            <input type="checkbox"
                                className={styles.input}
                                name="healthIssueNo"
                                checked={healthIssueBool? false:true}
                                onChange={() => setHealthIssueBool(false)}
                            />
                        </div>
                        <br />
                        <div>
                            {healthIssueBool}
                            <label>More detail about health issue:</label>
                            <input
                                className={styles.input}
                                type="text"
                                name="healthIssue"
                                value={medicalAidDetail.healthIssue || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.btn_footer}>
                        <button className={styles.save_button} onClick={save_medical_detail}>Complete</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default EmployeeMedicalAidInfo; 
