import React from 'react';

import styles from './Buttons.module.scss';
import call, { IDType } from './call';

const WSClientButtons = ({ instanceID }: IDType) => {
  const wsclientRemove = async () => await call('wsclient_remove', instanceID);
  const wsclientRestart = async () =>
    await call('wsclient_restart', instanceID);
  const wsclientStart = async () => await call('wsclient_start', instanceID);
  const nagiosWSClientCreate = async () =>
    await call('nagios_wsclient_create', instanceID);
  const nagiosWSClientRemove = async () =>
    await call('nagios_wsclient_remove', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={wsclientRemove}>WSClient Remove</button>
      <button onClick={wsclientRestart}>WSClient Restart</button>
      <button onClick={wsclientStart}>WSClient Start</button>
      <button onClick={nagiosWSClientCreate}>Nagios WSClient Create</button>
      <button onClick={nagiosWSClientRemove}>Nagios WSClient Remove</button>
    </div>
  );
};

export default WSClientButtons;
