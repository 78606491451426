import React from 'react';

import styles from './Buttons.module.scss';
import call, { IDType } from './call';

const CloudflareButtons = ({ instanceID }: IDType) => {
  const cloudflare = async () =>
    await call('cloudflare_upsert_records', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={cloudflare}>Cloudflare Upsert Records</button>
    </div>
  );
};

export default CloudflareButtons;
