import React from 'react';
import { NavLink } from 'react-router-dom';
import { faLock,faFolder } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Links.module.scss';
import Profile from '../Profile/Profile';

const HomeLink: React.FC = () => (
    <NavLink activeClassName={styles.active} exact to="/">
        Home
    </NavLink>
);

const Instances: React.FC = () => (
  <NavLink activeClassName={styles.active} to="/instance">
    <FontAwesomeIcon icon={faLock} className={styles.Icon}/>
    Instances
  </NavLink>
);

const DashboardsLink: React.FC = () => (
  <NavLink activeClassName={styles.active} to="/dashboards">
    <FontAwesomeIcon icon={faLock} className={styles.Icon}/>
    Dashboards
  </NavLink>
);

const QuotationLink: React.FC = () => (
  <NavLink activeClassName={styles.active} to="/quotation">
    <FontAwesomeIcon icon={faFolder} className={styles.Icon}/>
    Quotations
  </NavLink>
);

const Links: React.FC = () => (
  <div className={styles.Nav}>
    <div className={styles.NavLinks}>
      <Instances />
      <QuotationLink />
    </div>
    <Profile />
  </div>
);

export default Links;
