import { History } from 'history';
import React from 'react';

import { InstanceListItem, activeTypeName } from '../InstanceListItem';
import styles from './InstancesTable.module.scss';
import { Sort, sortInstanceListSingle } from './sortColumns';

const toggleSelected = (
  id: number,
  setSelectedIDs: React.Dispatch<React.SetStateAction<number[]>>
) =>
  setSelectedIDs((old) =>
    old.includes(id) ? old.filter((o) => o !== id) : [...old, id]
  );

const sortInstanceList = (instanceList: InstanceListItem[], sort?: Sort[]) => {
  if (!sort) {
    return instanceList;
  }

  let list = instanceList;

  for (const s of sort) {
    sortInstanceListSingle(list, s);
  }

  return list;
};

export const filterInstanceList = (
  instanceList: InstanceListItem[],
  filter?: RegExp[],
  sort?: Sort[]
) => {
  if (!filter) {
    return sortInstanceList(instanceList, sort);
  }

  let list = instanceList;

  for (const f of filter) {
    list = list.filter(
      (i) =>
        i.link?.match(f) ||
        i.version?.match(f) ||
        i.hosting_group?.match(f) ||
        i.data_center?.match(f) ||
        i.cluster?.match(f) ||
        i.database?.match(f) ||
        i.id?.toString().match(f)
    );
  }

  return sortInstanceList(list, sort);
};

const InstanceListTableBody = ({
  filter,
  history,
  instanceList,
  selectedIDs,
  setSelectedIDs,
  sort,
}: {
  filter?: RegExp[];
  history: History;
  instanceList: InstanceListItem[];
  selectedIDs: number[];
  setSelectedIDs: React.Dispatch<React.SetStateAction<number[]>>;
  sort: Sort[];
}) => (
  <tbody>
    {filterInstanceList(instanceList, filter, sort).map((r) => (
      <tr
        key={r.id}
        onClick={() => {
          if (selectedIDs.length === 0)
            history.push({ pathname: `/instance/${r.id}` });
          else toggleSelected(r.id, setSelectedIDs);
        }}
        onAuxClick={(e) => {
          if (e.button === 1)
            window.open(`/instance/${r.id}`, '_blank', 'noopener');
        }}
      >
        <td onClick={(e) => e.stopPropagation()} className={styles.Min}>
          <input
            type="checkbox"
            onChange={() => toggleSelected(r.id, setSelectedIDs)}
            checked={selectedIDs.includes(r.id)}
          />
        </td>
        <td>
          <a
            href={r.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {r.link}
          </a>
        </td>
        <td>{r.version}</td>
        <td>{r.data_center}</td>
        <td>{r.hosting_group}</td>
        <td>{activeTypeName(r.active_type)}</td>
        <td>{r.cluster}</td>
        <td>{r.database}</td>
      </tr>
    ))}
  </tbody>
);

export default InstanceListTableBody;
