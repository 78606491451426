import {
  IconDefinition,
  faSortAlphaDownAlt,
  faSortAlphaUp,
  faSortNumericDownAlt,
  faSortNumericUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './InstancesTable.module.scss';
import addColumn, { COLUMN, SORT_DIRECTION, Sort } from './sortColumns';

const SortedHeader = ({
  name,
  column,
  setSort,
  ascIcon,
  dscIcon,
}: {
  name: string;
  column: COLUMN;
  setSort: React.Dispatch<React.SetStateAction<Sort[]>>;
  ascIcon: IconDefinition;
  dscIcon: IconDefinition;
}) => (
  <th>
    <div>
      <span>{name}</span>
      <div
        className={styles.FilterButton}
        onClick={() =>
          addColumn(setSort, {
            column,
            direction: SORT_DIRECTION.ASC,
          })
        }
      >
        <FontAwesomeIcon icon={ascIcon} />
      </div>
      <div
        className={styles.FilterButton}
        onClick={() => {
          addColumn(setSort, {
            column,
            direction: SORT_DIRECTION.DEC,
          });
        }}
      >
        <FontAwesomeIcon icon={dscIcon} />
      </div>
    </div>
  </th>
);

export const SortedHeaderAlpha = ({
  name,
  column,
  setSort,
}: {
  name: string;
  column: COLUMN;
  setSort: React.Dispatch<React.SetStateAction<Sort[]>>;
}) => (
  <SortedHeader
    {...{
      name,
      column,
      setSort,
      ascIcon: faSortAlphaUp,
      dscIcon: faSortAlphaDownAlt,
    }}
  />
);

export const SortedHeaderNumeric = ({
  name,
  column,
  setSort,
}: {
  name: string;
  column: COLUMN;
  setSort: React.Dispatch<React.SetStateAction<Sort[]>>;
}) => (
  <SortedHeader
    {...{
      name,
      column,
      setSort,
      ascIcon: faSortNumericUp,
      dscIcon: faSortNumericDownAlt,
    }}
  />
);
