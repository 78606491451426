import React from 'react';

import styles from './ModalPage.module.scss';

const ModalPage = ({
  children,
  onClose,
}: {
  children: any;
  onClose: () => void;
}) => {
  return (
    <div
      className={styles.ModalPage}
      onClick={(e) => {
        e.stopPropagation();
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      {children}
    </div>
  );
};

export default ModalPage;
