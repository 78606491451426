import React from 'react';

import styles from './Buttons.module.scss';
import call, { IDType } from './call';

const GXWebNagiosButtons = ({ instanceID }: IDType) => {
  const nagiosGxwebCreate = async () =>
    await call('nagios_gxweb_create', instanceID);
  const nagiosGxwebRemove = async () =>
    await call('nagios_gxweb_remove', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={nagiosGxwebCreate}>Nagios Configs Create</button>
      <button onClick={nagiosGxwebRemove}>Nagios Configs Remove</button>
    </div>
  );
};

export default GXWebNagiosButtons;
