import {
  Document,
  Image,
  Page,
  StyleSheet,
  Font,
  Text,
  View
} from '@react-pdf/renderer';
import React, { useState } from 'react';

import { QuoteLineType, QuoteType } from './quotationReducer';
import { BusinessDetailsType } from '../BusinessDetails/BusinessDetailsReducer';

import font1 from './fonts/Roboto-Regular.ttf';
import font1i from './fonts/Roboto-Italic.ttf';
import font1b from './fonts/Roboto-Bold.ttf';
import font2b from './fonts/RobotoCondensed-Bold.ttf';
import font2l from './fonts/RobotoCondensed-Light.ttf';
import icons from './fonts/MaterialIcons-Regular.ttf';
import BusinessDetailsTemplatePDF from '../BusinessDetails/BusinessDetailsTemplatePDF';


Font.register({ family: 'Roboto', fonts: [
  { src: font1, format: "truetype", fontStyle: 'normal', fontWeight: 400 },
  { src: font1i, format: "truetype", fontStyle: 'italic', fontWeight: 400 },
  { src: font1b, format: "truetype", fontStyle: 'normal', fontWeight: 700 },
]});
Font.register({ family: 'Roboto Condensed', fonts: [
  { src: font2b, format: "truetype", fontStyle: 'normal', fontWeight: 700 },
  { src: font2l, format: "truetype", fontStyle: 'normal', fontWeight: 300 },
]});
Font.register({ family: 'Material Icons', format: "truetype", src: icons });

interface PropsTemplate {
  props?: QuoteType;
  item_lines?: QuoteDetailPropTypes[];
  quote_config?: QuoteConfigPropTypes; 
  business_details?: BusinessDetailsType;
  banking_details?: QuoteConfigBankingDetails[];
  header_image?: GetHeaderImage;
};

export type SubCategoryTemplatePropTypes = {
  product_type: string;
  line_detail: QuoteLineType[];
};

export type QuoteCategoriesPropTypes = {
  item_type: string;
  sub_categories?: SubCategoryTemplatePropTypes[];
};

export type QuoteDetailPropTypes = {
  line_detail: QuoteCategoriesPropTypes;
};

export type QuoteConfigPropTypes = {
  company_name: string; 
  contact_no: string; 
  company_reg_no: string; 
  email_address: string;
  general_quote_message: string;
  abbreviated_name: string;
  debit_order_instruction_message: string;
  terms_and_conditions: string;
  terms_and_conditions_url: string;
  currency: string;
  vat_text: string;
  vat_percentage: number;
  debit_order: boolean;
};

export type QuoteConfigBankingDetails = {
  bank_name: string;
  bank_acc_name: string;
  bank_acc_no: string;
  bank_acc_type: string;
  bank_branch_code: string;
  bank_logo: string;
};

export type GetHeaderImage = {
  header: string;
  footer: string;
};

const MyDocument: React.FC<PropsTemplate> = (props) => {
  const [quote] = useState(props.props);
  const [quoteCategories] = useState(props.item_lines);
  const [quoteConfig] = useState(props.quote_config);
  const [businessValues] = useState(props.business_details);
  const [bankingDetails] = useState(props.banking_details);
  const [headerImage] = useState(props.header_image);
  const styles = StyleSheet.create({
    body: {
			padding: '10mm 10mm 5mm 10mm',
			fontFamily: 'Roboto',
			fontSize: '11pt',
      display: 'flex',
      color: '#505050',
		},
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingBottom: '5mm',
      paddingTop: '5mm',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    columnR: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      flexGrow: 1,
    },
    columnL: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexGrow: 2,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '5mm',
    },
    headerimg: {
      marginLeft: '-10mm',
      marginTop: '-10mm',
      width: '210mm',
    },
    icon: {
      fontFamily: 'Material Icons',
    },
    center: {
      textAlign: 'center',
    },
    centered: {
      margin: 'auto',
    },
    title: {
      fontFamily: 'Roboto Condensed',
      fontSize: '30pt',
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    subtitle: {
      fontFamily: 'Roboto Condensed',
      fontSize: '20pt',
      textTransform: 'uppercase',
      fontWeight: 300,
    },
    h1: {
      fontFamily: 'Roboto Condensed',
      fontSize: '24pt',
      textTransform: 'uppercase',
      color: '#0081c6',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Roboto Condensed',
      fontSize: '18pt',
      textTransform: 'uppercase',
      color: '#005e91',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Roboto Condensed',
      fontSize: '14pt',
      textTransform: 'uppercase',
      color: '#47a3d3',
      fontWeight: 700,
    },
    h3dark: {
      fontFamily: 'Roboto Condensed',
      fontSize: '18pt',
      textTransform: 'uppercase',
      color: '#005e91',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Roboto Condensed',
      fontSize: '12pt',
      textTransform: 'uppercase',
      color: '#505050',
      fontWeight: 700,
    },
    space5: {
      marginBottom: '5mm',
    },
    space2: {
      marginBottom: '2.5mm',
    },
    italic: {
      fontStyle: 'italic',
    },
    bold: {
      fontWeight: 'bold',
    },
    boldItalic: {
      fontStyle: 'italic',
      fontWeight: 'bold',
    },

    footer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      position: 'absolute',
      bottom: '5mm',
      marginTop: '5mm',
      marginLeft: '10mm',
      marginRight: '10mm',
      fontSize: '9pt',
      color: '#bbbbbb',
      fontStyle: 'italic',
      textAlign: 'center',
    },

    tableRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    tableCell: {
      width: '100%',
      textAlign: 'left',
      padding: '2mm',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#dddddd',
    },
    tableHeader: {
      fontWeight: 'bold',
    },
    tableHeaderBlue: {
      fontWeight: 'bold',
      color: '#ffffff',
      backgroundColor: '#0081c6',
    },
    textRight: {
      textAlign: 'right',
    },
    colWidth7: {
      width: '7%',
    },
    colWidth10: {
      width: '10%',
    },
    colWidth15: {
      width: '15%',
    },
    colWidth40: {
      width: '40%',
    },
     colWidth48: {
      width: '48%',
    },
    colWidth60: {
      width: '60%',
    },
    colWidth55: {
      width: '55%',
    },
    colWidth85: {
      width: '85%',
    },
    bgGrey: {
      backgroundColor: '#eeeeee',
    },
    spaceNone: {
      marginTop: 0,
      marginBottom: 0,
    }

  });

  const document = (
    <Document>
    {quote?.id && quoteCategories ?
      <Page size="A4" style={styles.body}>
        <View style={styles.header} fixed>
          <View style={styles.column}>
            <Image style={styles.headerimg} src={`${headerImage?.header}`}></Image>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.center}>
              <Text style={styles.subtitle}>GoodX Software & Switch</Text>
              <Text style={styles.title}>License & Support Agreement</Text>

              <Text>{' '}</Text>
              <Text>between:</Text>
              <Text>{' '}</Text>

              <Text style={styles.h1}>{quoteConfig?.company_name}</Text>
              <Text>Registration number: {quoteConfig?.company_reg_no}</Text>
              <Text style={styles.italic}>(hereinafter referred to as "GoodX")</Text>

              <Text>{' '}</Text>
              <Text>and</Text>
              <Text>{' '}</Text>

              <Text style={styles.h1}>{businessValues?.business_name ? businessValues?.business_name : quote?.name}</Text>
              <Text style={styles.h3dark}>{businessValues?.trading_as ? 'T/A' : null}</Text>
              <Text style={styles.h1}>{businessValues?.trading_as ? businessValues?.trading_as : ''}</Text>
              <Text>Practice number: {quote?.practice_number}</Text>
              <Text style={styles.italic}>(hereinafter referred to as "the Client")</Text>
              <Text>{' '}</Text>
              <Text style={styles.italic}>(hereinafter referred to as "the Parties")</Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.column, styles.colWidth60, styles.centered]}>

            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth40]}>Date:</Text>
              <Text style={[styles.tableCell, styles.colWidth60]}>{quote?.create_date}</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth40]}>Quotation for:</Text>
              <Text style={[styles.tableCell, styles.colWidth60]}>{quote?.name} (Quote #{quote?.id})</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth40]}>Discipline:</Text>
              <Text style={[styles.tableCell, styles.colWidth60]}>{quote?.speciality?.name}</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth40]}>Email address:</Text>
              <Text style={[styles.tableCell, styles.colWidth60]}>{quote?.email}</Text>
            </View>

          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.column, styles.colWidth60, styles.centered]}>
            <View style={[styles.tableRow, styles.bgGrey]}>
              <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth40]}>GoodX Consultant:</Text>
              <Text style={[styles.tableCell, styles.colWidth60]}>{quote?.sale_employee.name}</Text>
            </View>

            <View style={[styles.tableRow, styles.bgGrey]}>
              <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth40]}>Email address:</Text>
              <Text style={[styles.tableCell, styles.colWidth60]}>{quoteConfig?.email_address}</Text>
            </View>

            <View style={[styles.tableRow, styles.bgGrey]}>
              <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth40]}>Contact number:</Text>
              <Text style={[styles.tableCell, styles.colWidth60]}>{quote?.employee_phone}</Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <Text style={[styles.center, styles.italic]}>{quote.note_section}</Text>
        </View>


      {/* Outstanding line items and detail */}
      <Text style={[styles.h2, styles.spaceNone]} break>
        Quote: Part A
      </Text>
      {quoteCategories?.map((groupitem) => (
        <View style={styles.row} key={groupitem.line_detail.item_type}>
          <View style={styles.column}>
            <View style={styles.spaceNone} wrap={true}>
              <Text style={[styles.h3, styles.space2]}>
                {groupitem.line_detail.item_type}
              </Text>

              {groupitem.line_detail.sub_categories?.map((subcats) => (

              <View style={styles.space5} wrap={false} key={subcats.product_type}>
                <Text style={[styles.h4, styles.space2]}>
                  {subcats.product_type}
                </Text>

                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.tableHeaderBlue, styles.colWidth48]}>
                    Item Name
                  </Text>
                  <Text style={[styles.tableCell, styles.tableHeaderBlue, styles.textRight, styles.colWidth15]}>
                    Std Price
                  </Text>
                  <Text style={[styles.tableCell, styles.tableHeaderBlue, styles.textRight, styles.colWidth7]}>
                    Qty
                  </Text>
                  <Text style={[styles.tableCell, styles.tableHeaderBlue, styles.textRight, styles.colWidth15]}>
                    Quoted Price
                  </Text>
                  <Text style={[styles.tableCell, styles.tableHeaderBlue, styles.textRight, styles.colWidth15]}>
                    Total
                  </Text>
                </View>

                {/* <View > */}
                {subcats.line_detail?.map((item) => (
                  <View style={styles.tableRow} key={item.id}>
                    {item.billable_price.optional_extra ?
                    <Text style={[styles.tableCell, styles.colWidth48]}>
                      {item.billable_price.name + " (INCLUDED)"}
                    </Text>
                    :
                    <Text style={[styles.tableCell, styles.colWidth48]}>
                      {item.billable_price.name}
                    </Text>
                    }
                    {!item.adhoc_item ?
                    <Text style={[styles.tableCell, styles.textRight, styles.colWidth15]}>
                      {quoteConfig?.currency} {item.billable_price.full_price.toFixed(2)}
                    </Text>
                    :
                    <Text style={[styles.tableCell, styles.textRight, styles.colWidth15]}>
                      {quoteConfig?.currency} {item.billable_price.full_price.toFixed(5)}
                    </Text>
                    }
                    <Text style={[styles.tableCell, styles.textRight, styles.colWidth7]}>
                      {item.quantity}
                    </Text>
                    {item.sale_price || item.billable_price.empty_value_allowed ?
                    !item.adhoc_item ?
                    <Text style={[styles.tableCell, styles.textRight, styles.colWidth15]}>
                    {quoteConfig?.currency} {item.sale_price.toFixed(2)}
                    </Text>
                    : <Text style={[styles.tableCell, styles.textRight, styles.colWidth15]}>
                    {quoteConfig?.currency} {item.sale_price.toFixed(5)}
                    </Text>
                    : null}
                     {item.sale_price ?
                    <Text style={[styles.tableCell, styles.textRight, styles.colWidth15]}>
                      {quoteConfig?.currency} {(item.sale_price * item.quantity).toFixed(2)}
                    </Text>
                    :null}
                  </View>
                ))}

                {/* Outstanding Sub Total Amount */}
                {groupitem.line_detail.item_type !== 'Transactional' ?
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth85, styles.bgGrey]}>
                    Subtotal:
                  </Text>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth15]}>
                    {quoteConfig?.currency} {subcats.line_detail
                      ?.reduce(
                        (total, line) =>
                          total + line.sale_price * line.quantity,
                        0
                      )
                      .toFixed(2)}
                  </Text>
                </View>
                : null
                }

                {/* Outstanding VAT Amount */}
                {groupitem.line_detail.item_type !== 'Transactional' ?
                <View style={styles.tableRow}>
                 <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth85, styles.bgGrey]}>
                  {quoteConfig?.vat_text}:
                </Text>
                <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth15]}>
                  {quoteConfig?.currency} {subcats.line_detail
                    ?.reduce(
                      (total, line) =>
                        total + line.sale_price * line.quantity * ((quoteConfig?.vat_percentage?  quoteConfig?.vat_percentage: 0)/100),
                      0
                    )
                    .toFixed(2)}
                  </Text>
                </View>
                : null
                }

                {/* Outstanding Total Amount */}
                {groupitem.line_detail.item_type !== 'Transactional' ?
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth85, styles.bgGrey]}>
                    Total
                  </Text>
                  <Text style={[styles.tableCell, styles.textRight, styles.bold, styles.colWidth15]}>
                    {quoteConfig?.currency} {subcats.line_detail
                      ?.reduce(
                        (total, line) =>
                          total +
                          line.sale_price * line.quantity * ((quoteConfig?.vat_percentage?  quoteConfig?.vat_percentage: 0)/100) +
                          line.sale_price * line.quantity,
                        0
                      )
                      .toFixed(2)}
                  </Text>
                </View>
                : null
                }
              </View>

              ))}
            </View>
          </View>
        </View>
      ))}
      
      {businessValues?.quote_id !== undefined ?
        <React.Fragment>
        <Text break></Text>
          <BusinessDetailsTemplatePDF props={businessValues} quoteConfig={quoteConfig} bankingDetails={bankingDetails} quote={quote}/>
        </React.Fragment>
      : null
      }

      <Text style={styles.footer} fixed>
        GoodX Software & Switch Licence & Support Agreement for {quote?.name}
      </Text>
      
    </Page>
  : null
  }
  </Document>
  );
  return document;
};

export default MyDocument;
