const toISODateString = (date?: Date): string | undefined => {
  if (date === undefined) {
    return undefined;
  }

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default toISODateString;
