import React, { ChangeEvent } from 'react';

import { InstanceListItem } from '../InstanceListItem';
import styles from './InstancesTable.module.scss';
import { COLUMN, Sort } from './sortColumns';
import { SortedHeaderAlpha, SortedHeaderNumeric } from './SortedHeader';
import { filterInstanceList } from './TableBody';

const onCheckSelectedAll = (
  event: ChangeEvent<HTMLInputElement>,
  instanceList: InstanceListItem[],
  setSelectedIDs: React.Dispatch<React.SetStateAction<number[]>>,
  filter?: RegExp[]
) => {
  event.persist();

  if (event.target.checked) {
    setSelectedIDs(filterInstanceList(instanceList, filter).map((il) => il.id));
  } else {
    setSelectedIDs([]);
  }
};

const InstanceListTableHead = ({
  filter,
  instanceList,
  selectedIDs,
  setSelectedIDs,
  setSort,
}: {
  filter?: RegExp[];
  instanceList: InstanceListItem[];
  selectedIDs: number[];
  setSelectedIDs: React.Dispatch<React.SetStateAction<number[]>>;
  setSort: React.Dispatch<React.SetStateAction<Sort[]>>;
}) => {
  const ids = instanceList.map((i) => i.id);
  const checked = selectedIDs.filter((id) => ids.includes(id)).length !== 0;
  const intermediateCheck =
    selectedIDs.length !== 0 &&
    filterInstanceList(instanceList, filter).length !== selectedIDs.length;

  return (
    <thead>
      <tr>
        <th className={styles.Min}>
          <input
            type="checkbox"
            checked={checked}
            ref={(input) =>
              input ? (input.indeterminate = intermediateCheck) : null
            }
            onChange={(e) =>
              onCheckSelectedAll(e, instanceList, setSelectedIDs, filter)
            }
          />
        </th>
        <SortedHeaderAlpha name="Link" column={COLUMN.LINK} setSort={setSort} />
        <SortedHeaderAlpha
          name="Version"
          column={COLUMN.VERSION}
          setSort={setSort}
        />
        <SortedHeaderAlpha
          name="Data Center"
          column={COLUMN.DATA_CENTER}
          setSort={setSort}
        />
        <SortedHeaderAlpha
          name="Hosting Group"
          column={COLUMN.HOSTING_GROUP}
          setSort={setSort}
        />
        <SortedHeaderAlpha
          name="Active Type"
          column={COLUMN.ACTIVE_TYPE}
          setSort={setSort}
        />
        <SortedHeaderNumeric
          name="Cluster"
          column={COLUMN.CLUSTER}
          setSort={setSort}
        />
        <SortedHeaderAlpha
          name="Database"
          column={COLUMN.DATABASE}
          setSort={setSort}
        />
      </tr>
    </thead>
  );
};

export default InstanceListTableHead;
