import React from 'react';
import { Link } from 'react-router-dom';
import style from '../EditableInputs/Inputs.module.scss'

type EditSaveDiscardButtonsPropTypes = {
  editing: boolean;
  saveFn: () => void;
  setEditing: (value: boolean) => void;
  create?: boolean;
  createLink?: string;
  createFn?: () => void;
  accepted?: boolean;
};

const EditSaveDiscardButtonsQuotation = ({
  editing,
  accepted,
  saveFn,
  setEditing,
  create,
  createLink,
  createFn,
}: EditSaveDiscardButtonsPropTypes) =>
  editing || create ? (
    <>
      <button onClick={create ? createFn : saveFn} className={style.SubmitBtn}>Save</button>
      {create ? 
        <Link to="/quotation">
          <button onClick={() => setEditing(false)} className={style.SubmitBtn}>Discard</button>
        </Link>:
        <button onClick={() => setEditing(false)} className={style.SubmitBtn}>Discard</button>}
    </>
  ) : (
    <>
      {!accepted ?
      <button onClick={() => setEditing(true)} className={style.SubmitBtn}>Edit</button> : null}
      {createLink ? <Link to={createLink}>Create</Link> : null}
    </>
  );

export default EditSaveDiscardButtonsQuotation;
