import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { post } from '../../utils/api';
import { QuoteType } from '../Quotation/quotationReducer';

import TextInputValidation from '../../components/EditableInputs/TextInputValidation';
import NumberInputValidation from '../../components/EditableInputs/NumberIntputValidation';
import styles from '../../neumorpism.module.scss';
import { BusinessDetailsType, BusinessDetailsHeardFromType, fileLT2MBValidator, QuoteConfigPropTypes } from './BusinessDetailsReducer';
import { fetchBusinessDetails, fetchSpecialities } from './BusinessDetailsAPI';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';
import InputUpload from '../../components/EditableInputs/InputUpload';
import Select from 'react-select';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';
import bstyles from '../BusinessDetails/BusinessDetails.module.scss';
import Validator from '../../utils/Validator';

const BusinessDetails: React.FC = () => {
  const { id, jwt_token }: any = useParams();
  const history = useHistory();
  const [businessValues, setBusinessValues] = useState({} as BusinessDetailsType);
  const [quote, setQuote] = useState({} as QuoteType);
  const [tradingAs, setTradingAs] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [idRegNr, setIdRegNr] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [disciplineApi, setDisciplineApi] = useState([]);
  const [discipline, setDiscipline] = useState('');
  const [postalAddress, setPostalAddress] = useState('');
  const [postalAddress2, setPostalAddress2] = useState('');
  const [postalAddress3, setPostalAddress3] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [streetAddress3, setStreetAddress3] = useState('');
  const [streetCode, setStreetCode] = useState('');
  const [telNr, setTelNr] = useState(0);
  const [cellNr, setCellNr] = useState(0);
  const [faxNr, setFaxNr] = useState('');
  const [emailDebitOrder, setEmailDebitOrder] = useState('');
  const [emailAccounts, setEmailAccounts] = useState('');
  const [emailNewsletter, setEmailsNewsletter] = useState('');
  const [emailPracticeManager, setEmailPracticeManager] = useState('');
  const [creditControlName, setCreditControlName] = useState('');
  const [creditControlEmail, setCreditControlEmail] = useState('');
  const [businessDetailsLoading, setBusinessDetailsLoading] = useState(false);
  const [IOCellNo, setIOCellNo] = useState(0);
  const [practiceNr, setPracticeNr] = useState('');
  const [hpcsaNr, setHpcsaNr] = useState('');
  const [mhgUsername, setMHGUsername] = useState('');
  const [mhgPassword, setMHGPassword] = useState('');
  const [vatNr, setVatNr] = useState('');
  const [businessNameErr, setBusinessNameErr] = useState('');
  const [tradingAsErr, setTradingAsErr] = useState('');
  const [idRegNrErr, setIdRegNrErr] = useState('');
  const [contactPersonErr, setContactPersonErr] = useState('');
  const [disciplineErr, setDisciplineErr] = useState('');
  const [postalAddressErr, setPostalAddressErr] = useState('');
  const [streetAddressErr, setStreetAddressErr] = useState('');
  const [telNrErr, setTelNrErr] = useState('');
  const [cellNrErr, setCellNrErr] = useState('');
  const [emailDebitOrderErr, setEmailDebitOrderErr] = useState('');
  const [emailAccountsErr, setEmailAccountsErr] = useState('');
  const [emailNewsletterErr, setEmailsNewsletterErr] = useState('');
  const [creditControlNameErr, setCreditControlNameErr] = useState('');
  const [creditControlEmailErr, setCreditControlEmailErr] = useState('');
  const [practiceNrErr, setPracticeNrErr] = useState('');
  const [hpcsaNrErr, setHpcsaNrErr] = useState('');
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [acceptContact, setAcceptContact] = useState('');
  const [acceptContactErr, setAcceptContactErr] = useState('');
  const [seenSocialMediaErr, setSeenSocialMediaErr] = useState('');
  const [emailPracticeManagerErr, setEmailPracticeManagerErr] = useState('');
  const [IOCellNoErr, setIOCellNoErr] = useState('');
  const [idDocument, setIdDocument] = useState('');
  const [idDocumentErr, setIdDocumentErr] = useState('');
  const [companyDocument, setCompanyDocument] = useState('');
  const [companyDocumentErr, setCompanyDocumentErr] = useState('');
  const [bankDetails, setBankDetails] = useState('');
  const [bankDetailsErr, setBankDetailsErr] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [heardFrom, setHeardFrom] = useState<BusinessDetailsHeardFromType[]>([])
  const [seenSocialMedia, setSeenSocialMedia] = useState('');
  const [debitorderBool, setDebitOrderBool] = useState(false);
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');
  const [branchCode, setBranchCode] = useState(0);
  const [accountName, setAccountName] = useState('');
  const [accountNo, setAccountNo] = useState(0);
  const [accountType, setAccountType] = useState('');
  const [dateOfDebitOrder, setDateOfDebitOrder] = useState('');
  const [debitorderBoolError, setDebitOrderBoolError] = useState('');
  const [bankError, setBankError] = useState('');
  const [bankBranchError, setBankBranchError] = useState('');
  const [branchCodeError, setBranchCodeError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [accountNoError, setAccountNoError] = useState('');
  const [accountTypeError, setAccountTypeError] = useState('');
  const [dateOfDebitOrderError, setDateOfDebitOrderError] = useState('');
  const [defaultFileLoaded, setDefaultFileLoadedState] = useState({ iddoc: false, companydoc: false, bankdoc: false });
  const [quoteConfig, setQuoteConfig] = useState({} as QuoteConfigPropTypes);
  const [heardFromErr, setHeardFromErr] = useState('');
  const [isTokenValid, setIsTokenValid] = useState({"isValid": "", "isTemp": ""});
  let regexEmail = /^[\w\.-]+@[\w\.-]+\.\w+$/;
 
  const HeardFromOptions = [
    { value: '1', label: 'Google' },
    { value: '2', label: 'Social Media Platform' },
    { value: '3', label: 'Publication - Magazine/Advert' },
    { value: '4', label: 'Marketing Emails' },
    { value: '5', label: 'Word of Mounth - Friend/Colleague' },
    { value: '6', label: 'Sales person - Cold Calling' },
    { value: '7', label: 'Events -Expos/Congress/Golf gathering' },
    { value: '8', label: 'Other' },
  ];

  useEffect(() => {
    if (id !== undefined) {
      const validateToken = async () => {
        const resp = await post(`/quotation/validate_token`, {jwt_token, id});

        if (resp.ok) {
          setIsTokenValid(await resp.json());
        }
      }
      validateToken();
    }
  },[id, jwt_token]);

  const validateFields = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (businessName === '') {
      setBusinessNameErr('Please enter your business name');
    } else {
      setBusinessNameErr('');
    }
    if (tradingAs === '') {
      setTradingAsErr('Please enter your trading as name')
    }
    else {
      setTradingAsErr('');
    }
    if (idRegNr === '') {
      setIdRegNrErr('Please enter your ID/Registration number');
    } else {
      setIdRegNrErr('');
    }
    if (contactPerson === '') {
      setContactPersonErr('Please enter a contact person');
    } else {
      setContactPersonErr('');
    }
    if (discipline) {
      setDisciplineErr('Please select a discipline from the list');
    } else {
      setDisciplineErr('');
    }
    if (postalAddress === '') {
      setPostalAddressErr('Please enter a postal address');
    } else {
      setPostalAddressErr('');
    }
    // if (postalCode === '') {
    //   setPostalCodeErr('Please enter a postal code');
    // } else {
    //   setPostalCodeErr('');
    // }
    if (streetAddress === '') {
      setStreetAddressErr('Please enter a street address');
    } else {
      setStreetAddressErr('');
    }
    if (String(telNr).length < 10) {
      setTelNrErr('Telephone number must be 10 digits long');
    } else {
      setTelNrErr('');
    }
    if (String(cellNr).length < 10 ) {
      setCellNrErr('Cellphone number must be 10 digits long');
    } else {
      setCellNrErr('');
    }
    if (emailDebitOrder.match(regexEmail)) {
      setEmailDebitOrderErr('');
    } else {
      setEmailDebitOrderErr('Invalid debit order email address');
    }
    if (emailAccounts.match(regexEmail)) {
      setEmailAccountsErr('');
    } else {
      setEmailAccountsErr('Invalid accounts email address');
    }
    if (emailNewsletter.match(regexEmail)) {
      setEmailsNewsletterErr('');
    } else {
      setEmailsNewsletterErr('Invalid newsletter email address');
    }
    if (emailPracticeManager.match(regexEmail)) {
      setEmailPracticeManagerErr('');
    } else {
      setEmailPracticeManagerErr('Invalid practice manager email address');
    }
    if (creditControlName === '') {
      setCreditControlNameErr('Please enter the information officer\'s name');
    } else {
      setCreditControlNameErr('');
    }
    if (creditControlEmail.match(regexEmail)) {
      setCreditControlEmailErr('');
    } else {
      setCreditControlEmailErr('Invalid information officer email address');
    }
    if (String(IOCellNo).length < 10 ) {
      setIOCellNoErr('Information Officer cellphone number must be 10 digits long');
    } else {
      setIOCellNoErr('');
    }
    if (practiceNr.length < 7) {
      setPracticeNrErr('Practice number must be 7 characters long');
    } else {
      setPracticeNrErr('');
    }
    if (hpcsaNr === '') {
      setHpcsaNrErr('Please enter a your practice HPCSA number');
    } else {
      setHpcsaNrErr('');
    }
    if (acceptContact === '') {
      setAcceptContactErr('Please enter your full name');
    } else {
      setAcceptContactErr('');
    }
    if (seenSocialMedia === '') {
      setSeenSocialMediaErr('No option has been selected');
    } else {
      setSeenSocialMediaErr('');
    }
    if(debitorderBool===false && bankDetails==='') {
      setDebitOrderBoolError('Please attach proof of banking detail');
    } else {
      setDebitOrderBoolError('');
    }
    if (bank === '') {
      setBankError('Please enter a bank');
    } else {
        setBankError('');
    }
    if (branch === '') {
        setBankBranchError('Please enter a branch');
    } else {
        setBankBranchError('');
    }
    if (branchCode === 0 || branch === '') {
        setBranchCodeError('Please enter a branch code');
    } else {
        setBranchCodeError('');
    }
    if (accountName === '') {
        setAccountNameError('Please enter an account name');
    } else {
        setAccountNameError('');
    }
    if (accountNo === 0 || String(accountNo) === 'None' || String(accountNo) === 'NaN') {
        setAccountNoError('Please enter an account no');
    } else {
        setAccountNoError('');
    }
    if (accountType === '') {
        setAccountTypeError('Please enter an account type');
    } else {
        setAccountTypeError('');
    }
    if (dateOfDebitOrder === '') {
        setDateOfDebitOrderError('Please select date of debit order');
    } else {
        setDateOfDebitOrderError('');
    }
    if (idDocument === '' || idDocument === undefined) {
      setIdDocumentErr('Please attach ID document');
    }
    else {
      setIdDocumentErr('');
    }
    if (companyDocument === '' || companyDocument === undefined) {
      setCompanyDocumentErr('Please attach company document');
    }
    else {
      setCompanyDocumentErr('');
    }
    if (bankDetails === '' || bankDetails === undefined) {
      setBankDetailsErr('Please attach proof of banking detail');
    } else {
      setBankDetailsErr('');
    }
    if (heardFrom === undefined || heardFrom.length === 0) {
      setHeardFromErr('Please select an option');
    } else {
      setHeardFromErr('');
    }
  };

  const BusinessDetailsUpdate = async (field: any, value: any) => {
    const resp = await post(`/quotation/business_details_update/${quote.id}`, {
      field,
      value
    });

    if (resp.ok) {
      return true;
    } else {
      toast.error(resp.statusText + '. Contact GoodX for further assistance.');
    }
  };

  const marketerSubmitSave = async (
    id: string,
    businessName: string,
    tradingAs: string,
    idRegNr: string,
    contactPerson: string,
    discipline: string,
    postalAddress: string,
    postalAddress2: string,
    postalAddress3: string,
    postalCode: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    streetCode: string,
    telNr: number,
    cellNr: number,
    faxNr: string,
    emailDebitOrder: string,
    emailAccounts: string,
    emailNewsletter: string,
    creditControlName: string,
    creditControlEmail: string,
    practiceNr: string,
    hpcsaNr: string,
    mhgUsername: string,
    mhgPassword: string,
    vatNr: string,
    acceptContact: string,
    agreementCheck: boolean,
    idDocument: string,
    companyDocument: string,
    bankDetails: string,
    IOCellNo: number,
    emailPracticeManager: string,
    businessType: string,
    heardFrom: string,
    socialMediaPlatforms: string,
    debitorderBool: boolean,
    bank: string,
    branch: string,
    branchCode: number,
    accountName:string,
    accountNo:number,
    accountType:string,
    dateOfDebitOrder:string,
  ) => {
    setIsLoading(true);
    const resp = await post('/quotation/marketer_fill_in_details', {
      id: id,
      businessName: businessName,
      tradingAs: tradingAs,
      idRegNr: idRegNr,
      contactPerson: contactPerson,
      discipline: discipline,
      postalAddress: postalAddress,
      postalAddress2: postalAddress2,
      postalAddress3: postalAddress3,
      postalCode: postalCode,
      streetAddress: streetAddress,
      streetAddress2: streetAddress2,
      streetAddress3: streetAddress3,
      streetCode: streetCode,
      telNr: telNr,
      cellNr: cellNr,
      faxNr: faxNr,
      emailDebitOrder: emailDebitOrder,
      emailAccounts: emailAccounts,
      emailNewsletter: emailNewsletter,
      creditControlName: creditControlName,
      creditControlEmail: creditControlEmail,
      practiceNr: practiceNr,
      hpcsaNr: hpcsaNr,
      mhgUsername: mhgUsername,
      mhgPassword: mhgPassword,
      vatNr: vatNr,
      acceptContact: acceptContact,
      agreementCheck: agreementCheck,
      idDocument: idDocument,
      companyDocument: companyDocument,
      bankDetails: bankDetails,
      IOCellNo: IOCellNo,
      emailPracticeManager: emailPracticeManager,
      businessType: businessType,
      heardFrom: heardFrom,
      socialMediaPlatforms: socialMediaPlatforms,
      debitorderBool:debitorderBool,
      bank:bank,
      branch:branch,
      branchCode:branchCode,
      accountName:accountName,
      accountNo:accountNo,
      accountType:accountType,
      dateOfDebitOrder:dateOfDebitOrder
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
      history.push({ pathname: `/` });
      setIsLoading(false);
      } else {
        toast.error(resp.statusText + '. Contact GoodX for further assistance.');
        setIsLoading(false);
      }
    };

  const BusinessDetailsSave = async (
    id: string,
    businessName: string,
    tradingAs: string,
    idRegNr: string,
    contactPerson: string,
    discipline: string,
    postalAddress: string,
    postalAddress2: string,
    postalAddress3: string,
    postalCode: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    streetCode: string,
    telNr: number,
    cellNr: number,
    faxNr: string,
    emailDebitOrder: string,
    emailAccounts: string,
    emailNewsletter: string,
    creditControlName: string,
    creditControlEmail: string,
    practiceNr: string,
    hpcsaNr: string,
    mhgUsername: string,
    mhgPassword: string,
    vatNr: string,
    acceptContact: string,
    agreementCheck: boolean,
    idDocument: string,
    companyDocument: string,
    bankDetails: string,
    IOCellNo: number,
    emailPracticeManager: string,
    businessType: string,
    heardFrom: string,
    socialMediaPlatforms: string,
    debitorderBool: boolean,
    bank: string,
    branch: string,
    branchCode: number,
    accountName:string,
    accountNo:number,
    accountType:string,
    dateOfDebitOrder:string,
    allowedToSign : boolean,
    signedOnBehalfOf:string,
    dateSigned:Date,
    signedName:string,
    signedSurname:string,
    signedRole:string,
    signedPlace:string,
    signedCell:string,
    signedEmail:string
  ) => {
    setIsLoading(true);
    const resp = await post('/quotation/business_details_submit', {
      id: id,
      businessName: businessName,
      tradingAs: tradingAs,
      idRegNr: idRegNr,
      contactPerson: contactPerson,
      discipline: discipline,
      postalAddress: postalAddress,
      postalAddress2: postalAddress2,
      postalAddress3: postalAddress3,
      postalCode: postalCode,
      streetAddress: streetAddress,
      streetAddress2: streetAddress2,
      streetAddress3: streetAddress3,
      streetCode: streetCode,
      telNr: telNr,
      cellNr: cellNr,
      faxNr: faxNr,
      emailDebitOrder: emailDebitOrder,
      emailAccounts: emailAccounts,
      emailNewsletter: emailNewsletter,
      creditControlName: creditControlName,
      creditControlEmail: creditControlEmail,
      practiceNr: practiceNr,
      hpcsaNr: hpcsaNr,
      mhgUsername: mhgUsername,
      mhgPassword: mhgPassword,
      vatNr: vatNr,
      acceptContact: acceptContact,
      agreementCheck: agreementCheck,
      idDocument: idDocument,
      companyDocument: companyDocument,
      bankDetails: bankDetails,
      IOCellNo: IOCellNo,
      emailPracticeManager: emailPracticeManager,
      businessType: businessType,
      heardFrom: heardFrom,
      socialMediaPlatforms: socialMediaPlatforms,
      debitorderBool:debitorderBool,
      bank:bank,
      branch:branch,
      branchCode:branchCode,
      accountName:accountName,
      accountNo:accountNo,
      accountType:accountType,
      dateOfDebitOrder:dateOfDebitOrder,
      allowedToSign:allowedToSign,
      signedOnBehalfOf:signedOnBehalfOf,
      dateSigned:dateSigned,
      signedName:signedName,
      signedSurname:signedSurname,
      signedRole:signedRole,
      signedPlace:signedPlace,
      signedCell:signedCell,
      signedEmail:signedEmail,
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
      window.location.reload();
      setIsLoading(false);
      } else {
        toast.error(resp.statusText + '. Contact GoodX for further assistance.');
        setIsLoading(false);
      }
    };

  const businessSubmit = async() => {
        if (
          businessName &&
          tradingAs &&
          businessType &&
          idRegNr &&
          contactPerson &&
          discipline &&
          postalAddress &&
          streetAddress &&
          Number(String(telNr).length > 9) &&
          Number(String(cellNr).length > 9) &&
          emailDebitOrder.match(regexEmail) && emailDebitOrder !== '' &&
          emailAccounts.match(regexEmail) && emailAccounts !== '' &&
          emailNewsletter.match(regexEmail) && emailNewsletter !== '' &&
          emailPracticeManager.match(regexEmail) && emailPracticeManager !== '' &&
          creditControlName &&
          creditControlEmail.match(regexEmail) &&
          Number(String(IOCellNo).length > 9) &&
          practiceNr.length > 6 &&
          hpcsaNr &&
          idDocument &&
          companyDocument &&
          bankDetails &&
          acceptContact &&
          heardFrom && heardFrom.length > 0 &&
          seenSocialMedia &&
          (!quoteConfig?.debit_order || bank) &&
          (!quoteConfig?.debit_order || branchCode) &&
          (!quoteConfig?.debit_order || branch) &&
          (!quoteConfig?.debit_order || accountName) &&
          (!quoteConfig?.debit_order || accountNo) &&
          (!quoteConfig?.debit_order || accountType)
        ) {
          BusinessDetailsSave(
            id,
            businessName,
            tradingAs,
            idRegNr,
            contactPerson,
            discipline,
            postalAddress,
            postalAddress2,
            postalAddress3,
            postalCode,
            streetAddress,
            streetAddress2,
            streetAddress3,
            streetCode,
            telNr,
            cellNr,
            faxNr,
            emailDebitOrder,
            emailAccounts,
            emailNewsletter,
            creditControlName,
            creditControlEmail,
            practiceNr,
            hpcsaNr,
            mhgUsername,
            mhgPassword,
            vatNr,
            acceptContact,
            agreementCheck,
            idDocument,
            companyDocument,
            bankDetails,
            IOCellNo,
            emailPracticeManager,
            businessType,
            heardFrom as any,
            seenSocialMedia,
            !debitorderBool,
            bank,
            branch,
            branchCode,
            accountName,
            accountNo,
            accountType,
            dateOfDebitOrder,
            false, 
            '', 
            new Date(),
            '',
            '',
            '',
            '',
            '',
            ''
          );
    }
  };

  const marketerSubmit = async() => {
        marketerSubmitSave(
          id,
          businessName,
          tradingAs,
          idRegNr,
          contactPerson,
          discipline,
          postalAddress,
          postalAddress2,
          postalAddress3,
          postalCode,
          streetAddress,
          streetAddress2,
          streetAddress3,
          streetCode,
          telNr,
          cellNr,
          faxNr,
          emailDebitOrder,
          emailAccounts,
          emailNewsletter,
          creditControlName,
          creditControlEmail,
          practiceNr,
          hpcsaNr,
          mhgUsername,
          mhgPassword,
          vatNr,
          acceptContact,
          agreementCheck,
          idDocument,
          companyDocument,
          bankDetails,
          IOCellNo,
          emailPracticeManager,
          businessType,
          heardFrom as any,
          seenSocialMedia,
          !debitorderBool,
          bank,
          branch,
          branchCode,
          accountName,
          accountNo,
          accountType,
          dateOfDebitOrder
        );
  };

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validation = (event:any,type:string , name:string) => {
    let err = ''
    switch(type) {
      case 'email':
        if (!isValidEmail(event.target.value)) {
          err ='Email is invalid';
        } else {
          err ='';
        }
    }
    switch(name) {
      case 'debitOrderEmail':
        return setEmailDebitOrderErr(err);
      case 'businessName':
        if (businessName === '') {
          return setBusinessNameErr('Please enter your business name');
        } else {
          return setBusinessNameErr('');
        }
      case 'tradingAs':
        if (tradingAs === '') {
          return setTradingAsErr('Please enter your trading as name')
        }
        else {
          return setTradingAsErr('');
        }
      case 'idRegNr':
        if (idRegNr === '') {
          return setIdRegNrErr('Please enter your ID/Registration number');
        } else {
          return setIdRegNrErr('');
        }
      case 'contactPerson':
        if (contactPerson === '') {
          return setContactPersonErr('Please enter a contact person');
        } else {
          return setContactPersonErr('');
        }
      case 'discipline':
        if (discipline) {
          return setDisciplineErr('Please select a discipline from the list');
        } else {
          return setDisciplineErr('');
        }
      case 'postalAddress':
        if (postalAddress === '') {
          return setPostalAddressErr('Please enter a postal address');
        }  
        if (postalAddress === '*') {
          setPostalAddress(streetAddress);
          setPostalAddress2(streetAddress2);
          setPostalAddress3(streetAddress3);
          setPostalAddressErr('');
        }
        else {
          return setPostalAddressErr('');
        }
      case 'streetAddress':
        if (streetAddress === '') {
          return setStreetAddressErr('Please enter a street address');
        } else {
          return setStreetAddressErr('');
        }
      case 'telNr':
        if (String(telNr).length < 10) {
          return setTelNrErr('Telephone number must be 10 digits long');
        } else {
          return setTelNrErr('');
        }
      case 'cellNr':
        if (String(cellNr).length < 10 ) {
          return setCellNrErr('Cellphone number must be 10 digits long');
        } else {
          return setCellNrErr('');
        }
      case 'emailAccounts':
        return setEmailAccountsErr(err);
      case 'emailNewsletter':
        return setEmailsNewsletterErr(err)
      case 'emailPracticeManager':
        return setEmailPracticeManagerErr(err)
      case 'creditControlName':
        if (creditControlName === '') {
          return setCreditControlNameErr('Please enter the information officer\'s name');
        } else {
          return setCreditControlNameErr('');
        }
      case 'creditControlEmail':
        if (creditControlEmail.match(regexEmail)) {
          return setCreditControlEmailErr('');
        } else {
          return setCreditControlEmailErr('Invalid information officer email address');
        }
      case 'IOCellNo':
        if (String(IOCellNo).length < 10 ) {
          return setIOCellNoErr('Information Officer cellphone number must be 10 digits long');
        } else {
          return setIOCellNoErr('');
        }
      case 'practiceNr':
        if (practiceNr.length < 7) {
          return setPracticeNrErr('Practice number must be 7 characters long');
        } else {
          return setPracticeNrErr('');
        }
      case 'hpcsaNr':
        if (hpcsaNr === '') {
          return setHpcsaNrErr('Please enter a your practice HPCSA number');
        } else {
          return setHpcsaNrErr('');
        }
      case 'acceptContact':
        if (acceptContact === '') {
          return setAcceptContactErr('Please enter your full name');
        } else {
          return setAcceptContactErr('');
        }
      case 'seenSocialMedia':
        if (seenSocialMedia === '') {
          return setSeenSocialMediaErr('No option has been selected');
        } else {
          return setSeenSocialMediaErr('');
        }
      case 'heardFrom':
        if (heardFrom === undefined || heardFrom.length === 0) {
          setHeardFromErr('Please select an option');
        } else {
          setHeardFromErr('');
        }
      case 'debitOrderBool':
        if(debitorderBool===false && bankDetails==='') {
          return setDebitOrderBoolError('Please attach proof of banking detail');
        } else {
          return setDebitOrderBoolError('');
        }
      case 'bank':
        if (bank === '' && debitorderBool===false) {
          return setBankError('Please enter a bank');
        } else {
          return setBankError('');
        }
      case 'branch':
        if (branch === '') {
          return setBankBranchError('Please enter a branch');
        } else {
          return setBankBranchError('');
        }
      case 'branchCode':
        if (branchCode === 0 || String(branchCode) === 'None' || String(branchCode) === 'NaN') {
          return setBranchCodeError('Please enter a branch code');
        } else {
          return setBranchCodeError('');
        }
      case 'accountName':
        if (accountName === '') {
          return setAccountNameError('Please enter an account name');
        } else {
          return setAccountNameError('');
        }
      case 'accountNo':
        if (accountNo === 0 || String(accountNo) === 'None' || String(accountNo) === 'NaN') {
          return setAccountNoError('Please enter an account no');
        } else {
          return setAccountNoError('');
        }
      case 'accountType':
        if (accountType === '') {
          return setAccountTypeError('Please enter an account type');
        } else {
          return setAccountTypeError('');
        }
      case 'dateOfDebitOrder':
        if (dateOfDebitOrder === '') {
          return setDateOfDebitOrderError('Please select date of debit orrder');
        } else {
          return setDateOfDebitOrderError('');
        }
    }
  };

  function applyStyling(fileInput: any, mode: any) {
    if (mode == 1) {
      fileInput.style.border = '2px solid #ccc';
      fileInput.style.backgroundColor = '#44AF69';
      fileInput.style.color = '#fffff';
      fileInput.style.height = '36px';
      fileInput.style.borderRadius = '66px';
      fileInput.style.fontWeight = '500';
      fileInput.style.fontSize = '16px';
      fileInput.style.textTransform = 'uppercase';
      fileInput.style.padding = '0 16px';
    }
    else {
      fileInput.style.border = '2px solid #ccc';
      fileInput.style.backgroundColor = '#E0323B';
      fileInput.style.color = '#fffff';
      fileInput.style.height = '36px';
      fileInput.style.borderRadius = '66px';
      fileInput.style.fontWeight = '500';
      fileInput.style.fontSize = '16px';
      fileInput.style.textTransform = 'uppercase';
      fileInput.style.padding = '0 16px';
    }
  };
  
  const initializeFileInput = (
    uploadedDocs: string | null,
    defaultFileLoaded: boolean,
    fileInputId: string,
    applyStyling: (fileInput: HTMLInputElement, styleCode: number) => void,
    setDefaultFileLoaded: (fileInputId: string, loaded: boolean) => void
  ) => {
    const fileInput = document.querySelector<HTMLInputElement>(`input[type="file"][id="${fileInputId}"]`);
    if (uploadedDocs && !defaultFileLoaded) {
      if (fileInput !== null) {
        const myFile = new File(['Uploaded'], 'Uploaded', { type: 'text/plain' });
  
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(myFile);
        fileInput.files = dataTransfer.files;
  
        applyStyling(fileInput, 1);
        setDefaultFileLoaded(fileInputId, true);
      } else {
        console.error("File input element not found.");
      }
    }
  };

  const handleFileInputChange = (
    fileInputId: string,
    applyStyling: (fileInput: HTMLInputElement, styleCode: number) => void,
    setIdDocumentErr: (message: string) => void,
    setCompanyDocumentErr: (message: string) => void,
    setBankError: (message: string) => void
  ) => {
    const fileInput = document.querySelector<HTMLInputElement>(`input[type="file"][id="${fileInputId}"]`);
    if (fileInput) {
      fileInput.onchange = () => {
        if (fileInput.files) {
          if (fileInput.files.length === 0) {
            applyStyling(fileInput, 2);
            if (fileInputId === 'iddoc') {
              setIdDocumentErr('Please attach ID document');
            }
            if (fileInputId === 'companydoc') {
              setCompanyDocumentErr('Please attach Company document');
            }
            if (fileInputId === 'bankdoc') {
              setBankError('Please attach proof of banking detail');
            }
          } else {
            applyStyling(fileInput, 1);
          }
        } else {
          console.error("File input files not available.");
        }
      };
    }
  };

  const setDefaultFileLoaded = (fileInputId: string, loaded: boolean) => {
    setDefaultFileLoadedState(prevState => ({ ...prevState, [fileInputId]: loaded }));
  };

  useEffect(() => {
    initializeFileInput(idDocument, defaultFileLoaded.iddoc, 'iddoc', applyStyling, setDefaultFileLoaded);
    initializeFileInput(companyDocument, defaultFileLoaded.companydoc, 'companydoc', applyStyling, setDefaultFileLoaded);
    initializeFileInput(bankDetails, defaultFileLoaded.bankdoc, 'bankdoc', applyStyling, setDefaultFileLoaded);
  }, [idDocument, companyDocument, bankDetails]);

  useEffect(() => {
    handleFileInputChange('iddoc', applyStyling, setIdDocumentErr, setCompanyDocumentErr, setBankError);
    handleFileInputChange('companydoc', applyStyling, setIdDocumentErr, setCompanyDocumentErr, setBankError);
    handleFileInputChange('bankdoc', applyStyling, setIdDocumentErr, setCompanyDocumentErr, setBankError);
  }, []);

  useEffect(() => {
   fetchSpecialities('/quotation/speciality', setDisciplineApi);
   fetchBusinessDetails(`/quotation/business_details/${id}`, setBusinessValues, setBusinessDetailsLoading);
   fetchBusinessDetails(`/quotation/${id}`, setQuote, setBusinessDetailsLoading);
   fetchSpecialities(`/quotation/config`, setQuoteConfig);
  }, []);
  
  useEffect(() => {
    setTradingAs(businessValues.trading_as || '');
    setBusinessName(businessValues.business_name || '');
    setBusinessType(businessValues.business_type || '');
    setIdRegNr(businessValues.id_reg_nr || '');
    setContactPerson(businessValues.contact_person || '');
    setDiscipline(businessValues.discipline_id || '');
    setPostalAddress(businessValues.postal_address || '');
    setPostalAddress2(businessValues.postal_address2 || '');
    setPostalAddress3(businessValues.postal_address3 || '');
    setStreetAddress(businessValues.street_address || '');
    setStreetAddress2(businessValues.street_address2 || '');
    setStreetAddress3(businessValues.street_address3 || '');
    setTelNr(businessValues.tel_nr || 0);
    setCellNr(businessValues.cell_nr || 0);
    setFaxNr(businessValues.fax_nr || '');
    setEmailAccounts(businessValues.email_accounts || '');
    setEmailDebitOrder(businessValues.email_debit_order || '');
    setEmailsNewsletter(businessValues.email_newsletter || '');
    setEmailPracticeManager(businessValues.email_practice_manager || '');
    setCreditControlName(businessValues.credit_control_name || '');
    setCreditControlEmail(businessValues.credit_control_email || '');
    setIOCellNo(businessValues.io_cell_nr || 0); 
    setPracticeNr(businessValues.practice_nr || '');
    setHpcsaNr(businessValues.hpcsa_nr || '');
    setMHGUsername(businessValues.mhg_username || '');
    setMHGPassword(businessValues.mhg_password || '');
    setVatNr(businessValues.vat_number || '');
    setAcceptContact(businessValues.accept_contact || '');
    setDebitOrderBool(businessValues.debit_order_bool || false);
    setBank(businessValues.debit_order_bank || '');
    setBranch(businessValues.debit_order_branch || '');
    setBranchCode(businessValues.debit_order_branch_code || 0);
    setAccountName(businessValues.debit_order_account_name || '');
    setAccountNo(businessValues.debit_order_account_no || 0);
    setAccountType(businessValues.debit_order_account_type || '');
    setDateOfDebitOrder(businessValues.date_of_debit_order || '');  
    setAgreementCheck(businessValues.accept_terms || false); 
    setSeenSocialMedia(businessValues.seen_social_media || '');
    setHeardFrom(businessValues.heard_from || '');
    setIdDocument(businessValues.id_document || '');
    setCompanyDocument(businessValues.company_document || '');
    setBankDetails(businessValues.banking_details || '');
    setDebitOrderBool(quoteConfig.debit_order || false);
  }, [businessValues, quoteConfig]);

  return (
    <>
    {businessDetailsLoading ?
    <>
    <DesktopLayout>
    <div className={styles.FormWrapper}>
      <div className={styles.notification}>
        <p className='BannerText'><strong>Info!</strong> To speed up the installation process, please complete the form below.</p>
      </div>
      
      <form onSubmit={validateFields}>
        <div className={styles.FormHeader}>
          <div className={styles.Heading}>
            <h2>Quote: Part B</h2>
          </div>
        </div>
        <br />
        <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Business Details</h2>
        <div className={styles.FieldGroupSingle}>
          <TextInputValidation
            editing={true}
            name="Name of Business/Practice: *"
            onBlur={
              (e:any) => {
                validation(e, 'none','businessName')
                BusinessDetailsUpdate('business_name', businessName)
              }
            }
            onChange={(event) => setBusinessName(event)} 
            errors={businessNameErr}
            value={businessName}
          />

          <TextInputValidation
            editing={true}
            name="Trading As: *"
            onBlur={
              (e:any) => {
                validation(e, 'none','tradingAs')
                BusinessDetailsUpdate('trading_as', tradingAs)
              }
            }
            onChange={(event) => setTradingAs(event)}
            errors={tradingAsErr}
            value={tradingAs}
          />

          <label className={styles.left}>Business Type: *</label>
          <RadioGroup className={styles.row} row aria-label="position" value={businessType} 
            onChange={(e) => setBusinessType(e.target.value)}
            onBlur={() => {BusinessDetailsUpdate('business_type', businessType)}}
          >
            <FormControlLabel value="Sole Practitioner" control={<Radio />} label="Sole Practitioner" />
            <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" />
            <FormControlLabel value="Incorporation" control={<Radio />} label="Incorporation" />
            <FormControlLabel value="Close Corporation" control={<Radio />} label="Close Corporation" />
            <FormControlLabel value="(Pty) Ltd" control={<Radio />} label="(Pty) Ltd" />
            <FormControlLabel value="Trust" control={<Radio />} label="Trust" />
          </RadioGroup>
        </div>
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="VAT number:"
              onBlur={() => BusinessDetailsUpdate('vat_number', vatNr)}
              onChange={(event) => setVatNr(event)}
              value={vatNr}
            />
            </div>
          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="ID or Company Registration number: *"
              onBlur={
                (e:any) => {
                  validation(e, 'none','idRegNr')
                  BusinessDetailsUpdate('id_reg_nr', idRegNr)
                }
              }
              onChange={(event) => setIdRegNr(event)}
              errors={idRegNrErr}
              value={idRegNr}
            />
          </div>
        </div>

        <label className={styles.row} style={{ color: 'red' }}>Use an asterisk '*' in the postal address to copy the street address</label>
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
          <h2 className={bstyles.FieldHeader}>Street Address</h2>
            <TextInputValidation
              editing={true}
              name="Street address line 1: *"
              onBlur={
                (e:any) => {
                  validation(e, 'none','streetAddress')
                  BusinessDetailsUpdate('street_address', streetAddress)
                }
              }
              onChange={(event) => setStreetAddress(event)}
              errors={streetAddressErr}
              value={streetAddress}
            />

            <TextInputValidation
              editing={true}
              name="Street address line 2:"
              onBlur={() => BusinessDetailsUpdate('street_address2', streetAddress2)}
              onChange={(event) => setStreetAddress2(event)}
              errors=""
              value={streetAddress2}
            />
            <TextInputValidation
              editing={true}
              name="Street address line 3:"
              onBlur={() => BusinessDetailsUpdate('street_address3', streetAddress3)}
              onChange={(event) => setStreetAddress3(event)}
              errors=""
              value={streetAddress3}
            />
          </div>
          <div className={styles.FieldGroupR}>
            <h2 className={bstyles.FieldHeader}>Postal Address</h2>
            <TextInputValidation
              editing={true}
              name="Postal address line 1: *"
              onBlur={
                (e:any) => {
                  validation(e, 'none','postalAddress')
                  if (e.target.value.includes('*')) { 
                    BusinessDetailsUpdate('postal_address', streetAddress);
                    BusinessDetailsUpdate('postal_address2', streetAddress2);
                    BusinessDetailsUpdate('postal_address3', streetAddress3);
                  }
                  else {
                    BusinessDetailsUpdate('postal_address', postalAddress);
                  }
                }
              }
              onChange={(event) => setPostalAddress(event)}
              errors={postalAddressErr}
              value={postalAddress}
            />

            <TextInputValidation
              editing={true}
              name="Postal address line 2:"
              onBlur={() => BusinessDetailsUpdate('postal_address2', postalAddress2)}
              onChange={(event) => setPostalAddress2(event)}
              errors=""
              value={postalAddress2}
            />
            <TextInputValidation
              editing={true}
              name="Postal address line 3:"
              onBlur={() =>  BusinessDetailsUpdate('postal_address3', postalAddress3)}
              onChange={(event) => setPostalAddress3(event)}
              errors=""
              value={postalAddress3}
            />
          </div>   
        </div>

        <div className={styles.FieldGroupSingle}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Contact Details</h2>
          <TextInputValidation
            editing={true}
            name="Name of Contact Person: *"
            onBlur={
              (e:any) => {
                validation(e, 'none','contactPerson')
                BusinessDetailsUpdate('contact_person', contactPerson)
              }
            }
            onChange={(event) => setContactPerson(event)}
            errors={contactPersonErr}
            value={contactPerson}
          />
        </div>
        <div className={styles.FieldGroupTripple}>
          <div className={styles.FieldGroupLL}>
            <TextInputValidation
              name={"Telephone number of practice: *"}
              editing={true}
              onBlur={
                (e:any) => {
                  validation(e, 'none','telNr')
                  BusinessDetailsUpdate('tel_nr', telNr)
                }
              }
              onChange={(event:any) => setTelNr(event)}
              errors={telNrErr}
              value={String(telNr)}
              maxLength={10}
            />
          </div>

          <div className={styles.FieldGroupCC}>
            <TextInputValidation
              name={"Cell number of practice: *"}
              editing={true}
              onBlur={
                (e:any) => {
                  validation(e, 'none','cellNr')
                  BusinessDetailsUpdate('cell_nr', cellNr)
                }
              }
              value={String(cellNr)}
              onChange={(event:any) => setCellNr(event)}
              errors={cellNrErr}
              maxLength={10}
            />
          </div>

          <div className={styles.FieldGroupRR}>
            <TextInputValidation
              editing={true}
              name="Fax number:"
              onBlur={() => BusinessDetailsUpdate('fax_nr', faxNr)}
              onChange={(event) => setFaxNr(event)}
              errors=""
              value={faxNr}
              maxLength={10}
            />
          </div>
        </div>

        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="Email address for practice: *"
              onBlur={
                (e:any) => {
                  validation(e, 'email','debitOrderEmail')
                  BusinessDetailsUpdate('email_debit_order', emailDebitOrder)
                }  
              }
              onChange={(event) => setEmailDebitOrder(event)}
              errors={emailDebitOrderErr}
              value={emailDebitOrder}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="Email address for newsletter: *"
              onBlur={
                (e:any) => {
                  validation(e, 'email','emailNewsletter')
                  BusinessDetailsUpdate('email_newsletter', emailNewsletter)
                }  
              }
              onChange={(event) => setEmailsNewsletter(event)}
              errors={emailNewsletterErr}
              value={emailNewsletter}
            />
          </div>
        </div>

        <div className={styles.FieldGroupDouble}>  
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="Email address for Practice Manager: *"
              onBlur={
                (e:any) => {
                  validation(e, 'email','emailPracticeManager')
                  BusinessDetailsUpdate('email_practice_manager', emailPracticeManager)
                }
              }
              onChange={(event) => setEmailPracticeManager(event)}
              errors={emailPracticeManagerErr}
              value={emailPracticeManager}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="Email address for accounts: *"
              onBlur={
                (e:any) => {
                  validation(e, 'email','emailAccounts')
                  BusinessDetailsUpdate('email_accounts', emailAccounts)
                }
              }
              onChange={(event) => setEmailAccounts(event)}
              errors={emailAccountsErr}
              value={emailAccounts}
            />
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Information Officer Details</h2>
          <TextInputValidation
            editing={true}
            name="Information Officers Name (POPI Act - Contracts): *"
            onBlur={
              (e:any) => {
                validation(e, 'None','creditControlName')
                BusinessDetailsUpdate('credit_control_name', creditControlName)
              }
            }
            onChange={(event) => setCreditControlName(event)}
            errors={creditControlNameErr}
            value={creditControlName}
          />
        </div>

        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>  
            <TextInputValidation
              name={"Cell number of Information Officer: *"}
              editing={true}
              onBlur={
                (e:any) => {
                  validation(e, 'None','IOCellNo')
                  BusinessDetailsUpdate('io_cell_nr', IOCellNo)
                }
              }
              onChange={(event:any) => setIOCellNo(event)}
              errors={IOCellNoErr}
              value={String(IOCellNo)}
              maxLength={10}
            />  
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="Information Officers email address: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','creditControlEmail')
                  BusinessDetailsUpdate('credit_control_email', creditControlEmail)
                }
              }
              onChange={(event) => setCreditControlEmail(event)}
              errors={creditControlEmailErr}
              value={creditControlEmail}
            />
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Practice Details</h2>
          <label className={styles.left}>Discipline: *</label>
          <select
            className={disciplineErr ? 'Error' : ''}
            value={discipline}
            onBlur={(e:any) => {
              validation(e, 'none','discipline')
              BusinessDetailsUpdate('discipline', discipline)
            }
            }
            onChange={(e) => {
              setDiscipline(e.target.value)
              BusinessDetailsUpdate('discipline', discipline)
            }}
            >
            <option></option>
            {disciplineApi.map((item: any) => (
              <option key={item.name} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="Practice (PCNS) number: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','practiceNr')
                  BusinessDetailsUpdate('practice_nr', practiceNr)
                }
              }
              onChange={(event) => setPracticeNr(event)}
              errors={practiceNrErr}
              value={practiceNr}
              maxLength={7}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="HPCSA number: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','hpcsaNr')
                  BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
                }
              }
              onChange={(event) => setHpcsaNr(event)}
              errors={hpcsaNrErr}
              value={hpcsaNr}
            />
          </div>
        </div>
        
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="MHG (Metropolitan Health Group) username:"
              onBlur={() => BusinessDetailsUpdate('mhg_username', mhgUsername)}
              onChange={(event) => setMHGUsername(event)}
              errors=""
              value={mhgUsername}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="MHG (Metropolitan Health Group) password:"
              onBlur={() => BusinessDetailsUpdate('mhg_password', mhgPassword)}
              onChange={(event) => setMHGPassword(event)}
              errors=""
              value={mhgPassword}
            />
          </div>
        </div>
        <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Upload Documents</h2>
        <div className={styles.FieldGroupTripple}>
          <div className={styles.FieldGroupLL}>
            <InputUpload
              name="ID Document"
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('id_document', idDocument)}
              errors={fileLT2MBValidator(idDocument) || idDocumentErr ? [fileLT2MBValidator(idDocument), idDocumentErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setIdDocument(event)
                BusinessDetailsUpdate('id_document', event)
              }}
              id={"iddoc"}
            />
          </div>
          <div className={styles.FieldGroupCC}>
            <InputUpload
              name="Company Document"
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('company_document', companyDocument)}
              errors={fileLT2MBValidator(companyDocument) || companyDocumentErr ? [fileLT2MBValidator(companyDocument), companyDocumentErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setCompanyDocument(event)
                BusinessDetailsUpdate('company_document', event)
              }}
              id={"companydoc"}
            />
          </div>
          
            <div className={styles.FieldGroupRR}>
              <InputUpload
                name="Proof of Banking Details"
                onBlur={() => BusinessDetailsUpdate('banking_details', bankDetails)}
                errors={fileLT2MBValidator(bankDetails) || bankDetailsErr ? [fileLT2MBValidator(bankDetails), bankDetailsErr].filter(Boolean) : null}
                onChange={(event: any) => {
                  setBankDetails(event)
                  BusinessDetailsUpdate('banking_details', event)
                }}
                id={"bankdoc"}
              />
            </div>
          </div>
          {quoteConfig?.debit_order === true ?
          <>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Banking Details</h2>
          <div className={styles.FieldGroupDouble}>
            <div className={styles.FieldGroupL}>
              <TextInputValidation
                editing={true}
                name="Account Name "
                onChange={(event) => setAccountName(event)}
                onBlur={
                  (e:any) => {
                    validation(e, 'None','accountName')
                    BusinessDetailsUpdate('debit_order_account_name', accountName)
                  }
                }
                errors={accountNameError}
                value={accountName}
                />
              </div>
                <div className={styles.FieldGroupR}>
                  <TextInputValidation
                    editing={true}
                    name="Bank: "
                    onChange={(event) => setBank(event)}
                    onBlur={
                      (e:any) => { 
                        validation(e, 'None','bank')
                        BusinessDetailsUpdate('debit_order_bank', bank)
                      }
                    }
                    errors={bankError}
                    value={bank}
                  />
                </div>
              </div>

              <div className={styles.FieldGroupDouble}>
                <div className={styles.FieldGroupL}>
                  <NumberInputValidation
                    editing={true}
                    name="Account Number "
                    onChange={(event) => setAccountNo(event)}
                    onBlur={
                      (e:any) => {
                        validation(e, 'None','accountNo')
                        BusinessDetailsUpdate('debit_order_account_no', accountNo)
                      }
                    }
                    errors={accountNoError}
                    value={accountNo || 0}
                  />
                </div>
              
                <div className={styles.FieldGroupR}>
                  <TextInputValidation
                    editing={true}
                    name="Branch: "
                    onChange={(event) => setBranch(event)}
                    onBlur={
                      (e:any) => {
                        validation(e, 'None','branch')
                        BusinessDetailsUpdate('debit_order_branch', branch)
                      }
                    }
                    errors={bankBranchError}
                    value={branch}
                  />
                </div>
              </div>
              <div className={styles.FieldGroupDouble}>
                <div className={styles.FieldGroupL}>
                  <NumberInputValidation
                    editing={true}
                    name="Branch Code: "
                    onChange={(event) => setBranchCode(event)}
                    onBlur={
                      (e:any) => {
                        validation(e, 'None','branchCode')
                        BusinessDetailsUpdate('debit_order_branch_code', branchCode)
                      }
                    }
                    errors={branchCodeError}
                    value={branchCode}
                  />
                </div>
            
                <div className={styles.FieldGroupR}>
                  <label className={styles.lineHeight29}>Account Type:</label>
                    <select
                      className={accountTypeError ? 'Error'&&bstyles.width108 : bstyles.width108}
                      value={accountType}
                      name="account Type"
                      onBlur={() => BusinessDetailsUpdate('debit_order_account_type', accountType)}
                      onChange={(event) => setAccountType(event.target.value)}>
                          <option> </option>
                          <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                          <option key={2} value="Savings">Savings</option>
                          <option key={3} value="transmission">transmission</option>
                  </select>
                </div>
              </div>
              <div className={styles.FieldGroupSingle}>
                <label>Date Of Debit Order:</label>
                  <select
                    className={dateOfDebitOrderError ? 'Error' : ''}
                    value={dateOfDebitOrder}
                    name="Date of debit order"
                    onBlur={() => BusinessDetailsUpdate('date_of_debit_order', dateOfDebitOrder)}
                    onChange={(event) => setDateOfDebitOrder(event.target.value)}>
                        <option> </option>
                        <option key={1} value="3">3</option>
                        <option key={2} value="7">7</option>
                        <option key={3} value="15">15</option>
                </select>
              </div>
              </>
              :<></>
           }
        <>
        { isTokenValid.isTemp ?
        <></>:
        <>
          <label>Heard From: *</label>
          <div className={styles.select}>
          <Select
            className={styles.multiValue}
            isMulti={true}
            onBlur={(e) => validation(e, 'None','heardFrom')}
            onChange={(selectedOptions) => {
              if (selectedOptions) {
                setHeardFrom(selectedOptions.map(option => ({
                  label: option.label,
                  value: option.value
                })))
                BusinessDetailsUpdate('heardFrom', selectedOptions)
              } 
              else {
                setHeardFrom([]);
                BusinessDetailsUpdate('heardFrom', []);
              }
            }}
            options={[...HeardFromOptions]}
            value={heardFrom || []}
          />
          </div>
          <Validator errors={heardFromErr} />

          <label>Seen Social Media: *</label>
          <div className={styles.FieldGroupSingle}>
            <select
              className={seenSocialMediaErr ? 'Error' : ''}
              value={seenSocialMedia}
              onChange={(e) => setSeenSocialMedia(e.target.value)} 
              onBlur={(e:any) => {
                validation(e, 'None','seenSocialMedia')
                BusinessDetailsUpdate('seen_social_media', seenSocialMedia)}
              }
              >
              <option></option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
            </select>
            <span className={styles.ErrorMsg}>{seenSocialMediaErr}</span>
          </div>
          <br />
          <div className={styles.TermsWrapper}>
            <h3>Acceptance of Terms of Agreement</h3>
            <p>
              I, <input className={styles.ContractUndersigned}
                name="agreement_contact"
                placeholder={"Enter Full Name *"}
                onBlur={(e:any) => {
                  validation(e, 'None','acceptContact')
                  BusinessDetailsUpdate('accept_contact', acceptContact)
                }}
                onChange={(event) => setAcceptContact(event.target.value)}
                value={acceptContact}
              />, confirm that the information supplied, is correct.</p>
              <span className={styles.ErrorMsg}> {acceptContactErr}</span>
          </div>
        </>
        }
        </>

      { isTokenValid.isTemp ?
        <>
        {isLoading ?
          <button
            className={styles.SubmitBtn}
            onClick={marketerSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
        <div className={styles.FieldGroupSingle}><button
        className={bstyles.SubmitBtns}
        onClick={marketerSubmit}
          >
        Save <span className="material-icons">arrow_forward</span>
      </button></div>
        }</>
        :<>
        {isLoading ?
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
          >
            Submit <span className="material-icons">arrow_forward</span>
          </button>
        }</>
      }

        <ToastContainer />
        
        </form>
      </div>
      </DesktopLayout>
      
      <MobileLayout>
      <div className={responsive.PageWrapper}>
        <form onSubmit={validateFields}>
          <div className={responsive.OverviewFirstRow}>
            <div className={styles.Heading}>
              <h2>Quote: Part B</h2>
            </div>
          </div>

          <div className={responsive.OverviewPanel}>
            <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Business Details</h2>
            <div >
              <TextInputValidation
                editing={true}
                name="Name of Business/Practice: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'none','businessName')
                    BusinessDetailsUpdate('business_name', businessName)
                  }
                }
                onChange={(event) => setBusinessName(event)}
                errors={businessNameErr}
                value={businessName}
              />
              <TextInputValidation
                editing={true}
                name="Trading As: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'none','tradingAs')
                    BusinessDetailsUpdate('trading_as', tradingAs)
                  }
                }
                onChange={(event) => setTradingAs(event)}
                errors={tradingAsErr}
                value={tradingAs}
              />
            </div>
            <br />
            <label className={styles.top}>Business Type: *</label>
            <RadioGroup className={styles.column} row aria-label="position" value={businessType} 
              onChange={(e) => setBusinessType(e.target.value)}
              onBlur={() => {BusinessDetailsUpdate('business_type', businessType)}}
            >
              <FormControlLabel value="Sole Practitioner" control={<Radio />} label="Sole Practitioner" />
              <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" />
              <FormControlLabel value="Incorporation" control={<Radio />} label="Incorporation" />
              <FormControlLabel value="Close Corporation" control={<Radio />} label="Close Corporation" />
              <FormControlLabel value="(Pty) Ltd" control={<Radio />} label="(Pty) Ltd" />
              <FormControlLabel value="Trust" control={<Radio />} label="Trust" />
            </RadioGroup>
            
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="VAT number:"
                  onBlur={() => BusinessDetailsUpdate('vat_number', vatNr)}
                  onChange={(event) => setVatNr(event)}
                  value={vatNr}
                />
              </div>

              <div className={responsive.FieldLayoutEndGrid}>
                <TextInputValidation
                  editing={true}
                  name="ID or Company Registration number: *"
                  onBlur={
                    (e:any) => {
                      validation(e, 'none','idRegNr')
                      BusinessDetailsUpdate('id_reg_nr', idRegNr)
                    }
                  }
                  onChange={(event) => setIdRegNr(event)}
                  errors={idRegNrErr}
                  value={idRegNr}
                />
              </div>
            </div>
          </div>

          <div className={responsive.OverviewPanel}>
            <h2 className={responsive.FieldHeader}>Street Address</h2>
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Street address line 1: *"
                  onBlur={
                    (e:any) => {
                      validation(e, 'none','streetAddress')
                      BusinessDetailsUpdate('street_address', streetAddress)
                    }
                  }
                  onChange={(event) => setStreetAddress(event)}
                  errors={streetAddressErr}
                  value={streetAddress}
                />
                <TextInputValidation
                  editing={true}
                  name="Street address line 2:"
                  onBlur={() => BusinessDetailsUpdate('street_address2', streetAddress2)}
                  onChange={(event) => setStreetAddress2(event)}
                  errors=""
                  value={streetAddress2}
                />
                <TextInputValidation
                  editing={true}
                  name="Street address line 3:"
                  onBlur={() => BusinessDetailsUpdate('street_address3', streetAddress3)}
                  onChange={(event) => setStreetAddress3(event)}
                  errors=""
                  value={streetAddress3}
                />
              </div>

              <div className={responsive.FieldLayoutEndGrid}>
                <TextInputValidation
                  editing={true}
                  name="Postal address line 1: *"
                  onBlur={
                    (e:any) => {
                      validation(e, 'none','postalAddress')
                      if (e.target.value.includes('*')) { 
                        BusinessDetailsUpdate('postal_address', streetAddress);
                        BusinessDetailsUpdate('postal_address2', streetAddress2);
                        BusinessDetailsUpdate('postal_address3', streetAddress3);
                      }
                      else {
                        BusinessDetailsUpdate('postal_address', postalAddress);
                      }
                    }
                  }
                  onChange={(event) => setPostalAddress(event)}
                  errors={postalAddressErr}
                  value={postalAddress}
                />
            
                <TextInputValidation
                  editing={true}
                  name="Postal address line 2:"
                  onBlur={() => BusinessDetailsUpdate('postal_address2', postalAddress2)}
                  onChange={(event) => setPostalAddress2(event)}
                  errors=""
                  value={postalAddress2}
                />
                <TextInputValidation
                  editing={true}
                  name="Postal address line 3:"
                  onBlur={() =>  BusinessDetailsUpdate('postal_address3', postalAddress3)}
                  onChange={(event) => setPostalAddress3(event)}
                  errors=""
                  value={postalAddress3}
                />
              </div>
            </div>
          </div>
          <div className={responsive.OverviewPanel}>
            <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Contact Details</h2>
            <div>
            <TextInputValidation
              editing={true}
              name="Name of Contact Person: *"
              onBlur={
                (e:any) => {
                  validation(e, 'none','contactPerson')
                  BusinessDetailsUpdate('contact_person', contactPerson)
                }
              }
              onChange={(event) => setContactPerson(event)}
              errors={contactPersonErr}
              value={contactPerson}
            />
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                name={"Telephone number of practice: *"}
                editing={true}
                onBlur={
                  (e:any) => {
                    validation(e, 'none','telNr')
                    BusinessDetailsUpdate('tel_nr', telNr)
                  }
                }
                onChange={(event:any) => setTelNr(event)}
                errors={telNrErr}
                value={String(telNr)}
                maxLength={10}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                name={"Cell number of practice: *"}
                editing={true}
                onBlur={
                  (e:any) => {
                    validation(e, 'none','cellNr')
                    BusinessDetailsUpdate('cell_nr', cellNr)
                  }
                }
                onChange={(event:any) => setCellNr(event)}
                errors={cellNrErr}
                value={String(cellNr)}
                maxLength={10}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Fax number:"
                onBlur={() => BusinessDetailsUpdate('fax_nr', faxNr)}
                onChange={(event) => setFaxNr(event)}
                errors=""
                value={faxNr}
                maxLength={10}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Email address for practice: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'email','debitOrderEmail')
                    BusinessDetailsUpdate('email_debit_order', emailDebitOrder)
                  }  
                }
                onChange={(event) => setEmailDebitOrder(event)}
                errors={emailDebitOrderErr}
                value={emailDebitOrder}
              />
            </div>

            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name="Email address for newsletter: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'email','emailNewsletter')
                    BusinessDetailsUpdate('email_newsletter', emailNewsletter)
                  }  
                }
                onChange={(event) => setEmailsNewsletter(event)}
                errors={emailNewsletterErr}
                value={emailNewsletter}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Email address for Practice Manager: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'email','emailPracticeManager')
                    BusinessDetailsUpdate('email_practice_manager', emailPracticeManager)
                  }
                }
                onChange={(event) => setEmailPracticeManager(event)}
                errors={emailPracticeManagerErr}
                value={emailPracticeManager}
              />
            </div>
            
            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name="Email address for accounts: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'email','emailAccounts')
                    BusinessDetailsUpdate('email_accounts', emailAccounts)
                  }
                }
                onChange={(event) => setEmailAccounts(event)}
                errors={emailAccountsErr}
                value={emailAccounts}
              />
            </div>
          </div>
        </div>
        <div className={responsive.OverviewPanel}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Information Officer Details</h2>
          <div>
            <TextInputValidation
              editing={true}
              name="Information Officers Name (POPI Act - Contracts): *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','creditControlName')
                  BusinessDetailsUpdate('credit_control_name', creditControlName)
                }
              }
              onChange={(event) => setCreditControlName(event)}
              errors={creditControlNameErr}
              value={creditControlName}
            />
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                name={"Cell number of Information Officer: *"}
                editing={true}
                onBlur={
                  (e:any) => {
                    validation(e, 'None','IOCellNo')
                    BusinessDetailsUpdate('io_cell_nr', IOCellNo)
                  }
                }
                onChange={(event:any) => setIOCellNo(event)}
                errors={IOCellNoErr}
                value={String(IOCellNo)}
                maxLength={10}
              />  
            </div>
            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name="Information Officers email address: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','creditControlEmail')
                    BusinessDetailsUpdate('credit_control_email', creditControlEmail)
                  }
                }
                onChange={(event) => setCreditControlEmail(event)}
                errors={creditControlEmailErr}
                value={creditControlEmail}
              />
            </div>
          </div>
        </div>

        <div className={responsive.OverviewPanel}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Practice Details</h2>
          <div>
            <label>Discipline: *</label>
            <select
              className={disciplineErr ? 'Error' : ''}
              value={discipline}
              onBlur={(e:any) => validation(e, 'none','discipline')}
              onChange={(e) => {
                setDiscipline(e.target.value)
                BusinessDetailsUpdate('discipline', discipline)
              }}>
              <option></option>
              {disciplineApi.map((item: any) => (
                <option key={item.name} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <br/>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Practice (PCNS) number: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','practiceNr')
                    BusinessDetailsUpdate('practice_nr', practiceNr)
                  }
                }
                onChange={(event) => setPracticeNr(event)}
                errors={practiceNrErr}
                value={practiceNr}
                maxLength={7}
              />
            </div>

            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name="HPCSA number: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','hpcsaNr')
                    BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
                  }
                }
                onChange={(event) => setHpcsaNr(event)}
                errors={hpcsaNrErr}
                value={hpcsaNr}
              />
            </div>
          </div>
          <div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="MHG (Metropolitan Health Group) username:"
                onBlur={() => BusinessDetailsUpdate('mhg_username', mhgUsername)}
                onChange={(event) => setMHGUsername(event)}
                errors=""
                value={mhgUsername}
              />
            </div>
            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name="MHG (Metropolitan Health Group) password:"
                onBlur={() => BusinessDetailsUpdate('mhg_password', mhgPassword)}
                onChange={(event) => setMHGPassword(event)}
                errors=""
                value={mhgPassword}
              />
            </div>
          </div>
        </div>

        <div className={responsive.OverviewPanel}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Upload Documents</h2>
          <div>
            <InputUpload
              name="ID Document"
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('id_document', idDocument)}
              errors={fileLT2MBValidator(idDocument) || idDocumentErr ? [fileLT2MBValidator(idDocument), idDocumentErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setIdDocument(event)
                BusinessDetailsUpdate('id_document', event)
              }}
              className={responsive.InputBox}
              id={"iddoc"}
            />
            <InputUpload
              name="Company Document"
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('company_document', companyDocument)}
              errors={fileLT2MBValidator(companyDocument) || companyDocumentErr ? [fileLT2MBValidator(companyDocument), companyDocumentErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setCompanyDocument(event)
                BusinessDetailsUpdate('company_document', event)
              }}
              id={"companydoc"}
            />
            <InputUpload
              name="Proof of Banking Details"
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('banking_details', bankDetails)}
              errors={fileLT2MBValidator(bankDetails) || bankDetailsErr ? [fileLT2MBValidator(bankDetails), bankDetailsErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setBankDetails(event)
                BusinessDetailsUpdate('banking_details', event)
              }}
              id={"bankdoc"}
            />
          </div>          
        </div>
        
        {quoteConfig?.debit_order === true ?
          <div className={responsive.OverviewPanel}>
          <>
          
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Banking Details</h2>
          <div className={responsive.OverviewRow}>
            <TextInputValidation
              editing={true}
              name="Account Name "
              onChange={(event) => setAccountName(event)}
              onBlur={
                (e:any) => {
                  validation(e, 'None','accountName')
                  BusinessDetailsUpdate('debit_order_account_name', accountName)
                }
              }
              errors={accountNameError}
              value={accountName}
            />
            
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Bank: "
                  onChange={(event) => setBank(event)}
                  onBlur={
                    (e:any) => { 
                      validation(e, 'None','bank')
                      BusinessDetailsUpdate('debit_order_bank', bank)
                    }
                  }
                  errors={bankError}
                  value={bank}
                />
              </div>

              <div className={responsive.FieldLayoutEndGrid}>
                <NumberInputValidation
                  editing={true}
                  name="Account Number "
                  onChange={(event) => setAccountNo(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','accountNo')
                      BusinessDetailsUpdate('debit_order_account_no', accountNo)
                    }
                  }
                  errors={accountNoError}
                  value={accountNo}
                />
              </div>
            </div>
            
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Branch: "
                  onChange={(event) => setBranch(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','branch')
                      BusinessDetailsUpdate('debit_order_branch', branch)
                    }
                  }
                  errors={bankBranchError}
                  value={branch}
                />
              </div>
              <div className={responsive.FieldLayoutEndGrid}>
                <NumberInputValidation
                  editing={true}
                  name="Branch Code: "
                  onChange={(event) => setBranchCode(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','branchCode')
                      BusinessDetailsUpdate('debit_order_branch_code', branchCode)
                    }
                  }
                  errors={branchCodeError}
                  value={branchCode}
                />
              </div>
            </div>
            <div>
              <div className={responsive.FieldAccountType}>
                <label>Account Type:</label>
                    <select
                    className={accountTypeError ? 'Error' : ''}
                    value={accountType}
                    name="account Type"
                    onBlur={() => BusinessDetailsUpdate('debit_order_account_type', accountType)}
                    onChange={(event) => setAccountType(event.target.value)}>
                        <option> </option>
                        <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                        <option key={2} value="Savings">Savings</option>
                        <option key={3} value="transmission">transmission</option>
                </select>
              </div>
              <div className={responsive.FieldDebitOrderDate}>
                <label>Date Of Debit Order:</label>
                    <select
                    className={dateOfDebitOrderError ? 'Error' : ''}
                    value={dateOfDebitOrder}
                    name="Date of debit order"
                    onBlur={() => BusinessDetailsUpdate('date_of_debit_order', dateOfDebitOrder)}
                    onChange={(event) => setDateOfDebitOrder(event.target.value)}>
                        <option> </option>
                        <option key={1} value="3">3</option>
                        <option key={2} value="7">7</option>
                        <option key={3} value="15">15</option>
                </select>
              </div>
            </div>
            </div>
          </>
          </div>
        : null
        }
        <>
        { isTokenValid.isTemp ?
        <></>:
        
        <div className={responsive.OverviewPanel}>
        <>
        <div className={responsive.OverviewRow}>
          <label>Heard From: *</label>
          <Select
            className={styles.multiValue}
            isMulti={true}
            onBlur={(e) => validation(e, 'None','heardFrom')}
            onChange={(selectedOptions) => {
              if (selectedOptions) {
                setHeardFrom(selectedOptions.map(option => ({
                  label: option.label,
                  value: option.value
                })))
                BusinessDetailsUpdate('heardFrom', selectedOptions)
              } 
              else {
                setHeardFrom([]);
                BusinessDetailsUpdate('heardFrom', []);
              }
            }}
            options={[...HeardFromOptions]}
            value={heardFrom || []}
          />
          
          <label>Seen Social Media: *</label>
          <select
            className={disciplineErr ? 'Error' : ''}
            value={seenSocialMedia}
            onChange={(e) => setSeenSocialMedia(e.target.value)}
            onBlur={(e:any) => {
              validation(e, 'None','seenSocialMedia')
              BusinessDetailsUpdate('seen_social_media', seenSocialMedia)}
            }
            >
            <option></option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </select>
          <span className={styles.ErrorMsg}>{seenSocialMediaErr}</span>
        </div>
        <div className={responsive.TermsWrapper}>
          <h3>Acceptance of Terms of Agreement</h3>
          <p>
            I, <input className={responsive.ContractUndersigned}
              name="agreement_contact"
              placeholder={"Enter Full Name *"}
              onBlur={
                (e:any) => {
                  validation(e, 'None','acceptContact')
                  BusinessDetailsUpdate('accept_contact', acceptContact)
                }
              }
              onChange={(event) => setAcceptContact(event.target.value)}
              value={acceptContact}
            />, confirm that the information supplied, is correct.</p>
            <span className={responsive.ErrorMsg}> {acceptContactErr}</span>
        </div>
        </>
        </div>
        }
        </>
        { isTokenValid.isTemp ?
        <>
        {isLoading ?
          <button
            className={responsive.SubmitButton}
            onClick={marketerSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : <button
          className={responsive.SubmitButton}
          onClick={marketerSubmit}
            >
          Save <span className="material-icons">arrow_forward</span>
        </button>
        }
        </>
        :
        <>
        {isLoading ?
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
          >
            Submit <span className="material-icons">arrow_forward</span>
          </button>
        }
        </>
      }
        

        <ToastContainer />

        </form>
      </div>
      </MobileLayout>
      </>
      :
      <div className={styles.FormWrapper}>
        <div className={styles.QuoteCompleteCard}>
            <label className={styles.QuoteCompleteCardHeading}>Quote # {businessValues.quote_id}</label><br /><br />
            <label className={styles.QuoteCompleteCardHeading}>Your quotation has already been accepted.</label>
            <br/>
        </div>
      </div>
    }
    </>
  
  )
};

export default BusinessDetails;
