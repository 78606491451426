import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { get, post } from "../../utils/api";
import styles from './Quotation.module.scss';

type AdditionalServicesTypes = {
    quoteAccepted?: boolean;
};

const AdditionalServices: React.FC <AdditionalServicesTypes>= (quoteAccepted) => {
   const [priceList, setPriceList] = useState([]);
   const [packageSize, setPackageSize] = useState('');
   const [error, setError] = useState('');
   const history = useHistory();

   useEffect(() => {
    const fetchQuotationList = async () => {
      const resp = await get('/quotation/get_adhoc_price_list');
      if (resp.ok) {
        setPriceList(await resp.json());
      }
    };

    fetchQuotationList();
  }, []);

    const additionalServicesHandleChange = async(e:any) => {
        const { value } = e.target;
        setPackageSize(value);
    };

    const openAdditionalServicesDialog = () => {
        setError('');
        var elem = document.getElementById("additionalServicesDialog") as HTMLInputElement;
        elem.style.display = "block";
    };

    const closeAdditionalServicesDialog = () => {
        setError('');
        var elem = document.getElementById("additionalServicesDialog") as HTMLInputElement;
        elem.style.display = "none";
    };
    
    const additionalServicesAddLines = async(id:any) => {
        setError('');
        const resp = await post('/quotation/additional_services_add_lines', {
          price_list: packageSize,
          quote_id: id,
        });

        if (resp.ok) {
          const data = await resp.json();
          if (data) {
            closeAdditionalServicesDialog();
            window.location.reload();
          }
        }
        setError('Server error occured, unable to add additional services billing items'); 
    };            

    const additionalServicesRemoveLines = async(id:any) => {
      setError('');
      const idList: any = [];

        Object.entries(priceList).map(([category, items]) => (
          Object.entries(items).map(([item, value]: any) => (
            Object.entries(value).map(([itemone, list_ids]: any) => (
              idList.push(list_ids.id)
            ))
          ))
        ))

        const resp = await post('/quotation/additional_services_remove_lines', {
          price_list: idList,
          quote_id: id,
        });

        if (resp.ok) {
          const data = await resp.json();
          if (data) {
            closeAdditionalServicesDialog();
            window.location.reload();
          }
        }
        setError('Server error occured, unable to remove additional services billing items'); 
    };

    return (
        <>
        {!quoteAccepted.quoteAccepted ? <button className={styles.ActionBtn} onClick={openAdditionalServicesDialog}>Add Additional Services</button>:null}
        <div id="additionalServicesDialog" className={styles.modal}>
            <div className={styles.modalContent}>
                <h4 className={styles.PopupHeader} style={{textAlign: 'left', margin: 'auto'}}>
                    Choose Additional Services Package below
                </h4>
                <hr />
                <br />
                <span style={{textAlign: 'left'}}>Price List:</span> 
                <select className={styles.input} name="price_list" style={{width: '60%' }} onChange={(e) => additionalServicesHandleChange(e)}>
                    <option key={0}></option>
                    {priceList.map((list: any) => (
                        list.pricelist_data.map((item: any) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))
                    ))}
                </select>
                <button className={styles.ActionBtn} onClick={() => additionalServicesAddLines(history.location.pathname.split('/')[2])}>OK</button>
                <button className={styles.ActionBtn} onClick={() => additionalServicesRemoveLines(history.location.pathname.split('/')[2])}>Remove All</button>
                <button className={styles.ActionBtn} onClick={closeAdditionalServicesDialog}>Cancel</button>
                <br />
                <br />
                <span className={styles.error}>{error}</span>
            </div>
        </div>
        </>
    )
};

export default AdditionalServices;
