import React, { useState } from 'react';

import ModalPage from '../../ModalPage/ModalPage';
import styles from './Many2OneInput.module.scss';
import Many2OneType from './Many2OneTypes';

type Many2OneModalPropTypes = {
  onChange: (value?: Many2OneType) => void;
  onClose: () => void;
  values?: Many2OneType[];
};

const ModalListRow = ({
  onChange,
  value,
}: {
  onChange: (value?: Many2OneType) => void;
  value: Many2OneType;
}) => (
  <tr onClick={() => onChange(value)}>
    <td>{value.id}</td>
    <td>{value.name}</td>
  </tr>
);

const Many2OneModalTable = ({
  values,
  filter,
  onChange,
}: {
  values?: Many2OneType[];
  filter?: RegExp;
  onChange: (value?: Many2OneType) => void;
}) => {
  const rows =
    values
      ?.filter(
        (v) =>
          filter === undefined ||
          v.id.toString().match(filter) ||
          v.name.match(filter)
      )
      .map((value) => (
        <ModalListRow key={value.id} {...{ onChange, value }} />
      )) || null;

  return <>{rows}</>;
};

const Many2OneModal = ({
  onChange,
  onClose,
  values,
}: Many2OneModalPropTypes) => {
  const [filterInput, setFilterInput] = useState('');

  const filter =
    filterInput === ''
      ? undefined
      : new RegExp(filterInput.replace(' ', '|'), 'i');

  return (
    <ModalPage {...{ onClose }}>
      <div className={styles.Many2OneModal}>
        <h2>Select the new value to set it</h2>
        <input
          value={filterInput}
          onChange={(e) => setFilterInput(e.target.value)}
          placeholder="Filter"
          autoFocus
          className={styles.FieldInput}
        />

        <div className={styles.TableContainer}>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <Many2OneModalTable {...{ values, filter, onChange }} />
            </tbody>
          </table>
        </div>

        <button onClick={() => onClose()}>Close</button>
      </div>
    </ModalPage>
  );
};

export default Many2OneModal;
