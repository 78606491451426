import React from 'react';

import styles from './Buttons.module.scss';
import call from './call';

const ComboButtons = ({ instanceID }: { instanceID: string | number[] }) => {
  const upgradeVersion = async () =>
    await call('gxweb_version_upgrade', instanceID);
  const rollbackVersion = async () =>
    await call('gxweb_vesrion_rollback', instanceID);
  const gxwebSetup = async () => await call('gxweb_setup', instanceID);
  const gxwebTeardown = async () => await call('gxweb_teardown', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={upgradeVersion}>Upgrade version</button>
      <button onClick={rollbackVersion}>Rollback version</button>
      <button onClick={gxwebSetup}>GXWeb Setup</button>
      <button onClick={gxwebTeardown}>GXWeb Teardown</button>
    </div>
  );
};

export default ComboButtons;
