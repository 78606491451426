import React from 'react';

const Validator = ({ errors }: any) => {
  return (
    <>
      <div></div>
      <div
        className={['ValidationText', 'Error', errors ? null : 'Hidden'].join(
          ' ',
        )}
        title={errors}
      >
        {errors ? (
          <>
            <span className="material-icons small-icon">warning</span> {errors}
          </>
        ) : null}
      </div>
    </>
  );
};

export default Validator;
