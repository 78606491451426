import { get, post } from '../../utils/api';
import {
  GXWEB_VERSION_ACTIONS,
  GxwebVersionActions,
  GxwebVersionType,
} from './GxwebVersionReducer';

export const fetchGxwebVersion = async (
  dispatch: React.Dispatch<GxwebVersionActions>,
  id?: string
) => {
  const resp = await get(`/gxweb_version/${id}`);
  if (resp.ok) {
    dispatch({
      type: GXWEB_VERSION_ACTIONS.GXWEB_VERSION,
      value: await resp.json(),
    });
  }
};

export const saveGxwebVersion = async (
  gxwebVersion?: GxwebVersionType,
  id?: string
) => {
  if (!gxwebVersion || !id) {
    return undefined;
  }

  const resp = await post(`gxweb_version/${id}`, {
    is_lts: gxwebVersion?.is_lts,
    version: gxwebVersion?.version,
    gxweb_image: gxwebVersion?.gxweb_image,
    gxweb_branch: gxwebVersion?.gxweb_branch,
    portal_image: gxwebVersion?.portal_image,
    portal_branch: gxwebVersion?.portal_branch,
    numeric_version: gxwebVersion?.numeric_version,
  });

  if (resp.ok) {
    window.location.reload();
  }
};
