import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import Many2OneInput from '../../components/EditableInputs/Many2OneInput/Many2OneInput';
import TextInput from '../../components/EditableInputs/TextInput';
import EditSaveDiscardButtons from '../../components/EditSaveDiscardButtons/EditSaveDiscardButtons';
import { fetchHostingLocations } from '../../utils/many2OneAPI';
import { fetchHostingServer, saveHostingServer } from './HostingServerAPI';
import reducer, {
  HOSTING_SERVER_ACTIONS,
  HostingServerInitialState,
} from './HostingServerReducer';

const HostingServer: React.FC = () => {
  const { id }: any = useParams();

  const [{ editing, hostingServer, serverLocations }, dispatch] = useReducer(
    reducer,
    HostingServerInitialState
  );

  useEffect(() => {
    if (id !== undefined) {
      fetchHostingServer(dispatch, id);
    }
  }, [id]);

  useEffect(() => {
    if (editing) {
      fetchHostingLocations((value) =>
        dispatch({ type: HOSTING_SERVER_ACTIONS.SERVER_LOCATIONS, value })
      );
    }
  }, [editing]);

  if (id === undefined) {
    return null;
  }

  return (
    <div className="Page">
      <EditSaveDiscardButtons
        editing={editing}
        saveFn={() => saveHostingServer(hostingServer, id)}
        setEditing={(value) =>
          dispatch({ type: HOSTING_SERVER_ACTIONS.EDITING, value })
        }
      />
      <h1>{hostingServer?.hostname}</h1>

      <h2>Hosting Server Settings</h2>
      <div className="ValueGrid">
        <TextInput
          editing={editing}
          name="Hostname"
          onChange={(value) =>
            dispatch({ type: HOSTING_SERVER_ACTIONS.SERVER_HOSTNAME, value })
          }
          value={hostingServer?.hostname}
        />
        <TextInput
          editing={editing}
          name="Local IP"
          onChange={(value) =>
            dispatch({ type: HOSTING_SERVER_ACTIONS.SERVER_LOCAL_IP, value })
          }
          value={hostingServer?.local_ip}
        />
        <Many2OneInput
          editing={editing}
          link={`/hosting_location/${hostingServer?.hosting_location?.id}`}
          name="Server Location"
          onChange={(value) =>
            dispatch({ type: HOSTING_SERVER_ACTIONS.SERVER_LOCATION, value })
          }
          value={hostingServer?.hosting_location}
          values={serverLocations}
        />
      </div>
    </div>
  );
};

export default HostingServer;
