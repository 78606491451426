import React from 'react';

import { get, post } from '../../utils/api';
import {
  INSTANCE_ACTIONS,
  InstanceActions,
  InstanceData,
} from './instanceReducer';

export const fetchInstance = async (
  dispatch: React.Dispatch<InstanceActions>,
  id?: string
) => {
  const resp = await get(`/instance/${id}`);
  if (resp.ok) {
    dispatch({ type: INSTANCE_ACTIONS.INSTANCE, value: await resp.json() });
  }
};

export const fetchDatabases = async (
  dispatch: React.Dispatch<InstanceActions>
) => {
  const resp = await get('/database_login');
  if (resp.ok) {
    dispatch({ type: INSTANCE_ACTIONS.DATABASES, value: await resp.json() });
  }
};

export const fetchHostingLocations = async (
  dispatch: React.Dispatch<InstanceActions>
) => {
  const resp = await get('/hosting_location');
  if (resp.ok) {
    dispatch({
      type: INSTANCE_ACTIONS.HOSTING_LOCATIONS,
      value: await resp.json(),
    });
  }
};

export const fetchHostingServers = async (
  dispatch: React.Dispatch<InstanceActions>
) => {
  const resp = await get('/hosting_server');
  if (resp.ok) {
    dispatch({
      type: INSTANCE_ACTIONS.HOSTING_SERVERS,
      value: await resp.json(),
    });
  }
};

export const fetchGxwebVersions = async (
  dispatch: React.Dispatch<InstanceActions>
) => {
  const resp = await get('/gxweb_version');
  if (resp.ok) {
    dispatch({
      type: INSTANCE_ACTIONS.GXWEB_VERSIONS,
      value: await resp.json(),
    });
  }
};

export const saveInstance = async (instance?: InstanceData, id?: string) => {
  if (!instance || !id) {
    return undefined;
  }

  const resp = await post(`/instance/${id}`, {
    name: instance.name,
    active_type: instance.active_type,
    identifier: instance.identifier,
    database_id: instance.database.id,
    subdomain: instance.subdomain,
    hosting_location_id: instance.hosting_location.id,
    version_lts: instance.lts_version_only,
    version_freeze: instance.freeze_version,
    version: instance.gxweb_version.id,
    rollback_version: instance.gxweb_rollback_version.id,
    gxweb_env: instance.gxweb_env,
    wsclient_env: instance.wsclient_env,
    wsclient_host: instance.wsclient_host.id,
    wsclient_wfapi_url: instance.wsclient_wfapi_url,
  });

  if (resp.ok) {
    window.location.reload();
  }
};
