import React, { useEffect, useState } from 'react';

import TimeAgo from '../../../components/TimeAgo/TimeAgo';
import { get } from '../../../utils/api';
import styles from './AnsibleJobs.module.scss';

type JobType = {
  id: number;
  created: string;
  status: string;
  action: string;
  started: string;
  completed: string;
  user: string;
};

const jobActionMap: { [key: string]: string } = Object.freeze({
  acme_certificate: 'ACME Certificate',
  acme_copy: 'ACME Copy',
  cloudflare_upsert_records: 'Cloudflare Upsert Records',
  database_create: 'Database Create',
  docker_remove: 'Docker Remove',
  docker_restart: 'Docker Restart',
  docker_start: 'Docker Start',
  gxweb_image_pull: 'GXWeb Image Pull',
  gxweb_remove: 'GXWeb Remove',
  gxweb_restart: 'GXWeb Restart',
  gxweb_start: 'GXWeb Start',
  haproxy_configs_create: 'HAProxy Configs Create',
  haproxy_configs_remove: 'HAProxy Configs Remove',
  nagios_gxweb_create: 'Nagios GXWeb Create',
  nagios_gxweb_remove: 'Nagios GXWeb Remove',
  nagios_postgres_create: 'Nagios Postgres Create',
  nagios_postgres_remove: 'Nagios Postgres Remove',
  nagios_wsclient_create: 'Nagios WSClient Create',
  nagios_wsclient_remove: 'Nagios WSClient Remove',
  portal_remove: 'Portal Remove',
  portal_start: 'Portal Start',
  win_user_disable: 'Win User Disable',
  win_user_remove: 'Win User Remove',
  win_user_reset_pw: 'Win User Reset Password',
  win_user_upsert: 'Win User Upsert',
  wsclient_remove: 'WSClient Remove',
  wsclient_restart: 'WSClient Restart',
  wsclient_start: 'WSClient Start',
});

const JobAction = ({ action }: { action: string }) => {
  const str = jobActionMap[action];
  return <span>{str || action}</span>;
};

const Job = ({ job }: { job: JobType }) => (
  <div className={styles.AnsibleJob}>
    <div className={styles.JobLine}>
      <span>{job.user || 'No User'}</span>
      <JobAction action={job.action} />
    </div>
    <div className={styles.JobLine}>
      <span title="Status">{job.status}</span>
      <TimeAgo date={new Date(job.created)} />
    </div>
  </div>
);

const fetchJobs = async (
  id: string,
  setJobs: React.Dispatch<React.SetStateAction<JobType[]>>
) => {
  const resp = await get(`/ansible_job/${id}`);
  if (resp.ok) {
    setJobs(await resp.json());
  }
};

const AnsibleJobs = ({ id }: { id: string }) => {
  const [jobs, setJobs] = useState([] as JobType[]);

  useEffect(() => {
    fetchJobs(id, setJobs);
  }, [id]);

  return (
    <div className={styles.AnsibleJobs}>
      <h1>Ansible Jobs</h1>
      {jobs.map((job) => (
        <Job key={job.id} job={job} />
      ))}
    </div>
  );
};

export default AnsibleJobs;
