import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';
import { ActiveType } from '../InstanceList/InstanceListItem';

export enum INSTANCE_ACTIONS {
  DATABASES,
  EDITING,
  GXWEB_VERSIONS,
  HOSTING_LOCATIONS,
  HOSTING_SERVERS,
  INSTANCE_ACTIVE_TYPE,
  INSTANCE_DATABASE,
  INSTANCE_FREEZE_VERSION,
  INSTANCE_GXWEB_ENV,
  INSTANCE_GXWEB_ROLLBACK_VERSION,
  INSTANCE_GXWEB_VERSION,
  INSTANCE_HOSTING_LOCATION,
  INSTANCE_IDENTIFIER,
  INSTANCE_LTS_VERSION_ONLY,
  INSTANCE_SUBDOMAIN,
  INSTANCE_WSCLIENT_ENV,
  INSTANCE_WSCLIENT_HOST,
  INSTANCE_WSCLIENT_WFAPI_URL,
  INSTANCE,
}

export type InstanceActions =
  | { type: INSTANCE_ACTIONS.DATABASES; value: Many2OneType[] }
  | { type: INSTANCE_ACTIONS.EDITING; value: boolean }
  | { type: INSTANCE_ACTIONS.GXWEB_VERSIONS; value: Many2OneType[] }
  | { type: INSTANCE_ACTIONS.HOSTING_SERVERS; value: Many2OneType[] }
  | { type: INSTANCE_ACTIONS.HOSTING_LOCATIONS; value: Many2OneType[] }
  | { type: INSTANCE_ACTIONS.INSTANCE_ACTIVE_TYPE; value: ActiveType }
  | { type: INSTANCE_ACTIONS.INSTANCE_DATABASE; value?: Many2OneType }
  | { type: INSTANCE_ACTIONS.INSTANCE_FREEZE_VERSION; value: boolean }
  | { type: INSTANCE_ACTIONS.INSTANCE_GXWEB_ENV; value: string }
  | {
      type: INSTANCE_ACTIONS.INSTANCE_GXWEB_ROLLBACK_VERSION;
      value?: Many2OneType;
    }
  | { type: INSTANCE_ACTIONS.INSTANCE_GXWEB_VERSION; value?: Many2OneType }
  | {
      type: INSTANCE_ACTIONS.INSTANCE_HOSTING_LOCATION;
      value?: Many2OneType;
    }
  | { type: INSTANCE_ACTIONS.INSTANCE_IDENTIFIER; value: string }
  | { type: INSTANCE_ACTIONS.INSTANCE_LTS_VERSION_ONLY; value: boolean }
  | { type: INSTANCE_ACTIONS.INSTANCE_SUBDOMAIN; value: string }
  | { type: INSTANCE_ACTIONS.INSTANCE_WSCLIENT_ENV; value: string }
  | { type: INSTANCE_ACTIONS.INSTANCE_WSCLIENT_HOST; value?: Many2OneType }
  | { type: INSTANCE_ACTIONS.INSTANCE_WSCLIENT_WFAPI_URL; value: string }
  | { type: INSTANCE_ACTIONS.INSTANCE; value: InstanceData };

export type InstanceData = {
  name: string;
  active_type: string;
  gxweb_link: string;
  portal_link: string;
  identifier: string;
  database: Many2OneType;
  subdomain: string;
  hosting_location: Many2OneType;
  lts_version_only: boolean;
  freeze_version: boolean;
  gxweb_version: Many2OneType;
  gxweb_rollback_version: Many2OneType;
  gxweb_port: number;
  portal_port: number;
  gxweb_env: string;
  wsclient_env: string;
  wsclient_host: Many2OneType;
  wsclient_wfapi_url: string;
};

type InitialState = {
  editing: boolean;
  instance?: InstanceData;
  databases?: Many2OneType[];
  hostingLocations?: Many2OneType[];
  gxwebVersions?: Many2OneType[];
  hostingServers?: Many2OneType[];
};

export const instanceInitialState = {
  editing: false,
  instance: undefined,
  databases: undefined,
  hostingLocations: undefined,
  gxwebVersions: undefined,
  hostingServers: undefined,
};

const reducer = (state: InitialState, action: InstanceActions) => {
  switch (action.type) {
    case INSTANCE_ACTIONS.DATABASES:
      return { ...state, databases: action.value };
    case INSTANCE_ACTIONS.EDITING:
      return { ...state, editing: action.value };
    case INSTANCE_ACTIONS.GXWEB_VERSIONS:
      return { ...state, gxwebVersions: action.value };
    case INSTANCE_ACTIONS.HOSTING_LOCATIONS:
      return { ...state, hostingLocations: action.value };
    case INSTANCE_ACTIONS.HOSTING_SERVERS:
      return { ...state, hostingServers: action.value };
    case INSTANCE_ACTIONS.INSTANCE_ACTIVE_TYPE:
      return {
        ...state,
        instance: {
          ...state.instance,
          active_type: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_DATABASE:
      return {
        ...state,
        instance: {
          ...state.instance,
          database: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_FREEZE_VERSION:
      return {
        ...state,
        instance: {
          ...state.instance,
          freeze_version: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_GXWEB_ENV:
      return {
        ...state,
        instance: {
          ...state.instance,
          gxweb_env: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_GXWEB_ROLLBACK_VERSION:
      return {
        ...state,
        instance: {
          ...state.instance,
          gxweb_rollback_version: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_GXWEB_VERSION:
      return {
        ...state,
        instance: {
          ...state.instance,
          gxweb_version: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_HOSTING_LOCATION:
      return {
        ...state,
        instance: {
          ...state.instance,
          hosting_location: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_IDENTIFIER:
      return {
        ...state,
        instance: {
          ...state.instance,
          identifier: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_LTS_VERSION_ONLY:
      return {
        ...state,
        instance: {
          ...state.instance,
          lts_version_only: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_SUBDOMAIN:
      return {
        ...state,
        instance: {
          ...state.instance,
          subdomain: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_WSCLIENT_ENV:
      return {
        ...state,
        instance: {
          ...state.instance,
          wsclient_env: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_WSCLIENT_HOST:
      return {
        ...state,
        instance: {
          ...state.instance,
          wsclient_host: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE_WSCLIENT_WFAPI_URL:
      return {
        ...state,
        instance: {
          ...state.instance,
          wsclient_wfapi_url: action.value,
        } as InstanceData,
      };
    case INSTANCE_ACTIONS.INSTANCE:
      return { ...state, instance: action.value };
    default:
      return state;
  }
};

export default reducer;
