import React from 'react';
import { get } from '../../utils/api';


export const fetchSpecialities = async (
  url: string,
  setFn: (arg0: any) => void
) => {
  const resp = await get(url);
  if (resp.ok) {
    setFn(await resp.json());
  }
  return resp.ok;
};

export const fetchBusinessDetails = async (
  url: string,
  setFn: (arg0: any) => void,
  loading: (arg0: any) => void
) => {
  const resp = await get(url);
  if (resp.ok) {
    setFn(await resp.json());
    loading(true);
  }
  return resp.ok;
};

export const fetchAPIWithLoad = async (
  url: string,
  setFn: (arg0: any) => void,
  loading: (arg0: any) => void
) => {
  const resp = await get(url);
  if (resp.ok) {
    setFn(await resp.json());
    loading(true);
  }
  return resp.ok;
};

export const InvalidUUID = (
  <React.Fragment>
    <h3><b>Invalid UUID ID entered.</b></h3>
    <span>Please reclick the link on the email received.</span>
    <span>If the issue still persists, please contact support on <b>012 804 6831</b>.</span>
  </React.Fragment>
);
