import { Text } from '@react-pdf/renderer';
import React from 'react';
import JsxParser from 'react-jsx-parser';


interface TermsAndConditionsTypes {
  terms?: string;
};

const TermsAndConditions: React.FC<TermsAndConditionsTypes> = (props:any) => {
  
  return (
    <div style={{ top: '-20px' }}>
      <Text>
        <JsxParser jsx={props.terms} />
      </Text>
    </div>
  );
};

export default TermsAndConditions;
