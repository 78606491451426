import React, { useEffect, useState } from 'react';

import styles from './Inputs.module.scss';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';

type TextInputPropTypes = {
  name: string;
  value?: string;
  editing: boolean;
  onChange: (value: string) => void;
};

const TextInput = ({ name, value, editing, onChange }: TextInputPropTypes) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!editing) {
      setTouched(false);
    }
  }, [editing]);

  const changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    onChange(e.target.value);
  };

  return (
    <>
    <DesktopLayout>
     <div className={styles.FieldLabel}>
        <span>{name}</span>
      </div>
      {editing ? (
        <input
          type="text"
          value={value}
          onChange={changed}
          className={styles.FieldInput}
        />
      ) : (
        <span>{value}</span>
      )}
      </DesktopLayout>
      <MobileLayout>
      <div className={responsive.FieldLabel}>
        <span>{name}</span>
      </div>
      {editing ? (
        <input
          type="text"
          value={value}
          onChange={changed}
          className={responsive.input}
        />
      ) : (
        <span>{value}</span>
      )}
      </MobileLayout>
    </>
  );
};

export default TextInput;
