import { History } from 'history';
import React from 'react';

import LogsLinkButton from './LogsLinkButton';

const InstanceLogsButtons = ({
  history,
  identifier,
}: {
  history: History<any>;
  identifier?: string;
}) => {
  const disabled = identifier === undefined;

  return (
    <div>
      <LogsLinkButton
        {...{ disabled, history }}
        buttonName="GXWeb Logs"
        containerName={`${identifier}_gxweb`}
      />
      <LogsLinkButton
        {...{ disabled, history }}
        buttonName="WSClient Logs"
        containerName={`${identifier}_wsclient`}
      />
      <LogsLinkButton
        {...{ disabled, history }}
        buttonName="GXWeb and WSClient Logs"
        containerName={`${identifier}_gxweb,${identifier}_wsclient`}
      />
    </div>
  );
};

export default InstanceLogsButtons;
