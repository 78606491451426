import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import EditSaveDiscardButtonsQuotation from '../../components/EditSaveDiscardButtons/EditSaveDiscardButtonsQuotation';
import Messages from '../../components/Messages/Messages';
import UserContext, { User } from '../../User.context';
import {
  fetchCategories,
  fetchEmployees,
  fetchQuotePriceLists,
  fetchSpecialities,
} from '../../utils/many2OneAPI';
import styles from '../../neumorpism.module.scss';
import style from './Quotation.module.scss';
import {
  entitySetupSendEmail,
  fetchPriceListPrices,
  fetchQuote,
  fetchQuoteApi,
  quoteDuplicateCheck,
  quoteDuplicateCreate,
  quoteDuplicateLog,
  getTempBusinessUrl,
  saveNewQuote,
  saveQuote,
} from './quotationAPI';
import QuotationLines from './QuotationLines';
import reducer, {
  QUOTE_ACTIONS,
  QuoteInitialCreateState,
  QuoteInitialState,
} from './quotationReducer';
import QuotationValues from './QuotationValues';

import { toast, ToastContainer } from 'react-toastify';

const Quotation: React.FC = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const user: User = useContext(UserContext);
  const create = id === 'create';
  const [commissions, setCommissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quoteCheck, setQuoteCheck] = useState('');
  const [checkError, setCheckError] = useState(false);
  const [quoteState, setQuoteState] = useState(false);
  const [quoteAccepted, setQuoteAccepted] = useState(false);

  const [
    { categories, editing, employees, quote, specialities, priceLists, shouldReplaceLines },
    dispatch,
  ] = useReducer(reducer, QuoteInitialState);

  const checkQuoteState = () => {
    if (quote?.action_state === "accepted") {
      setQuoteState(false);
      setQuoteAccepted(true);
    }
    else if (quote?.action_state === "cancelled") {
      setQuoteState(false);
      setQuoteAccepted(false);
    }
    else if (create || editing) {
      setQuoteState(true);
      setQuoteAccepted(false);
    }
    else if (quote?.action_state === "quote") {
      setQuoteState(false);
      setQuoteAccepted(false);
    }
  };

  const quoteValidate = async() => {
    setCheckError(false);
    if (quote?.email === undefined) {
      setCheckError(true);
      toast.error("Email is not completed");
    }
    if (quote?.price_list === undefined) {
      setCheckError(true);
      toast.error("Price list is not selected")
    }
    if (quote?.name === "") {
      setCheckError(true);
      toast.error("Name is not completed");
    }
    if (!quote?.sale_employee.name) {
      setCheckError(true);
      toast.error("Sales employee is not selected");
    }
    if (quote?.practice_number === "") {
      setCheckError(true);
      toast.error("Practice number is not completd");
    }
  
   let quoteCheck = await quoteDuplicateCheck(quote?.practice_number);

   if (quoteCheck) {
     setQuoteCheck(quoteCheck);
     openModalQuoteCheck();
   }

   if (!quoteCheck) {
    await saveNewQuote(quote, history);
   }
  };

  const modalSaveQuote = async() => {
    if (!checkError) {
      await saveNewQuote(quote, history);
      closeModalQuoteCheck();
    }
  };

  const openModalQuoteCheck = () => {
    var elem = document.getElementById("myModal") as HTMLInputElement;
    elem.style.display = "block";
  }

  function closeModalQuoteCheck() {
    var elem = document.getElementById("myModal") as HTMLInputElement;
    elem.style.display = "none";
  };

  const openModalQuoteDuplicate = () => {
    var elem = document.getElementById("duplicateCreateDialog") as HTMLInputElement;
    elem.style.display = "block";
  };

  const openBusinessDetails = async() => {
    let quoteId = await getTempBusinessUrl(quote?.id.toString());
    history.push({ pathname: `${quoteId}` });
  };

  function closeModalDuplicate() {
    var elem = document.getElementById("duplicateCreateDialog") as HTMLInputElement;
    elem.style.display = "none";
  };

  const createDuplicate = async() => {
    let quoteId = await quoteDuplicateCreate(quote?.id.toString());
    await quoteDuplicateLog(quote?.id, user?.user_id, quoteId);

    history.push({ pathname: `/quotation/${quoteId}` });
    closeModalDuplicate();
  };
 
  useEffect(() => {
    if (id !== undefined && create) {
      dispatch({
        type: QUOTE_ACTIONS.STATE,
        value: QuoteInitialCreateState(user?.employee_id, user?.name),
      });
    }
  }, [id, create, user]);

  useEffect(() => {
    if (id !== undefined && !create) {
      fetchQuote(dispatch, id);
    }
  }, [id, create, editing]);

  useEffect(() => {
    if (editing) {
      fetchEmployees((value) =>
        dispatch({ type: QUOTE_ACTIONS.EMPLOYEES, value })
      );
      fetchSpecialities((value) =>
        dispatch({ type: QUOTE_ACTIONS.SPECIALITIES, value })
      );
      fetchCategories((value) =>
      dispatch({ type: QUOTE_ACTIONS.CATEGORIES, value })
      );
    }
  }, [editing]);

  const speciality_id = quote?.speciality?.id;
  useEffect(() => {
    if (editing && speciality_id !== undefined) {
      fetchQuotePriceLists(speciality_id, (value) =>
        dispatch({ type: QUOTE_ACTIONS.PRICE_LISTS, value })
      );
    }
  }, [editing, speciality_id]);

  const price_list_id = quote?.price_list?.id;
  useEffect(() => {
    if (shouldReplaceLines && price_list_id !== undefined) {
      fetchPriceListPrices(dispatch, price_list_id);
    }
  }, [shouldReplaceLines, price_list_id]);

  useEffect(() => {
    if (id !== undefined) {
    fetchQuoteApi(`/quotation/${id}/item_type_commission_totals`, setCommissions, setLoading);
    }
  }, [id, setCommissions]);

  useEffect(() => {
    if (id !== undefined) {
      checkQuoteState();
    }
  });

  return (
    <div className="Page">
      {id !== undefined && !create ?
      <div className={styles.FormWrapper}>
      <div className={styles.FieldGroupSingleRow}>
      <div className={styles.PullLeft}>
         <EditSaveDiscardButtonsQuotation
            editing={editing}
            create={create}
            accepted={quoteAccepted}
            createLink="/quotation/create"
            saveFn={() => saveQuote(quote, id)}
            createFn={() => quoteValidate()}
            setEditing={(value) => dispatch({ type: QUOTE_ACTIONS.EDITING, value })}
          />
          </div>
        <button className={styles.ActionBtn}
          onClick={() => window.open(`/quotation/download/${quote?.id}`)}>
          Preview Quote
        </button>
        <button className={styles.ActionBtn}
          onClick={() => entitySetupSendEmail(quote)}>
          Email Quote
        </button>
        <button className={styles.ActionBtn}
          onClick={() => openModalQuoteDuplicate()}>
          Duplicate Quote
        </button>
        <button className={styles.ActionBtn}
          onClick={() => openBusinessDetails()}>
          Complete client's details
        </button>
      </div>
      </div>
      : <EditSaveDiscardButtonsQuotation
          editing={editing}
          create={create}
          accepted={quoteAccepted}
          createLink="/quotation/create"
          saveFn={() => saveQuote(quote, id)}
          createFn={() => quoteValidate()}
          setEditing={(value) => dispatch({ type: QUOTE_ACTIONS.EDITING, value })}
        />
      }
      
      <div id="myModal" className={style.modal}>
        <div className={style.modalContent}>
          <span className={"material-icons"} style={{ color: '#FF8C00' }}>info</span>
          <h2 className={styles.PopupHeader}>Duplicate Quote Check</h2>
          <span>{quoteCheck}</span>
          <br />
          <br />
          <button className={styles.ActionBtn} disabled={checkError} onClick={() => modalSaveQuote()}>Continue</button>
          <button className={styles.ActionBtn} onClick={() => closeModalQuoteCheck()}>Discard</button>
        </div>
      </div>

      <div id="duplicateCreateDialog" className={style.modal}>
        <div className={style.modalContent}>
          <span className={"material-icons"} style={{ color: '#FF8C00' }}>info</span>
          <h2 className={style.PopupHeader}>Duplicate Quote Create</h2>
          <span>Are you sure you want to duplicate quotation #{id}?</span>
          <br />
          <br />
          <button className={styles.ActionBtn} disabled={checkError} onClick={() => createDuplicate()}>Yes</button>
          <button className={styles.ActionBtn} onClick={() => closeModalDuplicate()}>No</button>
        </div>
      </div>  
      <h1>{quote?.name}</h1>

      <QuotationValues
        {...{ dispatch, categories, editing, employees, quote, specialities, quoteState }}
      />
      <ToastContainer />
      <QuotationLines {...{ quote, editing, dispatch, priceLists, commissions, quoteState, quoteAccepted }} />

      <div className={style.Messages}>
        <Messages model="goodx.quote_order" id={id} />
      </div>
    </div>
  );
};

export default Quotation;
