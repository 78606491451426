import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';

import { get } from '../../utils/api';
import { QuoteActionStateType } from '../Quotation/quotationReducer';
import MUIDataTable from "mui-datatables";
import UserContext, { User } from '../../User.context';

type QuotationListItem = {
  id: number;
  accepted_date?: Date;
  action_state: QuoteActionStateType;
  bill_from_date: Date;
  install_date?: Date;
  name: string;
  practice_number: string;
  price_list_name: string;
  sale_employee_name: string;
  speciality_name: string;
};

const QuotationList: React.FC = () => {
  const history = useHistory();
  const [quoteList, setQuoteList] = useState([] as QuotationListItem[]);
  const user: User = useContext(UserContext);
  const [filterOptions, setFilterOptions] = useState<string[]>([]);

  const columns = [
    {
      name: "Quote ID",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => { return { style: { fontWeight: "bold" } } },
        setCellProps: () => { return { align: "left" } },
      },
    },
    {
      name: "Order Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => { return { style: { fontWeight: "bold" } } },
        setCellProps: () => { return { align: "left" } },
      },
    },
    {
      name: "Practice Number",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => { return { style: { fontWeight: "bold" } }},
        setCellProps: () => { return { align: "left" } },
      },
    },
    {
      name: "State",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => { return { style: { fontWeight: "bold" } } },
        setCellProps: (rows:any) => { 
          return { 
            style: 
            rows === "quote" ? { backgroundColor: "purple", color: "white" } : null ||
            rows === "accepted" ? { backgroundColor: "green", color: "white" } : null ||
            rows === "cancelled" ? { backgroundColor: "red", color: "white" } : null
        } 
      },
      },
    },
    {
      name: "Speciality",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => { return { style: { fontWeight: "bold" } }},
        setCellProps: () => { return { align: "left" } },
      },
    },
    {
      name: "Price List",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => { return { style: { fontWeight: "bold" } } },
        setCellProps: () => { return { align: "left" } },
      },
    },
    {
      name: "Sale Person",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => { return { style: { fontWeight: "bold" } } },
        setCellProps: () => { return { align: "left" } },
        filterList: filterOptions,
      },
    },
  ]

  const options = {
    onRowClick: (rowData:any) => history.push({ pathname: `/quotation/${rowData[0]}` }),
    jumpToPage: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsPerPage: 50,
    searchAlwaysOpen: true,
    print: false,
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: "800px",
    tableBodyWidth: "100%",
    justifyContent: "center",
    onFilterChange: (changedColumn: any, filterList: any) => {
      localStorage.setItem('filterList', JSON.stringify(filterList[6]));
    },  
  };

  const setFilterListOptions = useCallback(() => {
    const filterListValue = user?.name ? [user?.name] : [];
    if (filterListValue) {
      setFilterOptions(filterListValue);
      localStorage.setItem('filterList', JSON.stringify(filterListValue));
    }
  }, [user]);

  useEffect(() => {
    const fetchQuotationList = async () => {
      const resp = await get('/quotation/lists');
      if (resp.ok) {
        setQuoteList(await resp.json());
        if (user?.name) {
          setFilterListOptions();
        }
      }
    };

    fetchQuotationList();
  }, [setFilterListOptions, user]);

  useEffect(() => {
    const storedFilterList = localStorage.getItem('filterList');
    if (storedFilterList !== null) {
      setFilterOptions(JSON.parse(storedFilterList));
    } else {
      setFilterOptions([]);
    }
  }, []);

  return (
    <div>
      <DesktopLayout>
        <h1 style={{ marginLeft: '10px'}}>Quotation List</h1>
        <Link style={{ marginLeft: '10px'}} to="/quotation/create">Create</Link>
        <br />
        <br />
        <MUIDataTable
          title={"Quotation List"}
          data={quoteList}
          columns={columns}
          options={options as any}
        />
      </DesktopLayout>
      <MobileLayout>
      <h1 style={{ marginLeft: '10px'}}>Quotation List</h1>
        <Link style={{ marginLeft: '10px'}} to="/quotation/create">Create</Link>
        <br />
        <br />
        <MUIDataTable
          title={"Quotation List"}
          data={quoteList}
          columns={columns}
          options={options as any}
        />
      </MobileLayout>
    </div>
  );
};

export default QuotationList;
