import {
    Document,
    StyleSheet,
    Text,
    Page,
    View,
    Image
} from '@react-pdf/renderer';

import React from 'react';
import { BusinessDetailsType } from './BusinessDetailsReducer';
import { QuoteConfigPropTypes, QuoteConfigBankingDetails, GetHeaderImage } from '../Quotation/QuoteTemplate';
import DateTime from '../../utils/DateTime';
import BusinessDetailsAuthSignedPDF from "./BusinessDetailsAuthSignedPDF";

interface BusinessDetailsDebitOrderTypes {
    props?: BusinessDetailsType;
    quoteConfig?: QuoteConfigPropTypes;
    bankingDetails?: QuoteConfigBankingDetails[];
    headerImage?: GetHeaderImage;
};

const BusinessDetailsDebitOrderPDF: React.FC<BusinessDetailsDebitOrderTypes> = (business) => {
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
        },
        body: {
            paddingTop: 20,
            paddingBottom: 20,
            paddingHorizontal: 20,
        },
        textAlignCenter: {
            textAlign: 'center',
            fontSize: '18px',
        },
        table_header: {
            display: 'flex',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRightWidth: 1,
            borderBottomWidth: 1,
        },
        tableRow_header: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        tableCell_header: {
            width: '100%',
            textAlign: 'left',
            padding: '2mm',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#dddddd',
        },
        heading: {
            fontSize: 14,
            fontWeight: 'bold',
            left: '240px',
        },
        termsHeading: {
            fontSize: 14,
            textAlign: 'left',
            fontWeight: 'bold',
            marginLeft: 10,
        },
        terms: {
            fontSize: 10,
            textAlign: 'left',
            marginLeft: 10,
        },
        termsContact: {
            fontSize: 10,
            textDecoration: 'underline',
            fontWeight: 'bold',
            marginLeft: 10,
        },
        logo: {
            width: 100,
            position: 'absolute',
            right: '4%',
            transform: '-50%, -50%',
        },
        h2: {
            fontFamily: 'Roboto Condensed',
            fontSize: '18pt',
            textTransform: 'uppercase',
            color: '#005e91',
            fontWeight: 700,
        },
        h3: {
            fontFamily: 'Roboto Condensed',
            fontSize: '14pt',
            textTransform: 'uppercase',
            color: '#47a3d3',
            fontWeight: 700,
        },
        spaceNone: {
            marginTop: 0,
            marginBottom: 0,
        },
        colWidth20: {
            width: '20%',
        },
        colWidth40: {
            width: '40%',
        },
        colWidth50: {
            width: '50%',
        },
        colWidth60: {
            width: '60%',
        },
        colWidth25: {
            width: '25%',
        },
        colHeightImg50: {
            height: '40px',
        },
        textAlignRight: {
            textAlign: 'right',
        },
        bankDetailsHeading: {
            fontSize: 14,
            textAlign: 'left',
            fontWeight: 'bold',
        },
        bankDetailsFooter: {
            fontSize: 12,
            fontWeight: 'bold',
        },
        acceptedDate: {
            fontWeight: 'bold',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '5mm',
        },
        headerimg: {
            marginLeft: '-5mm',
            marginTop: '-5mm',
            marginRight: '-5mm',
            width: '200mm',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingBottom: '5mm',
            paddingTop: '5mm',
        },
        columnR: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flexGrow: 1,
        },
        columnL: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexGrow: 2,
        },
        icon: {
            fontFamily: 'Material Icons',
        },
        center: {
            textAlign: 'center',
        },
        centered: {
            margin: 'auto',
        },
        title: {
            fontFamily: 'Roboto Condensed',
            fontSize: '30pt',
            textTransform: 'uppercase',
            fontWeight: 700,
        },
        subtitle: {
            fontFamily: 'Roboto Condensed',
            fontSize: '20pt',
            textTransform: 'uppercase',
            fontWeight: 300,
        },
        h1: {
            fontFamily: 'Roboto Condensed',
            fontSize: '24pt',
            textTransform: 'uppercase',
            color: '#0081c6',
            fontWeight: 700,
        },
        h4: {
            fontFamily: 'Roboto Condensed',
            fontSize: '12pt',
            textTransform: 'uppercase',
            color: '#505050',
            fontWeight: 700,
        },
        space5: {
            marginBottom: '5mm',
        },
        space2: {
            marginBottom: '2.5mm',
        },
        italic: {
            fontStyle: 'italic',
        },
        bold: {
            fontWeight: 'bold',
        },
        boldItalic: {
            fontStyle: 'italic',
            fontWeight: 'bold',
        },

        footer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            position: 'absolute',
            bottom: '5mm',
            marginTop: '5mm',
            marginLeft: '10mm',
            marginRight: '10mm',
            fontSize: '9pt',
            color: '#bbbbbb',
            fontStyle: 'italic',
            textAlign: 'center',
        },
        tableHeader: {
            fontWeight: 'bold',
        },
        tableHeaderBlue: {
            fontWeight: 'bold',
            color: '#ffffff',
            backgroundColor: '#0081c6',
        },
        textRight: {
            textAlign: 'right',
        },
        colWidth10: {
            width: '10%',
        },
        colWidth15: {
            width: '15%',
        },
        colWidth55: {
            width: '55%',
        },
        colWidth85: {
            width: '85%',
        },
        bgGrey: {
            backgroundColor: '#eeeeee',
        },
        table: {
            display: 'flex',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRightWidth: 1,
            borderBottomWidth: 1,
        },
        tableRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        tableCell: {
            width: '100%',
            textAlign: 'left',
            padding: '2mm',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#dddddd',
        },
        text_view: {
            fontSize: '12px',
        },
    })
    return (
        <Document>
            {business.quoteConfig?.debit_order === true ?
            <Page size="A4" style={styles.body}>
                <View style={styles.header} fixed>
                    <View style={styles.column}>
                        <Image style={styles.headerimg} src={`${business?.headerImage?.header}`}></Image>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.column}>
                        <View style={styles.center}>
                            <Text style={styles.title}>BANK DEBIT ORDER INSTRUCTION</Text>

                            <Text>{' '}</Text>
                            <Text>between:</Text>
                            <Text>{' '}</Text>

                            <Text style={styles.h1}>{business?.quoteConfig?.company_name}</Text>
                            <Text>Registration number: {business?.quoteConfig?.company_reg_no}</Text>
                            <Text style={styles.italic}>(hereinafter referred to as "GoodX")</Text>

                            <Text>{' '}</Text>
                            <Text>and</Text>
                            <Text>{' '}</Text>

                            <Text style={styles.h1}>{business?.props?.business_name}</Text>
                            <Text>Practice number: {business.props?.practice_nr}</Text>
                            <Text style={styles.italic}>(hereinafter referred to as "the Client")</Text>
                            <Text>{' '}</Text>
                            <Text style={styles.italic}>(hereinafter referred to as "the Parties")</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.text_view}>
                    <Text break></Text>
                    <Text style={[styles.h3, styles.spaceNone]}>BANK DEBIT ORDER INSTRUCTION:</Text>
                    <Text>{'\n'}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth50]}>Name of Business / Practice:
                                <p style={styles.bold}> {business.props?.business_name}</p>
                            </Text>
                            <Text style={[styles.tableCell, styles.colWidth40]}>Date:
                                <p style={styles.bold}> {DateTime.curTime}</p>
                            </Text>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableCell}>Contract No:
                                    <p style={styles.bold}>  {business.props?.id}</p>
                                </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth50]}>Information Officer Name:
                                <p style={styles.bold}> {business.props?.credit_control_name}</p>
                            </Text>
                            <Text style={[styles.tableCell, styles.colWidth50]}>Debit Amount :
                                <p style={styles.bold}> Monthly licence and switching fees </p>
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth50]}>Information Officer Cell number:
                                <p style={styles.bold}> {business.props?.io_cell_nr}</p>
                            </Text>
                            <Text style={[styles.tableCell, styles.colWidth50]}>Commencement Date :
                                {/* <p style={styles.bold}> {business.props?.create_date}</p> */}
                                <p style={styles.bold}> in terms of clause 7.2 in terms of license agreement</p>
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth50]}>Information Officer E-mail address:
                                <p style={styles.bold}> {business.props?.credit_control_email}</p>
                            </Text>
                            <Text style={[styles.tableCell, styles.colWidth50]}>Abbreviated name as registered with the bank:
                                <p style={styles.bold}> {business.quoteConfig?.abbreviated_name}</p>
                            </Text>
                        </View>
                    </View>
                    <Text>{'\n'}</Text>
                    <Text style={[styles.h3, styles.spaceNone]}>The details of my/our account are as follows:</Text>
                    <Text>{'\n'}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth60]}>Bank:
                                <p style={styles.bold}> {business.props?.debit_order_bank}</p>
                            </Text>
                            <Text style={[styles.tableCell, styles.colWidth40]}>Branch:
                                <p style={styles.bold}> {business.props?.debit_order_branch}</p>
                            </Text>
                        </View>

                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth60]}>Branch Code:
                                <p style={styles.bold}> {business.props?.debit_order_branch_code}</p>
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth60]}>Account Name:
                                <p style={styles.bold}> {business.props?.debit_order_account_name}</p>
                            </Text>
                            <Text style={[styles.tableCell, styles.colWidth40]}>Account Number:
                                <p style={styles.bold}> {business.props?.debit_order_account_no}</p>
                            </Text>
                        </View>

                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth60]}>Branch Code:
                                <p style={styles.bold}> {business.props?.debit_order_branch_code}</p>
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth60]}>Account Name:
                                <p style={styles.bold}> {business.props?.debit_order_account_name}</p>
                            </Text>
                            <Text style={[styles.tableCell, styles.colWidth40]}>Account Number:
                                <p style={styles.bold}> {business.props?.debit_order_account_no}</p>
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, styles.colWidth60]}>Account Type:
                                <p style={styles.bold}> {business.props?.debit_order_account_type}</p>
                            </Text>
                            <Text style={[styles.tableCell, styles.colWidth40]}>Date Of Debit Order:
                                <p style={styles.bold}> {business.props?.date_of_debit_order}</p>
                            </Text>
                        </View>
                    </View>
                    <Text>{'\n'}</Text>
                    <BusinessDetailsAuthSignedPDF props={business.props} quoteConfig={business.quoteConfig} bankingDetails={business.bankingDetails} ></BusinessDetailsAuthSignedPDF>
                    <Text break></Text>
                    <Text style={[styles.h2, styles.spaceNone]}>BANK DEBIT ORDER AGREEMENT:</Text>
                    <Text>{'\n'}</Text>
                    <View>
                        <Text style={styles.body}><p>{business.quoteConfig?.debit_order_instruction_message}</p></Text>
                    </View>
                </View>
            </Page>
            :
            
            <Page size="A4" style={styles.body}>
                <View style={styles.header} fixed>
                    <View style={styles.column}>
                        <Image style={styles.headerimg} src={`${business?.headerImage?.header}`}></Image>
                    </View>
                </View>
                <Text style={[styles.h2, styles.spaceNone]}>NOT APPLICABLE TO COMPANY</Text>
            </Page>}
        </Document>


    )
};

export default BusinessDetailsDebitOrderPDF;
