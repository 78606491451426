import React from 'react';

import styles from './Buttons.module.scss';
import call, { IDType } from './call';

const ACMECertificateButtons = ({ instanceID }: IDType) => {
  const acmeCopy = async () => await call('acme_copy', instanceID);
  const acmeIssue = async () => await call('acme_certificate', instanceID);

  return (
    <div className={styles.ButtonRow}>
      <button onClick={acmeIssue}>Issue ACME Certificate</button>
      <button onClick={acmeCopy}>Copy ACME Certificate</button>
    </div>
  );
};

export default ACMECertificateButtons;
