import React from 'react';
import { Link } from 'react-router-dom';
import style from '../EditableInputs/Inputs.module.scss'

type EditSaveDiscardButtonsPropTypes = {
  editing: boolean;
  saveFn: () => void;
  setEditing: (value: boolean) => void;
  create?: boolean;
  createLink?: string;
  createFn?: () => void;
};

const EditSaveDiscardButtons = ({
  editing,
  saveFn,
  setEditing,
  create,
  createLink,
  createFn,
}: EditSaveDiscardButtonsPropTypes) =>
  editing || create ? (
    <>
      <button onClick={create ? createFn : saveFn}>Save</button>
      <button onClick={() => setEditing(false)}>Discard</button>
    </>
  ) : (
    <>
      <div className={style.ActionBtn}>
        <button onClick={() => setEditing(true)}>Edit</button>
      </div>
      {createLink ? <Link to={createLink}>Create</Link> : null}
    </>
  );

export default EditSaveDiscardButtons;
