import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image
} from '@react-pdf/renderer';
import React from 'react';
import DateTime from '../../utils/DateTime';

interface ContractTemplateTypes {
agreementName: string;
agreementSurname: string;
agreementID: string;
agreementRole: string;
agreementCell: string;
agreementPlace: string;
agreementContact: string;
agreementAddress: string;
headerImage: string;
};

const styles = StyleSheet.create({
page: {
  flexDirection: 'row',
},
body: {
  paddingTop: 20,
  paddingBottom: 20,
  paddingHorizontal: 20,
},
table: {
  display: 'flex',
  width: 'auto',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRightWidth: 1,
  borderBottomWidth: 1,
},
signedCellLines: {
  marginLeft: 20,
  fontSize: 12,
},
header: {
  marginLeft: 20,
  textDecoration: "underline",
},
pageNumbers: {
  position: 'absolute',
  bottom: 20,
  left: 0,
  right: 0,
  textAlign: 'center',
  fontSize: 12,
},
pageDate: {
  position: 'absolute',
  bottom: 20,
  left: 0,
  right: 50,
  textAlign: 'right',
  fontSize: 12,
},
heading: {
  fontSize: 10,
  textAlign: 'left',
  marginTop: 10,
  marginLeft: 10,
},
headingSection: {
  fontWeight: 'bold',
  fontSize: 14,
  marginTop: 10,
  marginLeft: 3,
  textDecoration: 'underline',
},
QlinesSummary: {
  top: 360,
  right: 530,
  fontSize: 12,
  textAlign: 'center',
  fontWeight: 'bold',
},
QLinesDetail: {
  right: 400,
  top: 180,
  fontSize: 12,
  textAlign: 'left',
  flex: 1,
},
QLinesDetail1: {
  right: 400,
  top: 180,
  fontSize: 12,
  textAlign: 'left',
},
viewer: {
  width: '100%',
  height: '100%',
},
logo: {
  width: 100,
  position: 'absolute',
  right: '4%',
},
tableRow: {
  margin: 'left',
  flexDirection: 'row',
},
tableCol1: {
  width: '100%',
  borderStyle: 'solid',
  borderWidth: 1,
  borderLeftWidth: 0,
  borderBottomWidth: 1,
  top: -60,
},
tableCol2: {
  width: '20%',
  borderStyle: 'solid',
  borderLeftWidth: 1,
  borderTopWidth: 1,
  borderRightWidth: 0.3,
  borderBottomWidth: 0.2,
  top: 60,
  right: 160,
},
QLinesCol2: {
  width: '60%',
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  fontSize: 10,
  marginLeft: 5,
  marginTop: 5,
},
QLinesCol3: {
  width: '16%',
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  fontSize: 10,
  marginTop: 5,
},
QLinesCol4: {
  width: '8%',
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  fontSize: 10,
  marginTop: 5,
},
QLinesCell2: {
  width: '60%',
  margin: 'auto',
  marginTop: 5,
  fontSize: 10,
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  backgroundColor: '#CCCCCC',
  color: '#000000'
},
QLinesCell3: {
  width: '16%',
  margin: 'auto',
  marginTop: 5,
  fontSize: 10,
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  backgroundColor: '#CCCCCC',
  color: '#000000',
},
QLinesCell4: {
  width: '8%',
  margin: 'auto',
  marginTop: 5,
  fontSize: 10,
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  backgroundColor: '#CCCCCC',
  color: '#000000',
},
tableCol2Extra: {
  width: '30%',
  borderStyle: 'solid',
  borderTopWidth: 1,
  borderRightWidth: 0.3,
  borderLeftWidth: 0.3,
  borderBottomWidth: 1,
  top: -40,
  left: 170,
  fontSize: 10,
  textAlign: 'center',
},
tableCol3: {
  width: '33.33%',
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
},
tableCol4: {
  width: '25%',
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
},
tableHead: {
  width: '14%',
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  backgroundColor: '#CCCCCC',
  color: '#000000',
},
tableCell: {
  marginTop: 5,
  fontSize: 10,
  marginLeft: 10,
},
tableCol2Row: {
  marginTop: 5,
  fontSize: 10,
  textAlign: 'right',
},
tableCol2Cell: {
  marginTop: 5,
  fontSize: 10,
  textAlign: 'left',
},
tableCellGroup: {
  marginTop: 5,
  marginLeft: 2,
  fontSize: 14,
  textAlign: 'left',
  textDecoration: 'underline',
},
tableColSplit: {
  width: '100%',
  paddingTop: 5,
  paddingBottom: 5,
  borderLeft: 0,
},
tableCell2: {
  marginLeft: 1,
  marginTop: 5,
  fontSize: 18,
  textAlign: 'left',
  fontWeight: 100,
},
tableCol7: {
  width: '14%',
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
},
tableColOut: {
  width: '100%',
  marginBottom: 5,
  borderStyle: 'solid',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 1,
},
tableColOut5: {
  width: '75%',
  fontSize: 11,
  fontWeight: 'bold',
},
tableColOut2: {
  width: '25%',
  fontSize: 11,
  fontWeight: 'bold',
},
hr: {
  width: '80%',
  height: '2px',
  backgroundColor: '#666',
  border: 0,
  top: 58,
  left: '10%',
},
termsHeading: {
  fontSize: 14,
  textAlign: 'left',
  fontWeight: 'bold',
  marginLeft: 10,
},
terms: {
  fontSize: 10,
  textAlign: 'left',
  marginLeft: 10,
},
termsContact: {
  fontSize: 10,
  textDecoration: 'underline',
  fontWeight: 'bold',
  marginLeft: 10,
},
});

const AcceptTemplate: React.FC<ContractTemplateTypes> = (props) => {
const agreementName = props.agreementName;
const agreementSurname = props.agreementSurname;
const agreementID = props.agreementID;
const agreementRole = props.agreementRole;
const agreementCell = props.agreementCell;
const agreementPlace = props.agreementPlace;
const agreementContact = props.agreementContact;
const headerImage = props.headerImage;
const template = (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.table}>
        <View style={styles.heading}>
          <Text>E-mail: legal@goodx.co.za</Text>
          <Text>Tel: 010 286 0802</Text>
          <Text>Fax: 086 459 0549</Text>
          <Image style={styles.logo} src={`${props.headerImage}`}></Image>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>
          <Text>{'\n'}</Text>
          <Text style={styles.headingSection}>PART B: Information of Acceptance of the agreement</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Name: {agreementName}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Surname: {agreementSurname}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>ID No: {agreementID}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Role: {agreementRole}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Cell No: {agreementCell}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>Place: {agreementPlace}</Text>
        </View>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <View style={styles.tableRow}>
          <Text style={styles.termsHeading}>Acceptance of Terms of Agreement</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.terms}> I, </Text>
          <Text style={styles.termsContact}>{agreementContact}</Text>
          <Text style={styles.terms}> , by clicking on the accept button, confirm that the information supplied is correct, that</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.terms}>I have read and accept the terms of this GoodX Software Standard Licence, Services & Operator</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.terms}>Agreement read together with the General Terms & Conditions, Policies and Standard Operating</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.terms}>Procedures as amended from time to time on the official GoodX website, and that I have the</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.terms}> authority to sign on behalf of the Client.</Text>
        </View>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text>{'\n'}</Text>
        <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
        <Text style={styles.pageDate}>
          {DateTime.curTime}
        </Text>
      </View>
    </Page>
  </Document>
);
return template;
};

export default AcceptTemplate;
