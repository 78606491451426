import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import CheckBoxInput from '../../components/EditableInputs/CheckBoxInput';
import TextInput from '../../components/EditableInputs/TextInput';
import EditSaveDiscardButtons from '../../components/EditSaveDiscardButtons/EditSaveDiscardButtons';
import { fetchGxwebVersion, saveGxwebVersion } from './GxwebVersionAPI';
import reducer, {
  GXWEB_VERSION_ACTIONS,
  GxwebVersionInitialState,
} from './GxwebVersionReducer';

const GxwebVersion: React.FC = () => {
  const { id }: any = useParams();

  const [{ editing, gxwebVersion }, dispatch] = useReducer(
    reducer,
    GxwebVersionInitialState
  );

  useEffect(() => {
    if (id !== undefined) {
      fetchGxwebVersion(dispatch, id);
    }
  }, [id]);

  if (id === undefined) {
    return null;
  }

  return (
    <div className="Page">
      <EditSaveDiscardButtons
        editing={editing}
        saveFn={() => saveGxwebVersion(gxwebVersion, id)}
        setEditing={(value: boolean) =>
          dispatch({ type: GXWEB_VERSION_ACTIONS.EDITING, value })
        }
      />
      <h1>{gxwebVersion?.gxweb_image_identifier}</h1>

      <h2>Container Image Names</h2>
      <div className="ValueGrid">
        <span>GXWeb Container Image</span>
        <span>{gxwebVersion?.gxweb_image_identifier}</span>

        <span>Portal Container Image</span>
        <span>{gxwebVersion?.portal_image_identifier}</span>
      </div>

      <h2>Version Settings</h2>
      <div className="ValueGrid">
        <CheckBoxInput
          editing={editing}
          name="Is LTS"
          onChange={(value) =>
            dispatch({ type: GXWEB_VERSION_ACTIONS.VERSION_IS_LTS, value })
          }
          checked={gxwebVersion?.is_lts}
        />
        <TextInput
          editing={editing}
          name="Version"
          onChange={(value) =>
            dispatch({ type: GXWEB_VERSION_ACTIONS.VERSION_VERSION, value })
          }
          value={gxwebVersion?.version}
        />

        <span>Numeric Version</span>
        <span>{gxwebVersion?.numeric_version}</span>

        <TextInput
          editing={editing}
          name="GXWeb Image Name"
          onChange={(value) =>
            dispatch({ type: GXWEB_VERSION_ACTIONS.VERSION_GXWEB_IMAGE, value })
          }
          value={gxwebVersion?.gxweb_image}
        />
        <TextInput
          editing={editing}
          name="GXWeb Image Branch"
          onChange={(value) =>
            dispatch({
              type: GXWEB_VERSION_ACTIONS.VERSION_GXWEB_BRANCH,
              value,
            })
          }
          value={gxwebVersion?.gxweb_branch}
        />

        <TextInput
          editing={editing}
          name="Portal Image Name"
          onChange={(value) =>
            dispatch({
              type: GXWEB_VERSION_ACTIONS.VERSION_PORTAL_IMAGE,
              value,
            })
          }
          value={gxwebVersion?.portal_image}
        />
        <TextInput
          editing={editing}
          name="Portal Image Branch"
          onChange={(value) =>
            dispatch({
              type: GXWEB_VERSION_ACTIONS.VERSION_PORTAL_BRANCH,
              value,
            })
          }
          value={gxwebVersion?.portal_branch}
        />
      </div>
    </div>
  );
};

export default GxwebVersion;
