import React from 'react';

const Home: React.FC = () => (
  <>
    <h1>Home</h1>
    <div>
      <img
        src={`https://cataas.com/cat?uniqueNum=${Math.random()}`}
        alt="Cat"
      />
    </div>
  </>
);

export default Home;
