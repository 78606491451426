import { PDFDocument } from 'pdf-lib';

export const MergePDF = async (firstBlob, secondBlob) => {
    const firstData = await firstBlob.arrayBuffer();
    const secondData = await secondBlob.arrayBuffer();

    const firstPDF = await PDFDocument.load(new Uint8Array(firstData));
    const secondPDF = await PDFDocument.load(new Uint8Array(secondData));
   
    const mergedPDF = await PDFDocument.create();
    
   const firstPages = await mergedPDF.copyPages(firstPDF, firstPDF.getPageIndices());
   for (const page of firstPages) {
       mergedPDF.addPage(page);
   }
 
   const secondPages = await mergedPDF.copyPages(secondPDF, secondPDF.getPageIndices());
   for (const page of secondPages) {
       mergedPDF.addPage(page);
   }
  
    const mergedData = await mergedPDF.save();
    const blob = new Blob([mergedData], { type: 'application/pdf' });

    return blob;
};