import React from 'react';
import CheckBoxInput from '../../components/EditableInputs/CheckBoxInput';

import Many2OneInput from '../../components/EditableInputs/Many2OneInput/Many2OneInput';
import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';
import NumberInput from '../../components/EditableInputs/NumberInput';
import styles from '../../neumorpism.module.scss';
import {
  QUOTE_ACTIONS,
  QuoteActions,
  QuoteBillablePrice,
  QuoteLineType,
  QuoteType,
} from './quotationReducer';
import VoipPackage from './VoipPackage';
import AdditionalServices from './AdditionalServices';

const calculateLineCommission = (
  sale_price: number,
  billable_price: QuoteBillablePrice
) => {
  const {
    expected_price,
    expected_commission,
    commission_above_expected_multiplier,
    commission_below_expected_multiplier,
  } = billable_price;

  if (sale_price > expected_price) {
    return (
      expected_commission +
      (sale_price - expected_price) * commission_above_expected_multiplier
    );
  }

  if (sale_price < expected_price) {
    return (
      expected_commission -
      (expected_price - sale_price) * commission_below_expected_multiplier
    );
  }

  return expected_commission;
};

/*
 * expected_commission - (expected_price - x) * commission_below_expected_multiplier = 0
 * commission - (expected - x) * multiplier = 0
 * commission - (expected * multiplier) + (x * multiplier) = 0
 * commission - (expected * multiplier) = -(x * multiplier)
 * (commission / multiplier) - expected = -x
 * expected - (commission / multiplier) = x
 */
const calculateMinSalePrice = ({
  expected_price,
  expected_commission,
  commission_below_expected_multiplier,
}: QuoteBillablePrice) =>
  expected_price - expected_commission / commission_below_expected_multiplier;

type LinePropTypes = {
  dispatch: React.Dispatch<QuoteActions>;
  editing: boolean;
  line: QuoteLineType;
  quote: QuoteType;
  quoteState: boolean;
};

const Line = ({
  dispatch,
  editing,
  quote,
  line: { id, billable_price, sale_price, quantity, show_on_invoice, adhoc_item},
  quoteState,
}: LinePropTypes) => (
  <tr>
    <td>{billable_price.item_type}</td>
    <td>{billable_price.product}</td>
    <td>{billable_price.sequence}</td>
    <td>{billable_price.name}</td>
    <td>{billable_price.full_price}</td>
    <td>{billable_price.expected_price}</td>
    <td>
      <NumberInput
        editing={quoteState && !adhoc_item}
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_LINE_PRICE, id, value })
        }
        value={sale_price}
        step={0.01}
        min={calculateMinSalePrice(billable_price)}
      />
    </td>
    <td>
      <NumberInput
        editing={quoteState}
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_LINE_QUANTITY, id, value })
        }
        value={quantity}
        min={0}
      />
    </td>
    <td>
      {!adhoc_item ?
      (sale_price * quantity).toFixed(2)
      :
      (sale_price * quantity).toFixed(5)
      }
    </td>
    <td>
      {(calculateLineCommission(sale_price, billable_price) * quantity).toFixed(
        2
      )}
    </td>
    <td>{billable_price.calculated_commission}</td>
    <td>
      <CheckBoxInput
        editing={editing ? billable_price.can_delete_item : editing}
        name=""
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.SHOW_ITEMS_ON_INVOICE, id, value })
        }
        checked={show_on_invoice}
      />
    </td>
  </tr>
);

type QuotationLinesPropTypes = {
  dispatch: React.Dispatch<QuoteActions>;
  editing: boolean;
  priceLists?: Many2OneType[];
  quote?: QuoteType;
  commissions?: QuoteItemType[];
  quoteState: boolean;
  quoteAccepted: boolean;
};

type QuoteItemType = {
  item_type_commission_totals: QuoteItemTypeTotals[];
};

type QuoteItemTypeTotals = {
  item_type: string;
  yearly_commission: number;
  monthly_commission: number;
  quartely_commission: number;
  once_off_commission: number;
};

const QuotationLines = ({
  dispatch,
  editing,
  priceLists,
  quote,
  commissions,
  quoteState,
  quoteAccepted,
}: QuotationLinesPropTypes) => (
  <>
    <div className={styles.FormHeader}>
      <div className={styles.Heading}>
        <h1>Lines</h1>
      </div>
    </div>
    
    <div className={styles.FormWrapper}>
      <div className={styles.box}>
      <VoipPackage quoteAccepted={quoteAccepted}/> <AdditionalServices quoteAccepted={quoteAccepted}/>
      </div>
    
    <div className="ValueGrid">
      <Many2OneInput
        editing={quoteState}
        name="Price List"
        link=""
        onChange={(value) =>
          dispatch({ type: QUOTE_ACTIONS.QUOTE_PRICE_LIST, value })
        }
        value={quote?.price_list}
        values={priceLists}
      />
    </div>
    </div>

    <table className={styles.QuotationLinesTable}>
      <thead>
        <tr>
          <th>Item Type</th>
          <th>Product Name</th>
          <th>Order</th>
          <th>Name</th>
          <th>Full Price</th>
          <th>Expected Price</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Total</th>
          <th>Commission</th>
          <th>Calculated Commission</th>
          <th>Show on Invoice</th>
        </tr>
      </thead>
      <tbody>
        {quote?.lines
          ? quote?.lines.map((line, index) => (
              <Line key={index} {...{ dispatch, editing, line, quote, quoteState }} />
            ))
          : null}
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ borderTop: '1px solid black' }}>
            {quote?.lines
              ?.reduce(
                (total, line) => total + line.sale_price * line.quantity,
                0
              )
              .toFixed(2)}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <h2>Commission Totals</h2>
    <table className={styles.QuotationLinesTable}>
      <thead>
        <tr>  
          <th></th>
          <th>Once-off</th>
          <th>Estimated Monthly</th>
          <th>Estimated Yearly</th>
        </tr>
      </thead>
    {commissions?.map((item) => (
      <>
    {item.item_type_commission_totals.map((items) => (
      <>
      <tbody>
        <tr>
          <td><b>{items.item_type}{":"}</b></td>
          <td>{items.once_off_commission.toFixed(2)}</td>
          <td>{items.monthly_commission}</td>
          <td>{items.yearly_commission.toFixed(2)}</td>
        </tr>
      </tbody>
    </>
    ))}
    </>
    ))}
    </table> 
  </>
);

export default QuotationLines;
