import { faAngleDoubleRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OTPInput from '../../components/EditableInputs/OTPInput';
import { post, get } from '../../utils/api';
import styles from '../EmployeeContracts/employee_contracts.module.scss';

export type cellNumber= {
  cell_number: string;
};

const OTPFormValidateEmployeeSubmit: React.FC = () => {
  const { jwt_token }: any = useParams();
  const history = useHistory();
  const [signedOtp, setSignedOtp] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useState('');
  const [signedCellNo, setSignedCellNo] = useState({} as cellNumber);
  const [errors, setError] = useState('');

  const fetchFromDb = async (url:any, setFn:any) => {
    const resp = await get(url);
    if (resp.ok) {
      setFn(await resp.json());
    }
    return resp.ok;
};

  useEffect(() => {
    fetchFromDb(`/employee_contract/get_number_otp/${jwt_token}`, setSignedCellNo);
},[]);

  const otpValidate = async (
    signedOtp?: string,
  ) => {
    const resp = await post(`/employee_contract/validate_otp_contract/${jwt_token}`, {
      signedOtp: signedOtp,
    });

    if (!resp.ok) {
        setError('Something went wrong. Please send OTP again.');
      return false;
    }

    const json = await resp.json();
    if (!json.exists) {
        setError('The OTP you entered is invalid');
    }
    else {
        setError('');
    }

    return json.exists;
  };

  const sendOTP = async (e :any) => {
    e.preventDefault();
    setDisabled(false);
    if (signedCellNo.cell_number === '') {
      setError("Please enter a cellphone number.")
    }
    else {
      setError("Please check your cellphone for your one time pin.")
    }
    const resp = await post(`/employee_contract/set_otp/${jwt_token}`, {
      signedCellNo: signedCellNo.cell_number,
    });

    if (resp.ok) {
    } else {
      setMessage('Something went wrong. Try to request a OTP again.');
    }
    return resp.ok;
  };

  const submitOTP = async(e: any) => {
    setError('');
    e.preventDefault();
    let otpValid = await otpValidate(signedOtp);
    console.log(otpValid);
    if (otpValid) {
      const resp = await get(`/employee_contract/revoke_token/${jwt_token}`)
      if(resp.ok) {
        history.push({ pathname: `/login` })
      }
    }
  };

  return (
    <>
    <div className={styles.otpSubmit}>
        <OTPInput state={[signedOtp, setSignedOtp]} setMessage={setMessage} disabled={disabled} />
        <button className="btnquote_view" onClick={sendOTP}>
            <FontAwesomeIcon icon={faAngleDoubleRight} color="black" />
            Send OTP
        </button>
        <br />
        <span className="errormsg">{message}</span>
        <br />
        <br />
        <button className={styles.btn_submit_o_t_p} onClick={submitOTP}>
            <FontAwesomeIcon icon={faCheck} color="white" />
            Submit
        </button>
        
        <br />
        <span className={styles.info_msg}>{errors}</span>
    </div>
    </>
  )
};

export default OTPFormValidateEmployeeSubmit;
