import React from 'react';

import CheckBoxInput from '../../components/EditableInputs/CheckBoxInput';
import Many2OneInput from '../../components/EditableInputs/Many2OneInput/Many2OneInput';
import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';
import SelectInput from '../../components/EditableInputs/SelectInput';
import TextAreaInput from '../../components/EditableInputs/TextAreaInput';
import TextInput from '../../components/EditableInputs/TextInput';
import { activeTypeMap } from '../InstanceList/InstanceListItem';
import {
  INSTANCE_ACTIONS,
  InstanceActions,
  InstanceData,
} from './instanceReducer';

type GxwebSettingsValuesPropTypes = {
  instance?: InstanceData;
  editing: boolean;
  hostingLocations?: Many2OneType[];
  gxwebVersions?: Many2OneType[];
  dispatch: React.Dispatch<InstanceActions>;
};

const GxwebSettingsValues = ({
  instance,
  editing,
  hostingLocations,
  gxwebVersions,
  dispatch,
}: GxwebSettingsValuesPropTypes) => (
  <>
    <h2>GXWeb Settings</h2>
    <div className="ValueGrid">
      <SelectInput
        name="Active Type"
        value={instance?.active_type}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_ACTIVE_TYPE, value })
        }
        options={activeTypeMap}
      />

      <TextInput
        name="Sub Domain"
        value={instance?.subdomain}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_SUBDOMAIN, value })
        }
      />

      <Many2OneInput
        name="Hosting Location"
        value={instance?.hosting_location}
        link={`/hosting_location/${instance?.hosting_location.id}`}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_HOSTING_LOCATION, value })
        }
        values={hostingLocations}
      />

      <CheckBoxInput
        name="LTS Version Only"
        checked={instance?.lts_version_only}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_LTS_VERSION_ONLY, value })
        }
      />

      <CheckBoxInput
        name="Freeze Version"
        checked={instance?.freeze_version}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_FREEZE_VERSION, value })
        }
      />

      <Many2OneInput
        name="GXweb Version"
        value={instance?.gxweb_version}
        link={`/gxweb_version/${instance?.gxweb_version.id}`}
        editing={editing}
        onChange={(value) =>
          dispatch({
            type: INSTANCE_ACTIONS.INSTANCE_GXWEB_VERSION,
            value,
          })
        }
        values={gxwebVersions}
      />

      <Many2OneInput
        name="GXweb Rollback Version"
        value={instance?.gxweb_rollback_version}
        link={`/gxweb_version/${instance?.gxweb_rollback_version.id}`}
        editing={editing}
        onChange={(value) =>
          dispatch({
            type: INSTANCE_ACTIONS.INSTANCE_GXWEB_ROLLBACK_VERSION,
            value,
          })
        }
        values={gxwebVersions}
      />

      <span>Port Number</span>
      <span>{instance?.gxweb_port}</span>

      <span>Portal Port Number</span>
      <span>{instance?.portal_port}</span>

      <TextAreaInput
        name="Gxweb ENV"
        value={instance?.gxweb_env}
        editing={editing}
        onChange={(value) =>
          dispatch({ type: INSTANCE_ACTIONS.INSTANCE_GXWEB_ENV, value })
        }
      />
    </div>
  </>
);

export default GxwebSettingsValues;
