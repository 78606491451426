import React, { useEffect, useState } from 'react';

import toISODateString from '../../utils/toISODateString';
import styles from './Inputs.module.scss';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';
import Validator from '../../utils/Validator';

type DateInputPropTypes = {
  editing: boolean;
  name: string;
  onChange: (value: Date) => void;
  value?: Date;
  errors?: any;
  required?: boolean;
  onBlur?: any;
};

const DateInputValidation = ({ editing, name, onChange, value, errors , required, onBlur}: DateInputPropTypes) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!editing) {
      setTouched(false);
    }
  }, [editing]);

  const changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    onChange(new Date(e.target.valueAsNumber));
  };

  return (
    
    <>
    <DesktopLayout>
        <div className={styles.FieldLabel}>
          <label>{name}</label>
        </div>
        {editing ? (
          <input
            type="date"
            value={value ? toISODateString(value) : undefined}
            onChange={changed}
            onBlur={onBlur}
            className={errors ? 'Error'&&styles.FieldInput : styles.FieldInput }
            required={required}
          />
        ) : (
          <span>{value?.toLocaleDateString()}</span>
        )}
        <Validator errors={errors} />
        </DesktopLayout>
        <MobileLayout>
          <div className={responsive.FieldLabel}>
            <label>{name}</label>
          </div>
          {editing ? (
            <input
              type="date"
              value={value ? toISODateString(value) : undefined}
              onChange={changed}
              onBlur={onBlur}
              className={errors ? 'Error'&&responsive.InputBox : responsive.InputBox }
              required={required}
            />
          ) : (
            <span className={responsive.InputBox}>{value?.toLocaleDateString()}</span>
          )}
        <Validator errors={errors} />
        </MobileLayout>
    </>
  );
};

export default DateInputValidation;
