import {
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

import React from 'react';
import { BusinessDetailsType } from './BusinessDetailsReducer';
import { QuoteConfigPropTypes, QuoteConfigBankingDetails } from '../Quotation/QuoteTemplate';

interface BusinessDetailsDebitOrderTypes {
    props?: BusinessDetailsType;
    quoteConfig?: QuoteConfigPropTypes;
    bankingDetails?: QuoteConfigBankingDetails[];
};

const BusinessDetailsAuthSignedPDF: React.FC<BusinessDetailsDebitOrderTypes> = (business) => {
    const styles = StyleSheet.create({
        termsContact: {
            fontSize: '11pt',
            textDecoration: 'underline',
            fontWeight: 'bold',
        },
        h2: {
            fontFamily: 'Roboto Condensed',
            fontSize: '14pt',
            textTransform: 'uppercase',
            color: '#005e91',
            fontWeight: 700,
        },
        spaceNone: {
            marginTop: 0,
            marginBottom: 0,
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        row: {
            flexDirection: 'column',
            marginBottom: 10,
        },
        bold: {
            fontWeight: 'bold',
        },
        tableRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        padding: {
            paddingBottom: '4px'
        },
        textBlock: {
            flexDirection: 'row',
            flexWrap: 'wrap', 
        },
    })
    return (
    <View style={styles.row}>
        <View style={styles.column}>
                <Text style={[styles.h2, styles.spaceNone]}>Signature Authorization Form and Acceptance of Terms of Agreement</Text>
                <View style={styles.tableRow}>
                    <Text style={[styles.bold, styles.padding]}>I </Text>
                    <View style={styles.textBlock}>
                        <Text style={[styles.termsContact, styles.padding]}>{business.props?.signed_name} {business.props?.signed_surname} </Text>
                        <Text style={styles.padding}> hereby certify that I am the</Text>
                        <Text style={[styles.termsContact, styles.padding]}>{business.props?.signed_role} </Text>
                        <Text style={styles.padding}> of </Text>
                        <Text style={[styles.termsContact, styles.padding]}>{business.props?.signed_on_behalf} </Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <Text style={styles.padding}>with cell phone number </Text>
                    <Text style={[styles.termsContact, styles.padding]}>{business.props?.signed_cell} </Text>
                    <Text style={styles.padding}> ,and email address </Text>
                    <Text style={[styles.termsContact, styles.padding]}>{business.props?.signed_email}.</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={[styles.bold, styles.padding]}>I </Text>
                    <View style={styles.textBlock}>
                        <Text style={[styles.termsContact, styles.padding]}>{business.props?.signed_name} {business.props?.signed_surname} </Text>
                        <Text style={styles.padding}> confirm that I have the necessary authority to sign this document on behalf </Text>
                        <Text style={styles.padding}> of </Text>
                        <Text style={[styles.termsContact, styles.padding]}>{business.props?.signed_on_behalf}. </Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <Text style={styles.padding}> Signed on </Text>
                    <Text style={styles.padding}> {business.props?.signed_date}</Text>
                    <Text style={styles.padding}> at </Text>
                    <Text style={[styles.termsContact, styles.padding]}>{business.props?.signed_place}</Text>
                </View>
        </View>
    </View>
    )
};

export default BusinessDetailsAuthSignedPDF;
