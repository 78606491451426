import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { post } from "../../utils/api";
import styles from '../../neumorpism.module.scss';
import style from './Quotation.module.scss'

type VoipPackagePropTypes = {
    quoteAccepted?: boolean;
};

const VoipPackage: React.FC <VoipPackagePropTypes>= (quoteAccepted) => {
   const [packageSize, setPackageSize] = useState('');
   const [error, setError] = useState('');
   const history = useHistory();

   const voipHandleChange = async(e:any) => {
        const { value } = e.target;
        setPackageSize(value);
    };

    const openVoipDialog = () => {
        setError('');
        var elem = document.getElementById("VoipDialog") as HTMLInputElement;
        elem.style.display = "block";
    };

    const closeVoipDialog = () => {
        setError('');
        var elem = document.getElementById("VoipDialog") as HTMLInputElement;
        elem.style.display = "none";
    };
    
    const voipAddLines = async(id:any) => {
        setError('');
        const resp = await post('/quotation/voip_package_add_lines', {
          voip_package: packageSize,
          quote_id: id,
        });

        if (resp.ok) {
          const data = await resp.json();
          if (data) {
            closeVoipDialog();
            window.location.reload();
          }
        }
        setError('Server error occured, unable to add Voip billing items'); 
    };

    return (
        <>
        {!quoteAccepted.quoteAccepted ? <button className={styles.ActionBtn} onClick={openVoipDialog}>Add VOIP</button>:null}
        <div id="VoipDialog" className={style.modal}>
            <div className={style.modalContent}>
                <h4 className={style.PopupHeader} style={{textAlign: 'left', margin: 'auto'}}>
                    Choose VOIP Billing package below
                </h4>
                <hr />
                <br />
                <span style={{textAlign: 'left'}}>VOIP Package Size </span> 
                <select className={styles.input} name="voip_package_size" style={{width: '60%' }} onChange={(e) => voipHandleChange(e)}>
                    <option value={''} key={0}></option>
                    <option value={'s'} key={1}>Small</option>
                    <option value={'m'} key={2}>Medium</option>
                    <option value={'l'} key={3}>Large</option>
                    <option value={'p'} key={4}>Pilot/POC</option>
                </select>
                <button className={styles.ContinueQuoteBtn} onClick={() => voipAddLines(history.location.pathname.split('/')[2])}>OK</button>
                <button className={styles.DiscardQuoteBtn} onClick={closeVoipDialog}>Cancel</button>
                <br />
                <br />
                <span className={style.error}>{error}</span>
            </div>
        </div>
        </>
    )
};

export default VoipPackage;