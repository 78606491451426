import { InstanceListItem } from '../InstanceListItem';

export enum COLUMN {
  LINK,
  VERSION,
  HOSTING_GROUP,
  DATA_CENTER,
  ACTIVE_TYPE,
  DATABASE,
  CLUSTER,
}

export enum SORT_DIRECTION {
  ASC,
  DEC,
}

export type Sort = {
  column: COLUMN;
  direction: SORT_DIRECTION;
};

const sortStr = (a: string, b: string, dir: SORT_DIRECTION) =>
  dir === SORT_DIRECTION.ASC
    ? a
      ? b
        ? a.localeCompare(b)
        : -1
      : 1
    : b
    ? a
      ? b.localeCompare(a)
      : -1
    : 1;

export const sortInstanceListSingle = (
  instanceList: InstanceListItem[],
  sort: Sort
) => {
  switch (sort.column) {
    case COLUMN.LINK:
      return instanceList.sort((a, b) =>
        sortStr(a.link, b.link, sort.direction)
      );
    case COLUMN.VERSION:
      return instanceList.sort((a, b) =>
        sortStr(a.version, b.version, sort.direction)
      );
    case COLUMN.HOSTING_GROUP:
      return instanceList.sort((a, b) =>
        sortStr(a.hosting_group, b.hosting_group, sort.direction)
      );
    case COLUMN.ACTIVE_TYPE:
      return instanceList.sort((a, b) =>
        sortStr(a.active_type, b.active_type, sort.direction)
      );
    case COLUMN.DATA_CENTER:
      return instanceList.sort((a, b) =>
      sortStr(a.data_center, b.data_center, sort.direction)
      );
    case COLUMN.DATABASE:
      return instanceList.sort((a, b) =>
        sortStr(a.database, b.database, sort.direction)
      );
    case COLUMN.CLUSTER:
      return instanceList.sort((a, b) =>
        sortStr(a.cluster, b.cluster, sort.direction)
      );
  }
};

const addColumn = (
  setSort: React.Dispatch<React.SetStateAction<Sort[]>>,
  newSort: Sort
) => {
  setSort((sort) => [
    ...sort.filter((s) => s.column !== newSort.column),
    newSort,
  ]);
};

export default addColumn;
