import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './employee_contracts.module.scss';
import Collapse from '@material-ui/core/Collapse';
import { useParams } from 'react-router-dom';
import { PersonalDetail, fileLT2MBValidator } from './EmployeeContractDetail';
import { post, get } from '../../utils/api';
import InputUpload from '../../components/EditableInputs/InputUpload';

interface IfirstChildProps {
    updatePersonalDetail: (arg: any) => void;
    currentContract: PersonalDetail;
}

const EmployeePersonalDetail: React.FC<IfirstChildProps> = ({ updatePersonalDetail, currentContract }) => {
    const { jwt_token }: any = useParams();
    const [calledName, setCalledName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [cellNumber, setCellNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [unitNumber, setUnitNumber] = useState('');
    const [complexName, setComplexName] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [streetName, setStreetName] = useState('');
    const [suburb, setSuburb] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [poBox, setPobox] = useState(false);
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [line3, setLine3] = useState('');
    const [contract_id, setContractId] = useState('');
    const [postalCodePoBox, setpostalCodePoBox] = useState('');
    const [personalDetailComplete, setPersonalDetailComplete] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [errorComplexName, setErrorComplexName] = useState('');
    const [errorName, setErrorName] = useState('');
    const [errorFirstName, setErrorFirstName] = useState('');
    const [errorLastName, setErrorLastName] = useState('');
    const [errorUnitNumber, setErrorUnitNumber] = useState('');
    const [errorIdno, setErrorIdno] = useState('');
    const [errorStreetNumber, setErrorStreetNumber] = useState('');
    const [errorStreetName, setErrorStreetName] = useState('');
    const [errorSuburb, setErrorSurburb] = useState('');
    const [errorCity, setErrorCity] = useState('');
    const [errorPostalCode, setErrorPostalCode] = useState('');
    const [errorLine1, setErrorLine1] = useState('');
    const [errorLine2, setErrorLine2] = useState('');
    const [errorLine3, setErrorLine3] = useState('');
    const [errorPostalCodeMail, setErrorPostalCodeMail] = useState('');
    const [errorDateOfBirth,setErrorDateOfBirth] = useState('')
    const [idDocument, setIdDocument] = useState('');
    const [error, setError] = useState(false);
    const [personalDetail, setPersonalDetail] = useState({
        'calledName': calledName,
        'id': contract_id,
        'firstName': firstName,
        'lastName': lastName,
        'birthday': birthday,
        'cellNumber': cellNumber,
        'idNumber': idNumber,
        'unitNumber': unitNumber,
        'complexName': complexName,
        'streetNumber': streetNumber,
        'streetName': streetName,
        'suburb': suburb,
        'city': city,
        'postalCode': postalCode,
        'poBox': poBox,
        'line1': line1,
        'line2': line2,
        'line3': line3,
        'postalCodePoBox': postalCodePoBox,
        'personalDetailComplete':personalDetailComplete,
        'idDocument':idDocument
    } as PersonalDetail);

    const handleChange = (e: any) => {
        setError(false);
        const { name, value } = e.target;
        setPersonalDetail(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const loadCurrentContract = () => {
        if (currentContract && currentContract?.cellNumber) {
            setPersonalDetail(currentContract)
            setPobox(currentContract.poBox)
        }
    }

    const handlePobox = (e: any) => {
        setPobox(!poBox)
        personalDetail.poBox = poBox
    }

    const formValidate = async (e: { preventDefault: () => void }) => {
        if (personalDetail.calledName === '' || personalDetail.calledName === null) {
            setErrorName('Please enter your name');
            setError(true);
        } else {
            setErrorName('');
        }
        if (personalDetail.firstName === '' || personalDetail.firstName === null) {
            setErrorFirstName('Please enter your first name');
            setError(true);
        } else {
            setErrorFirstName('');
        }
        if (personalDetail.lastName === '' || personalDetail.lastName === null) {
            setErrorLastName('Please enter your last name');
            setError(true);
        } else {
            setErrorLastName('');
        }
        if (personalDetail.idNumber === '' || personalDetail.idNumber === null) {
            setErrorIdno('Please Enter ID No')
            setError(true);
        }else if (personalDetail.idNumber?.length < 13 ) {
            setError(true);
            setErrorIdno('ID number must be 13 characters long');  
        }else {
            setErrorIdno('');
        }
        if (personalDetail.complexName === '' || personalDetail.complexName === null) {
            setErrorComplexName('Please enter your complex name or N/A');
            setError(true);
        } else {
            setErrorComplexName('');
        }
        if (personalDetail.unitNumber === '' || personalDetail.unitNumber === null) {
            setErrorUnitNumber('Please enter your Unit Number or N/A');
            setError(true);
        } else {
            setErrorUnitNumber('');
        }
        if (personalDetail.streetNumber === '' || personalDetail.streetNumber === null) {
            setErrorStreetNumber('Please enter a street number');
            setError(true);
        } else {
            setErrorStreetNumber('');
        }
        if (personalDetail.streetName === '' || personalDetail.streetName === null) {
            setErrorStreetName('Please enter a street name');
            setError(true);
        } else {
            setErrorStreetName('');
        }
        if (personalDetail.suburb === '' || personalDetail.suburb === null) {
            setErrorSurburb('Please enter a suburb');
            setError(true);
        } else {
            setErrorSurburb('');
        }
        if (personalDetail.birthday === null || personalDetail.birthday === '') {
            setErrorDateOfBirth('Please enter a Birthday')
            setError(true);
        } else {
            setErrorDateOfBirth('');
        }
        if (personalDetail.city === '' || personalDetail.city === null) {
            setErrorCity('Please enter a city');
            setError(true);
        } else {
            setErrorCity('');
        }
        if (personalDetail.line1 === '' && poBox === false || personalDetail.line1 === null && poBox === false) {
            setErrorLine1('Please Line 1');
            setError(true);
        } else {
            setErrorLine1('');
        }
        if (personalDetail.postalCode === '' || personalDetail.postalCode === null) {
            setErrorPostalCode('Please enter a postal code');
            setError(true);
        } else {
            setErrorPostalCode('');
        }
        if (personalDetail.line2 === '' && poBox === false || personalDetail.line2 === null && poBox === false) {
            setErrorLine2('Please enter line 2');
            setError(true);
        } else {
            setErrorLine2('');
        }
        if (personalDetail.line3 === '' && poBox === false || personalDetail.line3 === null && poBox === false) {
            setErrorLine3('Please enter your full name');
            setError(true);
        } else {
            setErrorLine3('');
        }
        if(error===true){
            e.preventDefault()
        }
      };

    useEffect(() => {
        updatePersonalDetail(personalDetail)
        setTimeout(function () {
            if (loaded === false) {
                loadCurrentContract();
                setLoaded(true);
            }
        }, 1000);
    },);

    const save_employee_personal_details = async (e: any) => {
        if (personalDetail.calledName === '' || personalDetail.calledName === null) {
            setError(true);
        }else if (personalDetail.firstName === '' || personalDetail.firstName === null) {
            setError(true);
        }else if (personalDetail.lastName === '' || personalDetail.lastName === null) {
            setError(true);
        }else if (personalDetail.idNumber === '' || personalDetail.idNumber === null) {
            setError(true);
        }else if (personalDetail.idNumber?.length < 13 ) {
            setError(true);
        }else if (personalDetail.complexName === '' || personalDetail.complexName === null) {
            setError(true);
        }else if (personalDetail.unitNumber === '' || personalDetail.unitNumber === null) {
            setError(true);
        }else if (personalDetail.streetNumber === '' || personalDetail.streetNumber === null) {
            setError(true);
        }else if (personalDetail.streetName === '' || personalDetail.streetName === null) {
            setError(true);
        }else if (personalDetail.suburb === '' || personalDetail.suburb === null) {
            setError(true);
        }else if (personalDetail.city === '' || personalDetail.city === null) {
            setError(true);
        }else if (personalDetail.line1 === '' && poBox === false || personalDetail.line1 === null && poBox === false) {
            setError(true);
        }else if (personalDetail.postalCode === '' || personalDetail.postalCode === null) {
            setError(true);
        }else if (personalDetail.line2 === '' && poBox === false || personalDetail.line2 === null && poBox === false) {
            setError(true);
        }else if (personalDetail.line3 === '' && poBox === false || personalDetail.line3 === null && poBox === false) {
            setError(true);
        }else if (personalDetail.birthday === null) {
            setError(true);
        }else if (error === false) {
            const resp = await post(`/save_employee/save_personal_details/${jwt_token}`, {
                'calledName': calledName,
                'id': contract_id,
                'firstName': firstName,
                'lastName': lastName,
                'birthday': birthday,
                'cellNumber': cellNumber,
                'idNumber': idNumber,
                'unitNumber': unitNumber,
                'complexName': complexName,
                'streetNumber': streetNumber,
                'streetName': streetName,
                'suburb': suburb,
                'city': city,
                'postalCode': postalCode,
                'poBox': poBox,
                'line1': line1,
                'line2': line2,
                'line3': line3,
                'postalCodePoBox': postalCodePoBox,
                'personalDetail': personalDetail,
                'personalDetailComplete':true,
                'idDocument':idDocument
            });
            if (resp.ok) {
                return resp.ok;
            }
        }
    };

    return (
        <form onSubmit={formValidate}>
            <div className={styles.FormWrapper} onLoad={() => loadCurrentContract()}>
                <div className={styles.form}>
                    <div className={styles.column}>
                        <div>
                            <label className={styles.top}>Called Name:</label>
                            <input
                                type="text"
                                name="calledName"
                                value={personalDetail.calledName || '' }
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorName}</span>
                        <br />
                        <div>
                            <label>First Names:</label>
                            <input
                                type="text"
                                name="firstName"
                                value={personalDetail.firstName || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorFirstName}</span>
                        <br />
                        <div>
                            <label>Last Name:</label>
                            <input
                                type="text"
                                name="lastName"
                                value={personalDetail.lastName || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorLastName}</span>
                        <br />
                    </div>
                    <div className={styles.column}>
                        <div>
                            <label>Birthday:</label>
                            <input
                                type="date"
                                name="birthday"
                                value={personalDetail.birthday || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorDateOfBirth}</span>
                        <br />
                        <div>
                            <label>Cell Number:</label>
                            <input
                                type="text"
                                name="cellNumber"
                                value={personalDetail.cellNumber || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <br />
                        <div>
                            <label>ID Number:</label>
                            <input
                                type="text"
                                name="idNumber"
                                value={personalDetail.idNumber || ''}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorIdno}</span>
                        </div>
                        <br />
                        <div>
                            <label>ID Document:</label>
                                <InputUpload
                                    className="input"
                                    accept="image/*, application/pdf"
                                    errors={fileLT2MBValidator(idDocument) ? fileLT2MBValidator(idDocument) : null}
                                    onChange={(event: any) => setIdDocument(event)}
                                />
                        </div>
                    </div>

                    <div className={styles.column}>
                        <br />
                        <div>
                            <label>Complex Name:</label>
                            <input
                                type="text"
                                name="complexName"
                                value={personalDetail.complexName || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorComplexName}</span>
                        <br />
                        <div>
                            <label>Unit Number:</label>
                            <input
                                type="text"
                                name="unitNumber"
                                value={personalDetail.unitNumber || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorUnitNumber}</span>
                        <br />
                        <div>
                            <label>Street Number:</label>
                            <input
                                type="text"
                                name="streetNumber"
                                value={personalDetail.streetNumber || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorStreetNumber}</span>
                        <br />
                        <div>
                            <label>Street Name:</label>
                            <input
                                type="text"
                                name="streetName"
                                value={personalDetail.streetName || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorStreetName}</span>
                        <br />
                        <div>
                            <label>Suburb:</label>
                            <input
                                type="text"
                                name="suburb"
                                value={personalDetail.suburb || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorSuburb}</span>
                        <br />
                        <div>
                            <label>City:</label>
                            <input
                                type="text"
                                name="city"
                                value={personalDetail.city || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorCity}</span>
                        <br />
                        <div>
                            <label>Postal Code:</label>
                            <input
                                type="text"
                                name="postalCode"
                                value={personalDetail.postalCode || ''}
                                onChange={(handleChange)}
                            />
                        </div>
                        <span className={styles.errormsg}>{errorPostalCode}</span>
                        <br />
                    </div>
                    <div className={styles.column}>
                        <div>
                            <p>
                                Mail Address Same As Street Address:
                                <input type="checkbox"
                                    name="poBoxChecked"
                                    onChange={handlePobox}
                                    checked={poBox || false}
                                />
                            </p>
                        </div>
                        <br />
                        <Collapse in={!poBox} timeout="auto" unmountOnExit>
                            <div>
                                <label>Line 1:</label>
                                <input
                                    type="text"
                                    name="line1"
                                    value={personalDetail.line1 || ''}
                                    onChange={(handleChange)}
                                />
                            </div>
                            <span className={styles.errormsg}>{errorLine1}</span>
                            <br />
                            <div>
                                <label>Line 2:</label>
                                <input type="text"
                                    name="line2"
                                    value={personalDetail.line2 || ''}
                                    onChange={(handleChange)}
                                />
                            </div>
                            <span className={styles.errormsg}>{errorLine2}</span>
                            <br />
                            <div>
                                <label>Line 3:</label>
                                <input type="text"
                                    name="line3"
                                    value={personalDetail.line3 || ''}
                                    onChange={(handleChange)}
                                />
                            </div>
                            <span className={styles.errormsg}>{errorLine3}</span>
                            <br />
                            <div>
                                <label>Postal Code:</label>
                                <input type="text"
                                    name="postalCodePoBox"
                                    value={personalDetail.postalCodePoBox || ''}
                                    onChange={(handleChange)}
                                />
                            </div>
                            <br />
                        </Collapse>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.btn_footer}>
                        <button className={styles.save_button} onClick={save_employee_personal_details}>Complete</button>
                    </div>
                </div>
            </div>
        </form>
    )

}

export default EmployeePersonalDetail;

