import React, { useEffect, useState } from 'react';

import { get } from '../../utils/api';
import TimeAgo from '../TimeAgo/TimeAgo';
import styles from './Messages.module.scss';

type MessageType = {
  id: number;
  body: string;
  date: string;
  type: string;
  user: string;
};

const MessageMessageType = ({ type }: { type: string }) => {
  switch (type) {
    case 'comment':
      return <span>logged a note</span>;
    case 'notification':
      return <span>updated document</span>;
    default:
      return null;
  }
};

const Message = ({ message }: { message: MessageType }) => (
  <div className={styles.MessageWrapper}>
    <div className={styles.MessageHeader}>
    <div className={styles.UserLine}>
      <span>{message.user} </span>
      <MessageMessageType type={message.type} />
      <> | </>
      <TimeAgo date={new Date(message.date)} />
    </div>
    </div>
    <div
      className={styles.Body}
      dangerouslySetInnerHTML={{ __html: message.body }}
    />
    
  </div>
);

const fetchMessages = async (
  model: string,
  id: string | number | undefined,
  setMessages: React.Dispatch<React.SetStateAction<MessageType[]>>
) => {
  const resp = await get(`/message/${model}/${id}`);
  if (resp.ok) {
    setMessages(await resp.json());
  }
};

type MessagesPropTypes = {
  model: string;
  id: string | number | undefined;
};

const Messages = ({ model, id }: MessagesPropTypes) => {
  const [messages, setMessages] = useState([] as MessageType[]);

  useEffect(() => {
    if (id !== undefined) {
      fetchMessages(model, id, setMessages);
    }
  }, [model, id]);

  return (
    <div className={styles.Messages}>
      <h1>Messages</h1>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </div>
  );
};

export default Messages;
