import React from 'react';
import styles from './LoadingSpinner.module.scss';

const LoadingSpinner = ({ text, button, style } : any) => {

  const refreshPage = () => { 
    window.location.reload(); 
  };
  
  return (
    <div id="divSpinner" className={styles.spinner_loading}>
      <p style={style}>{text}</p>
      {button ?
        <button type="button" className={styles.refresh_page} onClick={refreshPage}><span>Click to reload</span></button>
      :
      null
      }
    </div>
  );
};

export default LoadingSpinner;
