import React, { useState } from 'react';
import style from '../../neumorpism.module.scss'

const OTPInput = ({ state: [signedOtp, setSignedOtp], setMessage, disabled }: any) => {
    const [error, setError] = useState(false);

    const validChars = (value: any) => {
      [...value].forEach(c => {
        if ('123456789ABCDEFGHJKLMNPQRSTUVWXYZ'.indexOf(c) === -1) {
          setMessage(NotValidOTPChar(c));
          setError(true);
        }
      });
    };
    const NotValidOTPChar = (char: any) => (
      <span>{char} is not a valid One Time Pin value</span>
    );

    const OTPLengthNot5 = <span>One Time Pin must be 5 characters</span>;

    const validateOTPLength = () => {
      if (signedOtp.length !== 5) {
        setMessage(OTPLengthNot5);
        setError(true);
      }
    };

    const validateOTP = (value: any) => {
      if (value) {
        validChars(value);
      }
    };

    const setOTP = (value: any) => {
      setMessage(null);
      setError(false);
      validateOTP(value);
      setSignedOtp(value);
    };

    return (
      <React.Fragment>
        <label className={style.FieldLabel}>OTP Pin:</label>
        <input
          className={['Input', error ? 'Error' : null].join(' ')}
          type="text"
          value={signedOtp}
          onChange={e => setOTP(e.target.value.toUpperCase())}
          onBlur={validateOTPLength}
          placeholder="One Time Pin"
          disabled={disabled}
        />
      </React.Fragment>
    );
};

export default OTPInput;
