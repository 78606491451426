import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';

import { websocket } from '../../utils/api';
import styles from './Logs.module.scss';

const ROW_HEIGHT = 20;

type LogStream = 'stdout' | 'stderr';

type LogType = {
  id: string;
  timestamp: Date;
  message: string;
  stream: LogStream;
  container_name: string;
};

const toLogType = (data: any) => ({
  ...data,
  timestamp: new Date(data.timestamp),
});

const Log = memo(
  ({ log, style }: { log: LogType; style: React.CSSProperties }) => (
    <div style={{ ...style, width: undefined }}>
      <span>{log.timestamp.getTime()}</span>
      <span>{log.stream}</span>
      <span>{log.container_name}</span>
      <pre className={log?.stream}>{log.message}</pre>
    </div>
  )
);

const Logs = () => {
  const { containerNames }: any = useParams();
  const [logs, setLogs] = useState([] as LogType[]);
  const [follow, setFollow] = useState(true);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<List>(null);

  useEffect(() => {
    if (containerNames) {
      const ws = websocket(`/logs_ws/${containerNames}`);
      ws.onmessage = (e) => {
        setLogs((oldLogs) =>
          [...oldLogs, toLogType(JSON.parse(e.data))].sort(
            (a, b) => a.timestamp - b.timestamp
          )
        );
      };
      return () => {
        ws.onmessage = null;
        ws.close();
      };
    }
  }, [containerNames]);

  useLayoutEffect(() => {
    if (follow) {
      listRef?.current?.scrollTo(logs.length * ROW_HEIGHT + 15);
    }
  });

  if (containerNames === undefined) {
    return null;
  }

  return (
    <div className={styles.LogsPage}>
      <h2>Logs {containerNames}</h2>
      <div>
        <button onClick={() => setFollow((f) => !f)}>
          {follow ? 'Stop following' : 'Follow'} logs
        </button>
      </div>
      <div className={styles.LogsList} ref={listContainerRef}>
        <List
          height={listContainerRef.current?.clientHeight || 0}
          width={listContainerRef.current?.clientWidth || 0}
          itemCount={logs.length}
          itemSize={ROW_HEIGHT}
          ref={listRef}
        >
          {({ index, style }) => (
            <Log key={logs[index].id} log={logs[index]} style={style} />
          )}
        </List>
      </div>
    </div>
  );
};

export default Logs;
