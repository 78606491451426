import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { post } from '../../utils/api';
import { QuoteType } from '../Quotation/quotationReducer';

import TextInputValidation from '../../components/EditableInputs/TextInputValidation';
import NumberInputValidation from '../../components/EditableInputs/NumberIntputValidation';
import styles from '../../neumorpism.module.scss';
import { BusinessDetailsType, BusinessDetailsHeardFromType, QuoteConfigPropTypes } from './BusinessDetailsReducer';
import { fetchBusinessDetails, fetchSpecialities } from './BusinessDetailsAPI';
import { toast, ToastContainer } from 'react-toastify';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';
import bstyles from '../BusinessDetails/BusinessDetails.module.scss';
import DateInputValidation from '../../components/EditableInputs/DateInputValidation';

const AcceptQuote: React.FC = () => {
  const { id, jwt_token }: any = useParams();
  const history = useHistory();
  const [businessValues, setBusinessValues] = useState({} as BusinessDetailsType);
  const [quoteConfig, setQuoteConfig] = useState({} as QuoteConfigPropTypes);
  const [quote, setQuote] = useState({} as QuoteType);
  const [tradingAs, setTradingAs] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [idRegNr, setIdRegNr] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [disciplineApi, setDisciplineApi] = useState([]);
  const [discipline, setDiscipline] = useState('');
  const [postalAddress, setPostalAddress] = useState('');
  const [postalAddress2, setPostalAddress2] = useState('');
  const [postalAddress3, setPostalAddress3] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [streetAddress3, setStreetAddress3] = useState('');
  const [streetCode, setStreetCode] = useState('');
  const [telNr, setTelNr] = useState(0);
  const [cellNr, setCellNr] = useState(0);
  const [faxNr, setFaxNr] = useState('');
  const [emailDebitOrder, setEmailDebitOrder] = useState('');
  const [emailAccounts, setEmailAccounts] = useState('');
  const [emailNewsletter, setEmailsNewsletter] = useState('');
  const [emailPracticeManager, setEmailPracticeManager] = useState('');
  const [creditControlName, setCreditControlName] = useState('');
  const [creditControlEmail, setCreditControlEmail] = useState('');
  const [businessDetailsLoading, setBusinessDetailsLoading] = useState(false);
  const [IOCellNo, setIOCellNo] = useState(0);
  const [practiceNr, setPracticeNr] = useState('');
  const [hpcsaNr, setHpcsaNr] = useState('');
  const [mhgUsername, setMHGUsername] = useState('');
  const [mhgPassword, setMHGPassword] = useState('');
  const [vatNr, setVatNr] = useState('');
  const [businessNameErr, setBusinessNameErr] = useState('');
  const [tradingAsErr, setTradingAsErr] = useState('');
  const [creditControlNameErr, setCreditControlNameErr] = useState('');
  const [creditControlEmailErr, setCreditControlEmailErr] = useState('');
  const [practiceNrErr, setPracticeNrErr] = useState('');
  const [hpcsaNrErr, setHpcsaNrErr] = useState('');
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [acceptContact, setAcceptContact] = useState('');
  const [acceptContactErr, setAcceptContactErr] = useState('');
  const [IOCellNoErr, setIOCellNoErr] = useState('');
  const [idDocument, setIdDocument] = useState('');
  const [companyDocument, setCompanyDocument] = useState('');
  const [bankDetails, setBankDetails] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [heardFrom, setHeardFrom] = useState<BusinessDetailsHeardFromType[]>([])
  const [seenSocialMedia, setSeenSocialMedia] = useState('');
  const [debitorderBool, setDebitOrderBool] = useState(false);
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');
  const [branchCode, setBranchCode] = useState(0);
  const [accountName, setAccountName] = useState('');
  const [accountNo, setAccountNo] = useState(0);
  const [accountType, setAccountType] = useState('');
  const [dateOfDebitOrder, setDateOfDebitOrder] = useState('');
  const [bankError, setBankError] = useState('');
  const [bankBranchError, setBankBranchError] = useState('');
  const [branchCodeError, setBranchCodeError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [accountNoError, setAccountNoError] = useState('');
  const [accountTypeError, setAccountTypeError] = useState('');
  const [dateOfDebitOrderError, setDateOfDebitOrderError] = useState('');
  const [signedOnBehalfOf, setSignedOnBehalfOf] = useState('');
  const [dateSigned, setDateSigned] = useState(new Date());
  const [signedName, setSignedName] = useState('');
  const [signedSurname, setSignedSurname] = useState('');
  const [signedRole, setSignedRole] = useState('');
  const [signedPlace, setSignedPlace] = useState('');
  const [signedCell, setSignedCell] = useState('');
  const [signedEmail, setSignedEmail] = useState('');
  const [signedOnBehalfOfErr, setSignedOnBehalfOfErr] = useState('');
  const [dateSignedErr, setDateSignedErr] = useState('');
  const [signedNameErr, setSignedNameErr] = useState('');
  const [signedSurnameErr, setSignedSurnameErr] = useState('');
  const [signedRoleErr, setSignedRoleErr] = useState('');
  const [signedPlaceErr, setSignedPlaceErr] = useState('');
  const [signedCellErr, setSignedCellErr] = useState('');
  const [signedEmailErr, setSignedEmailErr] = useState('');
  const [allowedToSign, setAllowedToSign] = useState(false);
  const [allowedToSignErr, setAllowedToSignErr] = useState('');
  const [isTokenValid, setIsTokenValid] = useState({"isValid": "", "isTemp": ""});
  let regexEmail = /^[\w\.-]+@[\w\.-]+\.\w+$/;
  
  useEffect(() => {
    if (id !== undefined) {
      const validateToken = async () => {
        const resp = await post(`/quotation/validate_token`, {jwt_token, id});

        if (resp.ok) {
          setIsTokenValid(await resp.json());
        }
      }
      validateToken();
    }
  },[id, jwt_token]);

  const setBusinessNames = (e: any) => {
    setBusinessName(e);
    setSignedOnBehalfOf(e);
  }

  const validateFields = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (businessName === '') {
      setBusinessNameErr('Please enter your business name');
    } else {
      setBusinessNameErr('');
    }
    if (tradingAs === '') {
      setTradingAsErr('Please enter your trading as name')
    }
    else {
      setTradingAsErr('');
    }
    if (creditControlName === '') {
        setCreditControlNameErr('Please enter the information officer\'s name');
    } else {
        setCreditControlNameErr('');
    }
    if (creditControlEmail.match(regexEmail)) {
        setCreditControlEmailErr('');
    } else {
        setCreditControlEmailErr('Invalid information officer email address');
    }
    if (String(IOCellNo).length < 10 ) {
        setIOCellNoErr('Information Officer cellphone number must be 10 digits long');
    } else {
        setIOCellNoErr('');
    }
    if (hpcsaNr === '') {
        setHpcsaNrErr('Please enter a your practice HPCSA number');
    } else {
        setHpcsaNrErr('');
    }
    if (bank === '' && debitorderBool===false) {
        setBankError('Please Enter A Bank');
    } else {
        setBankError('');
    }
    if (branch === '' && debitorderBool===false) {
        setBankBranchError('Please Enter A Branch');
    } else {
        setBankBranchError('');
    }
    if (branchCode === 0 && debitorderBool===false) {
        setBranchCodeError('Please Enter A Branch Code');
    } else {
        setBranchCodeError('');
    }
    if (accountName === '' && debitorderBool===false) {
        setAccountNameError('Please Enter A Account Name');
    } else {
        setAccountNameError('');
    }
    if (accountNo === 0 && debitorderBool===false) {
        setAccountNoError('Please Enter A Account No');
    } else {
        setAccountNoError('');
    }
    if (accountType === '' && debitorderBool===false) {
        setAccountTypeError('Please Enter A Account Type');
    } else {
        setAccountTypeError('');
    }
    if (dateOfDebitOrder === '' && debitorderBool===false) {
        setDateOfDebitOrderError('Please Select Date Of Debit Order');
    } else {
        setDateOfDebitOrderError('');
    }
    if (signedOnBehalfOf === '' || signedOnBehalfOf === undefined) {
      setSignedOnBehalfOf(businessName);
    } else {
      setSignedOnBehalfOfErr('');
    }
    if (signedName === '' || signedName === undefined) {
      setSignedNameErr('Please enter name');
    } else {
      setSignedNameErr('');
    }
    if (signedRole === '' || signedRole === undefined) {
      setSignedRoleErr('Please enter role in company');
    } else {
      setSignedRoleErr('');
    }
    if (signedPlace === '' || signedPlace === undefined) {
      setSignedPlaceErr('Please enter place signed');
    } else {
      setSignedPlaceErr('');
    }
    if (signedCell === '' || signedCell === undefined) {
      setSignedCellErr('Please enter cell number');
    } else {
      setSignedCellErr('');
    }
    if (signedEmail.match(regexEmail)) {
        setSignedEmailErr('');
    } else {
        setSignedEmailErr('Please enter email');
    }
    if (String(IOCellNo).length < 10 ) {
      setIOCellNoErr('Information Officer cellphone number must be 10 digits long');
    } else {
      setIOCellNoErr('');
    }
    if (signedSurname === '' || signedSurname === undefined) {
      setSignedSurnameErr('Please enter surname');
    } else {
      setSignedSurnameErr('');
    }
    if (allowedToSign === false) {
      setAllowedToSignErr('Please tick the checkbox to continue')
    } else {
      setAllowedToSignErr('');
    }
  };


  const BusinessDetailsUpdate = async (field: any, value: any) => {
    const resp = await post(`/quotation/business_details_update/${quote.id}`, {
      field,
      value
    });

    if (resp.ok) {
      return true;
    } else {
      toast.error(resp.statusText + '. Contact GoodX for further assistance.');
    }
  };

  const marketerSubmitSave = async (
    id: string,
    businessName: string,
    tradingAs: string,
    idRegNr: string,
    contactPerson: string,
    discipline: string,
    postalAddress: string,
    postalAddress2: string,
    postalAddress3: string,
    postalCode: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    streetCode: string,
    telNr: number,
    cellNr: number,
    faxNr: string,
    emailDebitOrder: string,
    emailAccounts: string,
    emailNewsletter: string,
    creditControlName: string,
    creditControlEmail: string,
    practiceNr: string,
    hpcsaNr: string,
    mhgUsername: string,
    mhgPassword: string,
    vatNr: string,
    acceptContact: string,
    agreementCheck: boolean,
    idDocument: string,
    companyDocument: string,
    bankDetails: string,
    IOCellNo: number,
    emailPracticeManager: string,
    businessType: string,
    heardFrom: string,
    socialMediaPlatforms: string,
    debitorderBool: boolean,
    bank: string,
    branch: string,
    branchCode: number,
    accountName:string,
    accountNo:number,
    accountType:string,
    dateOfDebitOrder:string,
  ) => {
    setIsLoading(true);
    const resp = await post('/quotation/marketer_fill_in_details', {
      id: id,
      businessName: businessName,
      tradingAs: tradingAs,
      idRegNr: idRegNr,
      contactPerson: contactPerson,
      discipline: discipline,
      postalAddress: postalAddress,
      postalAddress2: postalAddress2,
      postalAddress3: postalAddress3,
      postalCode: postalCode,
      streetAddress: streetAddress,
      streetAddress2: streetAddress2,
      streetAddress3: streetAddress3,
      streetCode: streetCode,
      telNr: telNr,
      cellNr: cellNr,
      faxNr: faxNr,
      emailDebitOrder: emailDebitOrder,
      emailAccounts: emailAccounts,
      emailNewsletter: emailNewsletter,
      creditControlName: creditControlName,
      creditControlEmail: creditControlEmail,
      practiceNr: practiceNr,
      hpcsaNr: hpcsaNr,
      mhgUsername: mhgUsername,
      mhgPassword: mhgPassword,
      vatNr: vatNr,
      acceptContact: acceptContact,
      agreementCheck: agreementCheck,
      idDocument: idDocument,
      companyDocument: companyDocument,
      bankDetails: bankDetails,
      IOCellNo: IOCellNo,
      emailPracticeManager: emailPracticeManager,
      businessType: businessType,
      heardFrom: heardFrom,
      socialMediaPlatforms: socialMediaPlatforms,
      debitorderBool:debitorderBool,
      bank:bank,
      branch:branch,
      branchCode:branchCode,
      accountName:accountName,
      accountNo:accountNo,
      accountType:accountType,
      dateOfDebitOrder:dateOfDebitOrder
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
      history.push({ pathname: `/` });
      setIsLoading(false);
      } else {
        toast.error(resp.statusText + '. Contact GoodX for further assistance.');
        setIsLoading(false);
      }
    };

  const BusinessDetailsSave = async (
    id: string,
    businessName: string,
    tradingAs: string,
    idRegNr: string,
    contactPerson: string,
    discipline: string,
    postalAddress: string,
    postalAddress2: string,
    postalAddress3: string,
    postalCode: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    streetCode: string,
    telNr: number,
    cellNr: number,
    faxNr: string,
    emailDebitOrder: string,
    emailAccounts: string,
    emailNewsletter: string,
    creditControlName: string,
    creditControlEmail: string,
    practiceNr: string,
    hpcsaNr: string,
    mhgUsername: string,
    mhgPassword: string,
    vatNr: string,
    acceptContact: string,
    agreementCheck: boolean,
    idDocument: string,
    companyDocument: string,
    bankDetails: string,
    IOCellNo: number,
    emailPracticeManager: string,
    businessType: string,
    heardFrom: string,
    socialMediaPlatforms: string,
    debitorderBool: boolean,
    bank: string,
    branch: string,
    branchCode: number,
    accountName:string,
    accountNo:number,
    accountType:string,
    dateOfDebitOrder:string,
    allowedToSign : boolean,
    signedOnBehalfOf:string,
    dateSigned:Date,
    signedName:string,
    signedSurname:string,
    signedRole:string,
    signedPlace:string,
    signedCell:string,
    signedEmail:string
  ) => {
    setIsLoading(true);
    const resp = await post('/quotation/quote_approve', {
      id: id,
      businessName: businessName,
      tradingAs: tradingAs,
      idRegNr: idRegNr,
      contactPerson: contactPerson,
      discipline: discipline,
      postalAddress: postalAddress,
      postalAddress2: postalAddress2,
      postalAddress3: postalAddress3,
      postalCode: postalCode,
      streetAddress: streetAddress,
      streetAddress2: streetAddress2,
      streetAddress3: streetAddress3,
      streetCode: streetCode,
      telNr: telNr,
      cellNr: cellNr,
      faxNr: faxNr,
      emailDebitOrder: emailDebitOrder,
      emailAccounts: emailAccounts,
      emailNewsletter: emailNewsletter,
      creditControlName: creditControlName,
      creditControlEmail: creditControlEmail,
      practiceNr: practiceNr,
      hpcsaNr: hpcsaNr,
      mhgUsername: mhgUsername,
      mhgPassword: mhgPassword,
      vatNr: vatNr,
      acceptContact: acceptContact,
      agreementCheck: agreementCheck,
      idDocument: idDocument,
      companyDocument: companyDocument,
      bankDetails: bankDetails,
      IOCellNo: IOCellNo,
      emailPracticeManager: emailPracticeManager,
      businessType: businessType,
      heardFrom: heardFrom,
      socialMediaPlatforms: socialMediaPlatforms,
      debitorderBool:debitorderBool,
      bank:bank,
      branch:branch,
      branchCode:branchCode,
      accountName:accountName,
      accountNo:accountNo,
      accountType:accountType,
      dateOfDebitOrder:dateOfDebitOrder,
      allowedToSign:allowedToSign,
      signedOnBehalfOf:signedOnBehalfOf,
      dateSigned:dateSigned,
      signedName:signedName,
      signedSurname:signedSurname,
      signedRole:signedRole,
      signedPlace:signedPlace,
      signedCell:signedCell,
      signedEmail:signedEmail,
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
      window.location.reload();
      setIsLoading(false);
      } else {
        toast.error(resp.statusText + '. Contact GoodX for further assistance.');
        setIsLoading(false);
      }
    };

  const businessSubmit = async() => {
    if (allowedToSign) {
      if (agreementCheck) {
        if (
          businessName &&
          tradingAs &&
          Number(String(IOCellNo).length > 9) &&
          practiceNr.length > 6 &&
          hpcsaNr &&
          agreementCheck && 
          allowedToSign &&
          signedOnBehalfOf &&
          dateSigned &&
          signedName &&
          signedSurname &&
          signedRole &&
          signedPlace &&
          signedCell &&
          signedEmail
        ) {
          BusinessDetailsSave(
            id,
            businessName,
            tradingAs,
            idRegNr,
            contactPerson,
            discipline,
            postalAddress,
            postalAddress2,
            postalAddress3,
            postalCode,
            streetAddress,
            streetAddress2,
            streetAddress3,
            streetCode,
            telNr,
            cellNr,
            faxNr,
            emailDebitOrder,
            emailAccounts,
            emailNewsletter,
            creditControlName,
            creditControlEmail,
            practiceNr,
            hpcsaNr,
            mhgUsername,
            mhgPassword,
            vatNr,
            acceptContact,
            agreementCheck,
            idDocument,
            companyDocument,
            bankDetails,
            IOCellNo,
            emailPracticeManager,
            businessType,
            heardFrom as any,
            seenSocialMedia,
            !debitorderBool,
            bank,
            branch,
            branchCode,
            accountName,
            accountNo,
            accountType,
            dateOfDebitOrder,
            allowedToSign,
            signedOnBehalfOf ,
            dateSigned ,
            signedName ,
            signedSurname ,
            signedRole ,
            signedPlace ,
            signedCell ,
            signedEmail
          );
        }
      }
    }
  };

  const marketerSubmit = async() => {
        marketerSubmitSave(
          id,
          businessName,
          tradingAs,
          idRegNr,
          contactPerson,
          discipline,
          postalAddress,
          postalAddress2,
          postalAddress3,
          postalCode,
          streetAddress,
          streetAddress2,
          streetAddress3,
          streetCode,
          telNr,
          cellNr,
          faxNr,
          emailDebitOrder,
          emailAccounts,
          emailNewsletter,
          creditControlName,
          creditControlEmail,
          practiceNr,
          hpcsaNr,
          mhgUsername,
          mhgPassword,
          vatNr,
          acceptContact,
          agreementCheck,
          idDocument,
          companyDocument,
          bankDetails,
          IOCellNo,
          emailPracticeManager,
          businessType,
          heardFrom as any,
          seenSocialMedia,
          !debitorderBool,
          bank,
          branch,
          branchCode,
          accountName,
          accountNo,
          accountType,
          dateOfDebitOrder
        );
  };

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validation = (event:any,type:string , name:string) => {
    let err = ''
    switch(type) {
      case 'email':
        if (!isValidEmail(event.target.value)) {
          err ='Email is invalid';
        } else {
          err ='';
        }
    }
    switch(name) {
      case 'businessName':
        if (businessName === '') {
          return setBusinessNameErr('Please enter your business name');
        } else {
          return setBusinessNameErr('');
        }
      case 'tradingAs':
        if (tradingAs === '') {
          return setTradingAsErr('Please enter your trading as name')
        }
        else {
          return setTradingAsErr('');
        }
      case 'practiceNr':
        if (practiceNr.length < 7) {
          return setPracticeNrErr('Practice number must be 7 characters long');
        } else {
          return setPracticeNrErr('');
        }
      case 'hpcsaNr':
        if (hpcsaNr === '') {
          return setHpcsaNrErr('Please enter a your practice HPCSA number');
        } else {
          return setHpcsaNrErr('');
        }
      case 'creditControlName':
        if (creditControlName === '') {
          return setCreditControlNameErr('Please enter the information officer\'s name');
        } else {
          return setCreditControlNameErr('');
        }
      case 'creditControlEmail':
        if (creditControlEmail.match(regexEmail)) {
          return setCreditControlEmailErr('');
        } else {
          return setCreditControlEmailErr('Invalid information officer email address');
        }
      case 'acceptContact':
        if (acceptContact === '') {
          return setAcceptContactErr('Please enter your full name');
        } else {
          return setAcceptContactErr('');
        }
      case 'IOCellNo':
        if (String(IOCellNo).length < 10 ) {
          return setIOCellNoErr('Information Officer cellphone number must be 10 digits long');
        } else {
          return setIOCellNoErr('');
        }
      case 'bank':
        if (bank === '' && debitorderBool===false) {
          return setBankError('Please Enter A Bank');
        } else {
          return setBankError('');
        }
      case 'branch':
        if (branch === '' && debitorderBool===false) {
          return setBankBranchError('Please Enter A Branch');
        } else {
          return setBankBranchError('');
        }
      case 'branchCode':
        if (branchCode === 0 && debitorderBool===false) {
          return setBranchCodeError('Please Enter A Branch Code');
        } else {
          return setBranchCodeError('');
        }
      case 'accountName':
        if (accountName === '' && debitorderBool===false) {
          return setAccountNameError('Please Enter A Account Name');
        } else {
          return setAccountNameError('');
        }
      case 'accountNo':
        if (accountNo === 0 && debitorderBool===false) {
          return setAccountNoError('Please Enter A Account No');
        } else {
          return setAccountNoError('');
        }
      case 'accountType':
        if (accountType === '' && debitorderBool===false) {
          return setAccountTypeError('Please Enter A Account Type');
        } else {
          return setAccountTypeError('');
        }
      case 'dateOfDebitOrder':
        if (dateOfDebitOrder === '' && debitorderBool===false) {
          return setDateOfDebitOrderError('Please Select Date Of Debit Order');
        } else {
          return setDateOfDebitOrderError('');
        }
      case 'signedOnBehaldOf':
        if (signedOnBehalfOf === '') {
          setSignedOnBehalfOf(businessName);
        } else {
          return setSignedOnBehalfOfErr('');
        }
      case 'signedName':
        if (signedName === '') {
          return setSignedNameErr('Please Enter who signed this');
        } else {
          return setSignedNameErr('');
        }
      case 'signedRole':
        if (signedRole === '' || signedRole === undefined) {
          return setSignedRoleErr('Please enter role in company');
        } else {
          return setSignedRoleErr('');
        }
      case 'signedPlace':
        if (signedPlace === '' || signedPlace === undefined) {
          return setSignedPlaceErr('Please enter place signed');
        } else {
          return setSignedPlaceErr('');
        }
      case 'signedCell':
        if (signedCell === '' || signedCell === undefined) {
          return setSignedCellErr('Please enter cell number');
        } else {
          return setSignedCellErr('');
        }
      case 'signedEmail':
        if (signedEmail === '' || signedEmail === undefined) {
          return setSignedEmailErr('Please enter email');
        } else {
          return setSignedEmailErr('');
        }
      case 'signedSurname':
        if (signedSurname === '' || signedSurname === undefined) {
          return setSignedSurnameErr('Please enter surname');
        } else {
          return setSignedSurnameErr('');
        }
      case 'allowedToSign':
        if (allowedToSign === false || allowedToSign === undefined) {
          return setAllowedToSignErr('Please tick the checkbox to continue');
        } else {
          return setAllowedToSignErr('');
        }
    }
  };
  
  useEffect(() => {
   fetchSpecialities('/quotation/speciality', setDisciplineApi);
   fetchBusinessDetails(`/quotation/business_details/${id}`, setBusinessValues, setBusinessDetailsLoading);
   fetchBusinessDetails(`/quotation/${id}`, setQuote, setBusinessDetailsLoading);
   fetchSpecialities(`/quotation/config`, setQuoteConfig);
  }, []);
  
  useEffect(() => {
    setTradingAs(businessValues.trading_as || '');
    setBusinessName(businessValues.business_name || '');
    setBusinessType(businessValues.business_type || '');
    setIdRegNr(businessValues.id_reg_nr || '');
    setContactPerson(businessValues.contact_person || '');
    setDiscipline(businessValues.discipline_id || '');
    setPostalAddress(businessValues.postal_address || '');
    setPostalAddress2(businessValues.postal_address2 || '');
    setPostalAddress3(businessValues.postal_address3 || '');
    setStreetAddress(businessValues.street_address || '');
    setStreetAddress2(businessValues.street_address2 || '');
    setStreetAddress3(businessValues.street_address3 || '');
    setTelNr(businessValues.tel_nr || 0);
    setCellNr(businessValues.cell_nr || 0);
    setFaxNr(businessValues.fax_nr || '');
    setEmailAccounts(businessValues.email_accounts || '');
    setEmailDebitOrder(businessValues.email_debit_order || '');
    setEmailsNewsletter(businessValues.email_newsletter || '');
    setEmailPracticeManager(businessValues.email_practice_manager || '');
    setCreditControlName(businessValues.credit_control_name || '');
    setCreditControlEmail(businessValues.credit_control_email || '');
    setIOCellNo(businessValues.io_cell_nr || 0); 
    setPracticeNr(businessValues.practice_nr || '');
    setHpcsaNr(businessValues.hpcsa_nr || '');
    setMHGUsername(businessValues.mhg_username || '');
    setMHGPassword(businessValues.mhg_password || '');
    setVatNr(businessValues.vat_number || '');
    setAcceptContact(businessValues.accept_contact || '');
    setDebitOrderBool(quoteConfig.debit_order || false);
    setBank(businessValues.debit_order_bank || '');
    setBranch(businessValues.debit_order_branch || '');
    setBranchCode(businessValues.debit_order_branch_code || 0);
    setAccountName(businessValues.debit_order_account_name || '');
    setAccountNo(businessValues.debit_order_account_no || 0);
    setAccountType(businessValues.debit_order_account_type || '');
    setDateOfDebitOrder(businessValues.date_of_debit_order || '');  
    setAgreementCheck(businessValues.accept_terms || false); 
    setSeenSocialMedia(businessValues.seen_social_media || '');
    setHeardFrom(businessValues.heard_from || '');
    setIdDocument(businessValues.id_document || '');
    setCompanyDocument(businessValues.company_document || '');
    setBankDetails(businessValues.banking_details || '');
    setAllowedToSign(businessValues.allowed_to_sign || false);
    setSignedOnBehalfOf(businessValues.signed_on_behalf || '');
    setDateSigned(new Date());
    setSignedName(businessValues.signed_name || '');
    setSignedSurname(businessValues.signed_surname || '');
    setSignedRole(businessValues.signed_role || '');
    setSignedPlace(businessValues.signed_place || '');
    setSignedCell(businessValues.signed_cell || '');
    setSignedEmail(businessValues.signed_email || '');
  }, [businessValues, quoteConfig]);

  return (
    <>
    {quote?.action_state !== "accepted" && businessDetailsLoading ?
    <>
    <DesktopLayout>
    <div className={styles.FormWrapper}>
      <div className={styles.notification}>
        <p className='BannerText'><strong>Info!</strong> Complete the form below to accept the contract</p>
      </div>
      
      <form onSubmit={validateFields}>
        <div className={styles.FormHeader}>
          <div className={styles.Heading}>
            <h2>Quote: Part B</h2>
          </div>
        </div>
        <br />
        <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Business Details</h2>
        <div className={styles.FieldGroupSingle}>
          <TextInputValidation
            editing={true}
            name="Name of Business/Practice: *"
            onBlur={
              (e:any) => {
                validation(e, 'none','businessName')
                BusinessDetailsUpdate('business_name', businessName)
              }
            }
            onChange={(event) => setBusinessNames(event)} 
            errors={businessNameErr}
            value={businessName}
          />

        </div>
        <TextInputValidation
            editing={true}
            name="Trading As: *"
            onBlur={
              (e:any) => {
                validation(e, 'none','tradingAs')
                BusinessDetailsUpdate('trading_as', tradingAs)
              }
            }
            onChange={(event) => setTradingAs(event)}
            errors={tradingAsErr}
            value={tradingAs}
          />

        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="Practice (PCNS) number: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','practiceNr')
                  BusinessDetailsUpdate('practice_nr', practiceNr)
                }
              }
              onChange={(event) => setPracticeNr(event)}
              errors={practiceNrErr}
              value={practiceNr}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="HPCSA number: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','hpcsaNr')
                  BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
                }
              }
              onChange={(event) => setHpcsaNr(event)}
              errors={hpcsaNrErr}
              value={hpcsaNr}
            />
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Information Officer Details</h2>
          <TextInputValidation
            editing={true}
            name="Information Officers Name (POPI Act - Contracts): *"
            onBlur={
              (e:any) => {
                validation(e, 'None','creditControlName')
                BusinessDetailsUpdate('credit_control_name', creditControlName)
              }
            }
            onChange={(event) => setCreditControlName(event)}
            errors={creditControlNameErr}
            value={creditControlName}
          />
        </div>

        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>  
            <TextInputValidation
              editing={true}
              name="Cell number of Information Officer: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','IOCellNo')
                  BusinessDetailsUpdate('io_cell_nr', IOCellNo)
                }
              }
              onChange={(event:any) => setIOCellNo(event)}
              errors={IOCellNoErr}
              value={String(IOCellNo)}
              maxLength={10}
            />  
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name="Information Officers email address: *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','creditControlEmail')
                  BusinessDetailsUpdate('credit_control_email', creditControlEmail)
                }
              }
              onChange={(event) => setCreditControlEmail(event)}
              errors={creditControlEmailErr}
              value={creditControlEmail}
            />
          </div>
        </div>
      {debitorderBool === true ?
      <>
        <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Banking Details</h2>
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name="Account Name "
              onChange={(event) => setAccountName(event)}
              onBlur={
                (e:any) => {
                  validation(e, 'None','accountName')
                  BusinessDetailsUpdate('debit_order_account_name', accountName)
                }
              }
              errors={accountNameError}
              required={true}
              value={accountName}
              />
            </div>
              <div className={styles.FieldGroupR}>
                <TextInputValidation
                  editing={true}
                  name="Bank: "
                  onChange={(event) => setBank(event)}
                  onBlur={
                    (e:any) => { 
                      validation(e, 'None','bank')
                      BusinessDetailsUpdate('debit_order_bank', bank)
                    }
                  }
                  errors={bankError}
                  required={true}
                  value={bank}
                />
              </div>
            </div>

            <div className={styles.FieldGroupDouble}>
              <div className={styles.FieldGroupL}>
                <NumberInputValidation
                  editing={true}
                  name="Account Number "
                  onChange={(event) => setAccountNo(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','accountNo')
                      BusinessDetailsUpdate('debit_order_account_no', accountNo)
                    }
                  }
                  errors={accountNoError}
                  required={true}
                  value={accountNo}
                />
              </div>
            
              <div className={styles.FieldGroupR}>
                <TextInputValidation
                  editing={true}
                  name="Branch: "
                  onChange={(event) => setBranch(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','branch')
                      BusinessDetailsUpdate('debit_order_branch', branch)
                    }
                  }
                  errors={bankBranchError}
                  required={true}
                  value={branch}
                />
              </div>
            </div>
            <div className={styles.FieldGroupDouble}>
              <div className={styles.FieldGroupL}>
                <NumberInputValidation
                  editing={true}
                  name="Branch Code: "
                  onChange={(event) => setBranchCode(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','branchCode')
                      BusinessDetailsUpdate('debit_order_branch_code', branchCode)
                    }
                  }
                  errors={branchCodeError}
                  required={true}
                  value={branchCode}
                />
              </div>
          
              <div className={styles.FieldGroupR}>
                <label className={styles.lineHeight29}>Account Type:</label>
                  <select
                    className={accountTypeError ? 'Error'&&bstyles.width108 : bstyles.width108}
                    value={accountType}
                    name="account Type"
                    required={true}
                    onBlur={() => BusinessDetailsUpdate('debit_order_account_type', accountType)}
                    onChange={(event) => setAccountType(event.target.value)}>
                        <option> </option>
                        <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                        <option key={2} value="Savings">Savings</option>
                        <option key={3} value="transmission">transmission</option>
                </select>
              </div>
            </div>
            <div className={styles.FieldGroupSingle}>
              <label>Date Of Debit Order:</label>
                <select
                  className={dateOfDebitOrderError ? 'Error' : ''}
                  value={dateOfDebitOrder}
                  name="Date of debit order"
                  required={true}
                  onBlur={() => BusinessDetailsUpdate('date_of_debit_order', dateOfDebitOrder)}
                  onChange={(event) => setDateOfDebitOrder(event.target.value)}>
                      <option> </option>
                      <option key={1} value="3">3</option>
                      <option key={2} value="7">7</option>
                      <option key={3} value="15">15</option>
              </select>
            </div>
            </>
            :<></>}
        <>
        { isTokenValid.isTemp ?
        <></>:
        <>
        <div className={styles.TermsWrapper}>
            <h3>Acceptance of Terms of Agreement</h3>
              <p>
              <input type="checkbox"
                className={bstyles.FieldCheckBox}
                name="agreement_checked"
                onChange={() => {
                  setAllowedToSign(!allowedToSign)
                  BusinessDetailsUpdate('allowed_to_sign', !allowedToSign)
                  }
                }
                onBlur={(e:any) => validation(e, 'None', 'allowedToSign')}
                required={allowedToSign}
                checked={allowedToSign}
              /> I confirm that I have the necessary authority to sign this document on behalf of the Licensee.
            </p>
            <span className={bstyles.ErrorMsg}> {allowedToSignErr}</span>
            { allowedToSign ?
            <div>
              <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Signee Information</h2>
              <div className={styles.FieldGroupDouble}>
                <div className={styles.FieldGroupL}>
                  <TextInputValidation
                    editing={false}
                    name="On Behalf of: "
                    onChange={(event) => setSignedOnBehalfOf(event)}
                    onBlur={
                      (e:any) => {
                        validation(e, 'None','signedOnBehaldOf');
                        setSignedOnBehalfOf(businessName);
                      }
                    }
                    errors={signedOnBehalfOfErr}
                    required={true}
                    value={businessName}
                  />
                </div>
                <div className={styles.FieldGroupR}>
                  <DateInputValidation
                    editing={false}
                    name="Date Signed: "
                    onChange={(event) => setDateSigned(event)}
                    onBlur={
                      (e:any) => {
                        validation(e, 'None','dateSigned')
                      }
                    }
                    errors={dateSignedErr}
                    required={true}
                    value={dateSigned}
                  />
                </div>
                </div>
                <div className={styles.FieldGroupDouble}>
                  <div className={styles.FieldGroupL}>
                    <TextInputValidation
                      editing={true}
                      name="Name: "
                      onChange={(event) => {
                        setSignedName(event)
                        BusinessDetailsUpdate('signed_name', event)
                        }
                      }
                      onBlur={
                        (e:any) => {
                          validation(e, 'None','signedName')
                        }
                      }
                      errors={signedNameErr}
                      required={true}
                      value={signedName}
                    />
                  </div>
                  <div className={styles.FieldGroupR}>
                      <TextInputValidation
                        editing={true}
                        name="Surname: "
                        onChange={(event) => {
                          setSignedSurname(event)
                          BusinessDetailsUpdate('signed_surname', event)
                          }
                        }
                        onBlur={
                          (e:any) => {
                            validation(e, 'None','signedSurname')
                          }
                        }
                        errors={signedSurnameErr}
                        required={true}
                        value={signedSurname}
                      />
                  </div>
                </div>
                <div className={styles.FieldGroupDouble}>
                    <div className={styles.FieldGroupL}>
                      <TextInputValidation
                        editing={true}
                        name="Role: "
                        onChange={(event) => {
                          setSignedRole(event)
                          BusinessDetailsUpdate('signed_role', event)
                          }
                        }
                        onBlur={
                          (e:any) => {
                            validation(e, 'None','signedRole')
                          }
                        }
                        errors={signedRoleErr}
                        required={true}
                        value={signedRole}
                      />
                    </div>
                    <div className={styles.FieldGroupR}>
                        <TextInputValidation
                          editing={true}
                          name="Place: "
                          onChange={(event) => {
                            setSignedPlace(event)
                            BusinessDetailsUpdate('signed_place', event)
                            }
                          }
                          onBlur={
                            (e:any) => {
                              validation(e, 'None','signedPlace')
                            }
                          }
                          errors={signedPlaceErr}
                          required={true}
                          value={signedPlace}
                        />
                    </div>
                </div>
                <div className={styles.FieldGroupDouble}>
                    <div className={styles.FieldGroupL}>
                      <TextInputValidation
                        editing={true}
                        name="Cell Number: "
                        onChange={(event) => {
                          setSignedCell(event)
                          BusinessDetailsUpdate('signed_cell', event)
                          }
                        }
                        onBlur={
                          (e:any) => {
                            validation(e, 'None','signedCell')
                          }
                        }
                        errors={signedCellErr}
                        required={true}
                        value={signedCell}
                        maxLength={10}
                      />
                    </div>
                    <div className={styles.FieldGroupR}>
                        <TextInputValidation
                          editing={true}
                          name="Email: "
                          onChange={(event) => {
                            setSignedEmail(event)
                            BusinessDetailsUpdate('signed_email', event)
                            }
                          }
                          onBlur={
                            (e:any) => {
                              validation(e, 'None','signedEmail')
                            }
                          }
                          errors={signedEmailErr}
                          required={true}
                          value={signedEmail}
                        />
                    </div>
                </div>
                <p>
                  <input type="checkbox"
                    className={bstyles.FieldCheckBox}
                    name="agreement_checked"
                    onChange={() => {
                      setAgreementCheck(!agreementCheck)
                      // BusinessDetailsUpdate('accept_terms', !agreementCheck)
                      }
                    }
                    required={agreementCheck}
                    checked={agreementCheck}
                  /> I <span className={styles.boldItalic}><strong>{signedName} {signedSurname}</strong></span>, confirm that the information supplied, is correct.
              
              </p>
            </div>
            :<></>
            }

          <br />
          </div>
        </>
        }
        </>

      { isTokenValid.isTemp ?
        <>
        {isLoading ?
          <button
            className={styles.SubmitBtn}
            onClick={marketerSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
        <div className={styles.FieldGroupSingle}><button
        className={bstyles.SubmitBtns}
        onClick={marketerSubmit}
          >
        Save <span className="material-icons">arrow_forward</span>
      </button></div>
        }</>
        :<>
        {isLoading ?
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
          >
            Accept Quote <span className="material-icons">arrow_forward</span>
          </button>
        }</>
      }
        <ToastContainer />
        </form>
      </div>
      </DesktopLayout>
      
      <MobileLayout>
      <div className={responsive.PageWrapper}>
        <form onSubmit={validateFields}>
          <div className={responsive.OverviewFirstRow}>
            <div className={styles.Heading}>
              <h2>Quote: Part B</h2>
            </div>
          </div>

          <div className={responsive.OverviewPanel}>
            <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Business Details</h2>
            <div >
              <TextInputValidation
                editing={true}
                name="Name of Business/Practice: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'none','businessName')
                    BusinessDetailsUpdate('business_name', businessName)
                  }
                }
                onChange={(event) => setBusinessNames(event)}
                errors={businessNameErr}
                value={businessName}
              />
              <div>
              <TextInputValidation
                editing={true}
                name="Trading As: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'none','tradingAs')
                    BusinessDetailsUpdate('trading_as', tradingAs)
                  }
                }
                onChange={(event) => setTradingAs(event)}
                errors={tradingAsErr}
                value={tradingAs}
              />
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Practice (PCNS) number: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','practiceNr')
                    BusinessDetailsUpdate('practice_nr', practiceNr)
                  }
                }
                onChange={(event) => setPracticeNr(event)}
                errors={practiceNrErr}
                value={practiceNr}
              />
            </div>

            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name="HPCSA number: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','hpcsaNr')
                    BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
                  }
                }
                onChange={(event) => setHpcsaNr(event)}
                errors={hpcsaNrErr}
                value={hpcsaNr}
              />
            </div>
          </div>
              
            </div>
            </div>
            <div className={responsive.OverviewPanel}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Information Officer Details</h2>
          <div>
            <TextInputValidation
              editing={true}
              name="Information Officers Name (POPI Act - Contracts): *"
              onBlur={
                (e:any) => {
                  validation(e, 'None','creditControlName')
                  BusinessDetailsUpdate('credit_control_name', creditControlName)
                }
              }
              onChange={(event) => setCreditControlName(event)}
              errors={creditControlNameErr}
              value={creditControlName}
            />
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name="Cell number of Information Officer: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','IOCellNo')
                    BusinessDetailsUpdate('io_cell_nr', IOCellNo)
                  }
                }
                onChange={(event:any) => setIOCellNo(event)}
                errors={IOCellNoErr}
                value={String(IOCellNo)}
                maxLength={10}
              />  
            </div>
            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name="Information Officers email address: *"
                onBlur={
                  (e:any) => {
                    validation(e, 'None','creditControlEmail')
                    BusinessDetailsUpdate('credit_control_email', creditControlEmail)
                  }
                }
                onChange={(event) => setCreditControlEmail(event)}
                errors={creditControlEmailErr}
                value={creditControlEmail}
              />
            </div>
          </div>
        </div>
        {debitorderBool === true ?
          <div className={responsive.OverviewPanel}>
          <>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Banking Details</h2>
          <div className={responsive.OverviewRow}>
            <TextInputValidation
              editing={true}
              name="Account Name "
              onChange={(event) => setAccountName(event)}
              onBlur={
                (e:any) => {
                  validation(e, 'None','accountName')
                  BusinessDetailsUpdate('debit_order_account_name', accountName)
                }
              }
              errors={accountNameError}
              required={true}
              value={accountName}
            />
            
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Bank: "
                  onChange={(event) => setBank(event)}
                  onBlur={
                    (e:any) => { 
                      validation(e, 'None','bank')
                      BusinessDetailsUpdate('debit_order_bank', bank)
                    }
                  }
                  errors={bankError}
                  required={true}
                  value={bank}
                />
              </div>

              <div className={responsive.FieldLayoutEndGrid}>
                <NumberInputValidation
                  editing={true}
                  name="Account Number "
                  onChange={(event) => setAccountNo(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','accountNo')
                      BusinessDetailsUpdate('debit_order_account_no', accountNo)
                    }
                  }
                  errors={accountNoError}
                  required={true}
                  value={accountNo}
                />
              </div>
            </div>
            
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Branch: "
                  onChange={(event) => setBranch(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','branch')
                      BusinessDetailsUpdate('debit_order_branch', branch)
                    }
                  }
                  errors={bankBranchError}
                  required={true}
                  value={branch}
                />
              </div>
              <div className={responsive.FieldLayoutEndGrid}>
                <NumberInputValidation
                  editing={true}
                  name="Branch Code: "
                  onChange={(event) => setBranchCode(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','branchCode')
                      BusinessDetailsUpdate('debit_order_branch_code', branchCode)
                    }
                  }
                  errors={branchCodeError}
                  required={true}
                  value={branchCode}
                />
              </div>
            </div>
            <div>
              <div className={responsive.FieldAccountType}>
                <label>Account Type:</label>
                    <select
                    className={accountTypeError ? 'Error' : ''}
                    value={accountType}
                    name="account Type"
                    required={true}
                    onBlur={() => BusinessDetailsUpdate('debit_order_account_type', accountType)}
                    onChange={(event) => setAccountType(event.target.value)}>
                        <option> </option>
                        <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                        <option key={2} value="Savings">Savings</option>
                        <option key={3} value="transmission">transmission</option>
                </select>
              </div>
              <div className={responsive.FieldDebitOrderDate}>
                <label>Date Of Debit Order:</label>
                    <select
                    className={dateOfDebitOrderError ? 'Error' : ''}
                    value={dateOfDebitOrder}
                    name="Date of debit order"
                    required={true}
                    onBlur={() => BusinessDetailsUpdate('date_of_debit_order', dateOfDebitOrder)}
                    onChange={(event) => setDateOfDebitOrder(event.target.value)}>
                        <option> </option>
                        <option key={1} value="3">3</option>
                        <option key={2} value="7">7</option>
                        <option key={3} value="15">15</option>
                </select>
              </div>
            </div>
            </div>
          </>
          </div>
        : null
        }
        <div className={responsive.OverviewPanel}>
          <h3 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Acceptance of Terms of Agreement</h3>
            <p>
            <input type="checkbox"
              className={bstyles.FieldCheckBox}
              name="agreement_checked"
              onChange={() => {
                setAllowedToSign(!allowedToSign)
                BusinessDetailsUpdate('allowed_to_sign', !allowedToSign)
                }
              }
              onBlur={(e:any) => validation(e, 'None', 'allowedToSign')}
              required={allowedToSign}
              checked={allowedToSign}
            /> I confirm that I have the necessary authority to sign this document on behalf of the Licensee.
          </p>
          <span className={bstyles.ErrorMsg}> {allowedToSignErr}</span>
          { allowedToSign ?
          <div>
            <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Signee Information</h2>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={false}
                  name="On Behalf of: "
                  onChange={(event) => setSignedOnBehalfOf(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedOnBehaldOf');
                      setSignedOnBehalfOf(businessName);
                    }
                  }
                  errors={signedOnBehalfOfErr}
                  required={true}
                  value={businessName}
                />
              </div>
              <div className={responsive.FieldLayoutGrid}>
                <DateInputValidation
                  editing={false}
                  name="Date Signed: "
                  onChange={(event) => setDateSigned(event)}
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','dateSigned')
                    }
                  }
                  errors={dateSignedErr}
                  required={true}
                  value={dateSigned}
                />
              </div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Name: "
                  onChange={(event) => {
                    setSignedName(event)
                    BusinessDetailsUpdate('signed_name', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedName')
                    }
                  }
                  errors={signedNameErr}
                  required={true}
                  value={signedName}
                />
              </div>
              <div className={styles.FieldLayoutGrid}>
                  <TextInputValidation
                    editing={true}
                    name="Surname: "
                    onChange={(event) => {
                      setSignedSurname(event)
                      BusinessDetailsUpdate('signed_surname', event)
                      }
                    }
                    onBlur={
                      (e:any) => {
                        validation(e, 'None','signedSurname')
                      }
                    }
                    errors={signedSurnameErr}
                    required={true}
                    value={signedSurname}
                  />
              </div>
              <div className={styles.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Role: "
                  onChange={(event) => {
                    setSignedRole(event)
                    BusinessDetailsUpdate('signed_role', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedRole')
                    }
                  }
                  errors={signedRoleErr}
                  required={true}
                  value={signedRole}
                />
              </div>
              <div className={styles.FieldLayoutGrid}>
                  <TextInputValidation
                    editing={true}
                    name="Place: "
                    onChange={(event) => {
                      setSignedPlace(event)
                      BusinessDetailsUpdate('signed_place', event)
                      }
                    }
                    onBlur={
                      (e:any) => {
                        validation(e, 'None','signedPlace')
                      }
                    }
                    errors={signedPlaceErr}
                    required={true}
                    value={signedPlace}
                  />
              </div>              
              <div className={styles.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name="Cell Number: "
                  onChange={(event) => {
                    setSignedCell(event)
                    BusinessDetailsUpdate('signed_cell', event)
                    }
                  }
                  onBlur={
                    (e:any) => {
                      validation(e, 'None','signedCell')
                    }
                  }
                  errors={signedCellErr}
                  required={true}
                  value={signedCell}
                  maxLength={10}
                />
              </div>
              <div className={styles.FieldLayoutGrid}>
                  <TextInputValidation
                    editing={true}
                    name="Email: "
                    onChange={(event) => {
                      setSignedEmail(event)
                      BusinessDetailsUpdate('signed_email', event)
                      }
                    }
                    onBlur={
                      (e:any) => {
                        validation(e, 'None','signedEmail')
                      }
                    }
                    errors={signedEmailErr}
                    required={true}
                    value={signedEmail}
                  />
              </div>
              <p>
                <input type="checkbox"
                  className={bstyles.FieldCheckBox}
                  name="agreement_checked"
                  onChange={() => {
                    setAgreementCheck(!agreementCheck)
                    // BusinessDetailsUpdate('accept_terms', !agreementCheck)
                    }
                  }
                  required={agreementCheck}
                  checked={agreementCheck}
                /> I <text className={styles.boldItalic}><strong>{signedName} {signedSurname}</strong></text>, confirm that the information supplied, is correct.
            </p>
          </div>
          :<></>
          }
        </div>
        { isTokenValid.isTemp ?
        <>
        {isLoading ?
          <button
            className={responsive.SubmitButton}
            onClick={marketerSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : <button
          className={responsive.SubmitButton}
          onClick={marketerSubmit}
            >
          Save <span className="material-icons">arrow_forward</span>
        </button>
        }
        </>
        :
        <>
        {isLoading ?
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
          >
            Accept Quote <span className="material-icons">arrow_forward</span>
          </button>
        }
        </>
      }
        <ToastContainer />
        </form>
      </div>
      </MobileLayout>
      </>
      :
      <div className={styles.FormWrapper}>
        <div className={styles.QuoteCompleteCard}>
            <label className={styles.QuoteCompleteCardHeading}>Quote # {businessValues.quote_id}</label><br /><br />
            <label className={styles.QuoteCompleteCardHeading}>Your quotation has already been accepted.</label>
            <br/>
        </div>
      </div>
    }
    </>
  
  )
};

export default AcceptQuote;
