import { get } from '../../../utils/api';

export type IDType = {
  instanceID: string | number[];
};

const call = async (
  func: string,
  instanceID: string | number | number[],
  sure: Boolean = true
) => {
  if (sure) {
    const result = !window.confirm(
      `Are you sure you want to call ${func} on ${instanceID}`
    );
    if (result) {
      return;
    }
  }

  switch (typeof instanceID) {
    case 'string':
    case 'number':
      await get(`/func/${func}/${instanceID}`);
      break;
    default:
      instanceID.forEach(async (id) => await call(func, id, false));
  }
};

export default call;
