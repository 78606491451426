import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React, { useState } from 'react';

import DateTime from '../../utils/DateTime';
import TermsAndConditions from '../Quotation/TermsAndConditions';
import { ContractTemplatePropTypes, contractTypes } from './ContractTypes';

interface PropsTemplate {
  props?: contractTypes;
  line_detail?: ContractTemplatePropTypes[];
  headerImages?: string;
}

const ContractTemplate: React.FC<PropsTemplate> = (props) => {
  const [contract] = useState(props?.props);
  const [contractLines] = useState(props?.line_detail);
  const [headerImage] = useState(props?.headerImages)

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
    },
    pageNumber: {
      textAlign: 'right',
      fontSize: 12,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    heading: {
      fontSize: 10,
      textAlign: 'left',
      marginTop: 10,
      marginLeft: 10,
      paddingBottom: 50,
    },
    headingDate: {
      textAlign: 'right',
    },
    headingSection: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 14,
      top: 40,
    },
    headingSection2: {
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: 16,
      top: -60,
      marginLeft: 10,
    },
    QlinesSummary: {
      top: 360,
      right: 530,
      fontSize: 12,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    QLinesDetail: {
      right: 400,
      top: 180,
      fontSize: 12,
      textAlign: 'left',
      flex: 1,
    },
    QLinesDetail1: {
      right: 400,
      top: 180,
      fontSize: 12,
      textAlign: 'left',
    },
    viewer: {
      width: '100%',
      height: '100%',
    },
    logo: {
      width: 100,
      position: 'absolute',
      right: '4%',
    },
    body: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingHorizontal: 20,
    },
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCol1: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderBottomWidth: 1,
      top: -60,
    },
    tableCol2: {
      width: '20%',
      borderStyle: 'solid',
      borderLeftWidth: 1,
      borderTopWidth: 1,
      borderRightWidth: 0.3,
      borderBottomWidth: 0.2,
      top: 60,
      right: 160,
    },
    QLinesCol2: {
      width: '62.8%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontSize: 10,
      marginLeft: 5,
    },
    QLinesCol3: {
      width: '18%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontSize: 10,
    },
    QLinesCol4: {
      width: '8%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontSize: 10,
      marginTop: 5,
    },
    QLinesCell2: {
      width: '62.8%',
      margin: 'auto',
      marginTop: 5,
      fontSize: 10,
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#CCCCCC',
      color: '#000000'
    },
    QLinesCell3: {
      width: '18%',
      margin: 'auto',
      marginTop: 5,
      fontSize: 10,
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#CCCCCC',
      color: '#000000',
    },
    QLinesCell4: {
      width: '8%',
      margin: 'auto',
      marginTop: 5,
      fontSize: 10,
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#CCCCCC',
      color: '#000000',
    },
    tableCol2Extra: {
      width: '30%',
      borderStyle: 'solid',
      borderTopWidth: 1,
      borderRightWidth: 0.3,
      borderLeftWidth: 0.3,
      borderBottomWidth: 1,
      top: -40,
      left: 170,
      fontSize: 10,
      textAlign: 'center',
      // right: '400%',
    },
    tableCol3: {
      width: '33.33%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCol4: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableHead: {
      width: '14%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#CCCCCC',
      color: '#000000',
    },
    tableCell: {
      margin: 'auto',
      marginTop: 5,
      fontSize: 10,
    },
    tableCol2Row: {
      marginTop: 5,
      fontSize: 10,
      textAlign: 'right',
    },
    tableCol2Cell: {
      width: 'auto',
      marginTop: 5,
      fontSize: 10,
      textAlign: 'left',
    },
    tableCellGroup: {
      marginTop: 5,
      marginLeft: 2,
      fontSize: 14,
      textAlign: 'left',
      textDecoration: 'underline',
    },
    tableColSplit: {
      width: '100%',
      paddingTop: 5,
      paddingBottom: 5,
      borderLeft: 0,
    },
    tableCell2: {
      marginLeft: 1,
      marginTop: 5,
      fontSize: 18,
      textAlign: 'left',
      fontWeight: 100,
    },
    tableColAmount: {
      width: '100%',
      marginBottom: 5,
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      // borderTopWidth: 1,
      fontWeight: "bold",
    },
    hr: {
      width: '80%',
      height: '2px',
      backgroundColor: '#666',
      border: 0,
      top: 58,
      left: '10%',
    }
  });

  function toFixed(num:any, fixed:any) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  };

  const template = (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.table}>
          <View style={styles.heading} fixed>
            <Text>E-mail: legal@goodx.co.za</Text>
            <Text>Tel: 010 286 0802</Text>
            <Text>Fax: 086 459 0549</Text>
            <Image style={styles.logo} src={`${headerImage}`}></Image>
            <Text style={styles.headingSection}>GoodX Software Standard Licence, Services & Operator Agreement between:</Text>
            <Text style={styles.headingSection}>{contract?.company_type}</Text>
            <Text style={styles.headingSection}>(hereinafter referred to as "GoodX")</Text>
            <Text style={styles.headingSection}>And</Text>
            <Text style={styles.hr}></Text>
            <Text style={styles.headingSection}>{contract?.debtor_name}</Text>
            <Text style={styles.headingSection}>(hereinafter referred to as "the Client")</Text>
            <Text>{' '}</Text>
            <Text style={styles.headingSection}>(hereinafter referred to as "the Parties")</Text>

            <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Row}>Contract to: {' '}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Cell}>{' '} {contract?.debtor_name}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Row}>Attention: {' '}</Text>
            </View>
            <View style={styles.tableCol2}>
              {/* <Text style={styles.tableCol2Cell}>{' '} {contractLinesttt}</Text> */}
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Row}>Cell: {' '}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Cell}>{' '} </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Row}>Tel: {' '}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Cell}>{' '} {contract?.tel_no}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Row}>E-mail: {' '}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCol2Cell}>{contract?.email}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol2Extra}>
              <Text >Date: {DateTime.curTime}</Text>
            </View>
          </View>
          <Text>{'\n'}</Text>
          <Text>{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}</Text>
          </View>
          <Text>{'\n'}{'\n'}{'\n'}</Text>
          <Text style={styles.headingSection2} fixed>Part A</Text>

          {/* Outstanding line items and detail */}
          {contractLines?.map((groupitem:any) => (
            <View style={styles.tableRow} key={groupitem.id}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCellGroup} fixed>{groupitem.item_type_description}</Text>
                <View style={styles.tableRow} fixed>
                    <Text style={styles.QLinesCell2}>{' '} {' '} Description</Text>
                    <Text style={styles.QLinesCell3}>Item Code</Text>
                    <Text style={styles.QLinesCell3}>{' '} {' '} {' '} {' '} Amount</Text>
                </View>
               {groupitem.line_detail.map((item:any) => (
                <View style={styles.tableRow} key={item.billable_price.uniq_id}>
                    <Text style={styles.QLinesCol2}>
                      {item.billable_price.item_description}
                    </Text>
                    <Text style={styles.QLinesCol3}>
                      {item.billable_price.item_code}
                    </Text>
                    <Text style={styles.QLinesCol3}>
                      {' '} {' '} {' '} {' '} {toFixed(item.billable_price.amount,2)}
                    </Text>
                </View>
                ))}
                <Text>{'\n'}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
      <Page style={styles.body}>
        <TermsAndConditions />
      </Page>
    </Document>
  );
  return template;
};

export default ContractTemplate;
