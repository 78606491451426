import { History } from 'history';
import React, { useState } from 'react';

import { InstanceListItem } from '../InstanceListItem';
import styles from './InstancesTable.module.scss';
import { Sort } from './sortColumns';
import InstanceListTableBody from './TableBody';
import InstanceListTableHead from './TableHeader';

const InstancesTable = ({
  filter,
  history,
  instanceList,
  selectedIDs,
  setSelectedIDs,
}: {
  filter?: RegExp[];
  history: History<any>;
  instanceList: InstanceListItem[];
  selectedIDs: number[];
  setSelectedIDs: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const [sort, setSort] = useState([] as Sort[]);

  return (
    <table className={styles.InstancesTable}>
      <InstanceListTableHead
        {...{ filter, instanceList, selectedIDs, setSelectedIDs, setSort }}
      />
      <InstanceListTableBody
        {...{
          filter,
          history,
          instanceList,
          selectedIDs,
          setSelectedIDs,
          sort,
        }}
      />
    </table>
  );
};

export default InstancesTable;
