import Many2OneType from '../../components/EditableInputs/Many2OneInput/Many2OneTypes';

export enum DB_SERVER_ACTIONS {
  DATABASE_SERVER,
  DB_SERVER_DB_PASS,
  DB_SERVER_DB_USER,
  DB_SERVER_HOSTING_LOCATION,
  DB_SERVER_HOSTNAME,
  DB_SERVER_IP,
  DB_SERVER_NAGIOS,
  DB_SERVER_PORT,
  EDITING,
  HOSTING_LOCATIONS,
}

export type DBServerType = {
  db_pass: string;
  db_user: string;
  hosting_location: Many2OneType;
  hostname: string;
  ip: string;
  nagios_check: boolean;
  port: number;
};

export type DBServerActions =
  | { type: DB_SERVER_ACTIONS.DATABASE_SERVER; value?: DBServerType }
  | { type: DB_SERVER_ACTIONS.DB_SERVER_DB_PASS; value: string }
  | { type: DB_SERVER_ACTIONS.DB_SERVER_DB_USER; value: string }
  | {
      type: DB_SERVER_ACTIONS.DB_SERVER_HOSTING_LOCATION;
      value?: Many2OneType;
    }
  | { type: DB_SERVER_ACTIONS.DB_SERVER_HOSTNAME; value: string }
  | { type: DB_SERVER_ACTIONS.DB_SERVER_IP; value: string }
  | { type: DB_SERVER_ACTIONS.DB_SERVER_NAGIOS; value: boolean }
  | { type: DB_SERVER_ACTIONS.DB_SERVER_PORT; value: number }
  | { type: DB_SERVER_ACTIONS.EDITING; value: boolean }
  | { type: DB_SERVER_ACTIONS.HOSTING_LOCATIONS; value?: Many2OneType[] };

type DBServerStateType = {
  editing: boolean;
  dbServer?: DBServerType;
  hostingLocations?: Many2OneType[];
};

export const DBServerInitialState = {
  editing: false,
  dbServer: undefined,
  hostingLocations: undefined,
};

const reducer = (
  state: DBServerStateType,
  action: DBServerActions
): DBServerStateType => {
  switch (action.type) {
    case DB_SERVER_ACTIONS.DATABASE_SERVER:
      return {
        ...state,
        dbServer: action.value,
      };
    case DB_SERVER_ACTIONS.DB_SERVER_DB_PASS:
      return {
        ...state,
        dbServer: {
          ...state.dbServer,
          db_pass: action.value,
        } as DBServerType,
      };
    case DB_SERVER_ACTIONS.DB_SERVER_DB_USER:
      return {
        ...state,
        dbServer: {
          ...state.dbServer,
          db_user: action.value,
        } as DBServerType,
      };
    case DB_SERVER_ACTIONS.DB_SERVER_HOSTING_LOCATION:
      return {
        ...state,
        dbServer: {
          ...state.dbServer,
          hosting_location: action.value,
        } as DBServerType,
      };
    case DB_SERVER_ACTIONS.DB_SERVER_HOSTNAME:
      return {
        ...state,
        dbServer: {
          ...state.dbServer,
          hostname: action.value,
        } as DBServerType,
      };
    case DB_SERVER_ACTIONS.DB_SERVER_IP:
      return {
        ...state,
        dbServer: {
          ...state.dbServer,
          ip: action.value,
        } as DBServerType,
      };
    case DB_SERVER_ACTIONS.DB_SERVER_NAGIOS:
      return {
        ...state,
        dbServer: {
          ...state.dbServer,
          nagios_check: action.value,
        } as DBServerType,
      };
    case DB_SERVER_ACTIONS.DB_SERVER_PORT:
      return {
        ...state,
        dbServer: {
          ...state.dbServer,
          port: action.value,
        } as DBServerType,
      };
    case DB_SERVER_ACTIONS.EDITING:
      return {
        ...state,
        editing: action.value,
      };
    case DB_SERVER_ACTIONS.HOSTING_LOCATIONS:
      return {
        ...state,
        hostingLocations: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
