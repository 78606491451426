import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './employee_contracts.module.scss';
import { EmploymentDetails } from './EmployeeContractDetail';
import { post, get } from '../../utils/api';
import { useParams } from 'react-router-dom';

export type DepartmentArray = {
    departments: DepartmentData[];
};

export type DepartmentData = {
    id: number;
    name: string;
};

interface ISixChildProps {
    updateEmploymentDetail: (arg: any) => void;
    currentContract: EmploymentDetails;
}


const EmployeeEmploymentDetails: React.FC<ISixChildProps> = ({ updateEmploymentDetail, currentContract }) => {
    const { jwt_token }: any = useParams();
    const [departments, setDepartments] = useState([] as DepartmentArray[]);
    const [selectDepartment, setSelectedDepartment] = useState('');
    const [companyAppointmentDate, setCompanyAppointmentDate] = useState('');
    const [goodxGroupAppointmentDate, setGoodxGroupAppointmentDate] = useState('');
    const [workHoursStart, setWorkHoursStart] = useState('');
    const [workHoursEnd, setWorkHoursEnd] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [employmentDetailsComplete,setEmploymentDetailsComplete] = useState(true);
    const [errorCompanyAppointment, setErrorCompanyAppointment] = useState('')
    const [errorCompanyGroupAppointment, setErrorCompanyGroupAppointment] = useState('')
    const [errorWorkHoursStart, setErrorWorkHoursStart] = useState('')
    const [errorWorkHoursStop, setErrorWorkHoursStop] = useState('')
    const [error, setError] = useState(false)
    const [employmentDetails, setEmploymentDetails] = useState({
        'selectDepartment': selectDepartment,
        'companyAppointmentDate': companyAppointmentDate,
        'goodxGroupAppointmentDate': goodxGroupAppointmentDate,
        'workHoursStart': workHoursStart,
        'workHoursEnd': workHoursEnd,
        'employmentDetailsComplete':employmentDetailsComplete,
    } as EmploymentDetails);

    const fetchFromDb = async (url: any, setFn: any) => {
        const resp = await get(url);
        if (resp.ok) {
            setFn(await resp.json());
        }
        return resp.ok;
    };

    const handleChange = (e: any) => {
        setError(false);
        const { name, value } = e.target;
        setEmploymentDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const formValidate = async (e: { preventDefault: () => void }) => {
        if (employmentDetails.companyAppointmentDate === '' || employmentDetails.companyAppointmentDate === null) {
            setErrorCompanyAppointment('Please set Company Appointment Date');
            setError(true);
        } else {
            setErrorCompanyAppointment('');
        }
        if (employmentDetails.goodxGroupAppointmentDate === '' || employmentDetails.goodxGroupAppointmentDate === null) {
            setErrorCompanyGroupAppointment('Please set Goodx Group Appointment Date');
            setError(true);
        } else {
            setErrorCompanyGroupAppointment('');
        }
        if (employmentDetails.workHoursStart === '' || employmentDetails.workHoursStart === null) {
            setErrorWorkHoursStart('Please set starting working hours');
            setError(true);
        } else {
            setErrorWorkHoursStart('');
        }
        if (employmentDetails.workHoursEnd === '' || employmentDetails.workHoursEnd === null) {
            setErrorWorkHoursStop('Please set Goodx Group Appointment Date');
            setError(true);
        } else {
            setErrorWorkHoursStop('');
        }
        if (error === true) {
            e.preventDefault();
        }
      };

    const save_employment_detail = async (e: any) => {
        if (employmentDetails.companyAppointmentDate === '' || employmentDetails.companyAppointmentDate === null) {
            setError(true);
        }else if (employmentDetails.goodxGroupAppointmentDate === '' || employmentDetails.goodxGroupAppointmentDate === null) {
            setError(true);
        }else if (employmentDetails.workHoursStart === '' || employmentDetails.workHoursStart === null) {
            setError(true);
        }else if (employmentDetails.workHoursEnd === '' || employmentDetails.workHoursEnd === null) {
            setError(true);
        }else if (error === false) {
            setEmploymentDetailsComplete(true);
            const resp = await post(`/save_employee/save_employment_detail/${jwt_token}`, {
                'employmentDetails':employmentDetails,
                'employmentDetailsComplete':employmentDetailsComplete
            });
            if (resp.ok) {
                return resp.ok;
            }
        }
        
    };

    const loadCurrentContract = () => {
        if (currentContract) {
            setEmploymentDetails(currentContract)
        }
    }

    useEffect(() => {
        setTimeout(function () {
            if (loaded === false) {
                loadCurrentContract();
                setLoaded(true);
            }
        }, 1000);
    },);

    useEffect(() => {
        updateEmploymentDetail(employmentDetails);
    },);

    useEffect(() => {
        fetchFromDb('/get_departments', setDepartments);
    }, []);

    return (
        <form onSubmit={formValidate}>
            <div className={styles.FormWrapper}>
                <div className={styles.form}>
                    <div className={styles.column}>
                        <div>
                            <label>Company Appointment Date:</label>
                            <input
                                className={styles.input}
                                type="date"
                                name="companyAppointmentDate"
                                value={employmentDetails.companyAppointmentDate || ''}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorCompanyAppointment}</span>
                        </div>
                        <br />
                        <div>
                            <label>Goodx Group Appointment Date:</label>
                            <input
                                className={styles.input}
                                type="date"
                                name="goodxGroupAppointmentDate"
                                value={employmentDetails.goodxGroupAppointmentDate || ''}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorCompanyGroupAppointment}</span>
                        </div>
                        <br />
                        <div>
                            <label>Work Hours Start:</label>
                            <input
                                className={styles.input}
                                type="time"
                                name="workHoursStart"
                                value={employmentDetails.workHoursStart || ''}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorWorkHoursStart}</span>
                        </div>
                        <br />
                        <div>
                            <label>Work Hours End:</label>
                            <input
                                className={styles.input}
                                type="time"
                                name="workHoursEnd"
                                value={employmentDetails.workHoursEnd || ''}
                                onChange={(handleChange)}
                            />
                            <span className={styles.errormsg}>{errorWorkHoursStop}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.btn_footer}>
                        <button className={styles.save_button} onClick={save_employment_detail}>Complete</button>
                    </div>
                </div>
            </div>
        </form>
    )

}

export default EmployeeEmploymentDetails; 
