import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { get } from '../../utils/api';
import ActionButtons from '../Instance/Buttons/ActionButtons';
import ComboButtons from '../Instance/Buttons/ComboButtons';
import DatabaseButtons from '../Instance/Buttons/DatabaseButtons';
import WSClientButtons from '../Instance/Buttons/WSClientButtons';
import ActiveTypeToggles from './ActiveTypeToggles/ActiveTypeToggles';
import FilterInput from './FilterInput/FilterInput';
import { ActiveType, InstanceListItem } from './InstanceListItem';
import InstancesTable from './InstancesTable/InstancesTable';

const fetchInstanceList = async (
  setInstanceList: React.Dispatch<React.SetStateAction<InstanceListItem[]>>,
  activeTypes: ActiveType[]
) => {
  const resp = await get(`/instance?active_types=${activeTypes.join(',')}`);

  if (resp.ok) {
    setInstanceList(await resp.json());
  } else {
    console.error('Fetching Instance List failed');
  }
};

const InstanceList: React.FC = () => {
  const history = useHistory();
  const [instanceList, setInstanceList] = useState([] as InstanceListItem[]);
  const [selectedIDs, setSelectedIDs] = useState([] as number[]);
  const [filterInput, setFilter] = useState('');
  const [activeTypeFilter, setActiveTypeFilter] = useState([
    'active',
    'testing',
  ] as ActiveType[]);

  const filter =
    filterInput === ''
      ? undefined
      : filterInput.split(' ').map((f) => new RegExp(f, 'i'));

  useEffect(() => {
    fetchInstanceList(setInstanceList, activeTypeFilter);
  }, [activeTypeFilter]);

  return (
    <div>
      <h1>Instance List</h1>

      <FilterInput {...{ filterInput, setFilter }} />
      <div>
        {selectedIDs.length > 0 ? (
          <>
            <ComboButtons instanceID={selectedIDs} />
            <DatabaseButtons instanceID={selectedIDs} />
            <ActionButtons instanceID={selectedIDs} />
            <WSClientButtons instanceID={selectedIDs} />
          </>
        ) : null}
        <ActiveTypeToggles {...{ activeTypeFilter, setActiveTypeFilter }} />
      </div>

      <InstancesTable
        {...{
          filter,
          history,
          instanceList,
          selectedIDs,
          setSelectedIDs,
        }}
      />
    </div>
  );
};

export default InstanceList;
