import 'react-toastify/dist/ReactToastify.css';
import styles from './Contracts.module.scss';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { fetchContractsApi, InvalidContract, LoadingContract } from './ContractsAPI';
import { post } from '../../utils/api';
import OTPInput from '../../components/EditableInputs/OTPInput';
import CurrentDateTime from '../../utils/CurrentDateTime';
import AcceptTemplate from './AcceptTemplate';
import { PDFViewer, pdf, StyleSheet } from '@react-pdf/renderer';
import { ContractTemplatePropTypes, contractTypes } from './ContractTypes';
import ContractTemplate from './ContractTemplate';

const viewer = StyleSheet.create({
  page: {
    position: 'absolute',
    height: "91%",
    width: "50%",
    right: 10,
    top: 70,
  },
});

export type GetHeaderImage = {
  header: string;
  footer: string;
};

const Contracts: React.FC = () => {
  const { id }: any = useParams();
  const { debtorID }: any = useParams();
  const [contract, setContract] = useState({} as contractTypes);
  const [contractLines, setContractLines] = useState({} as ContractTemplatePropTypes[]);
  const [contractLoaded, setContractLoaded] = useState(false);
  const [contractLinesLoaded, setContractLinesLoaded] = useState(false);
  const [errorName, setErrorName] = useState('');
  const [errorSurname, setErrorSurname] = useState('');
  const [errorIdno, setErrorIdno] = useState('');
  const [errorRole, setErrorRole] = useState('');
  const [errorCellNo, setErrorCellNo] = useState('');
  const [errorTime, setErrorTime] = useState('');
  const [errorPlace, setErrorPlace] = useState('');
  const [errorOtp, setErrorOtp] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [signedName, SetSignedName] = useState('');
  const [signedSurname, setSignedSurname] = useState('');
  const [signedId, setSignedId] = useState('');
  const [signedRole, setSignedRole] = useState('');
  const [signedCellNo, setSignedCellNo] = useState('');
  const [signedOtp, setSignedOtp] = useState('');
  const [message, setMessage] = useState('');
  const [signedTime, setSignedTime] = useState(new Date().toISOString());
  const [signedPlace, setSignedPlace] = useState('');
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [agreementContact, setAgreementContact] = useState('');
  const [acceptContactErr, setAcceptContactErr] = useState('');
  const [headerImage, setHeaderImage] = useState({} as GetHeaderImage);
  const [isHeaderImageLoaded, setHeaderImageLoaded] = useState(false);

  const sendOTP = async () => {
    const resp = await post('/contracts/set_otp', {
      signedCellNo: signedCellNo,
      uuid: contract.uuid
    });

    if (resp.ok) {
      setDisabled(false);
      setErrorOtp('OTP Sent to your cellphone number');
    } else {
      setErrorOtp('Something went wrong. Try to request a OTP again.');
      setDisabled(true);
    }
    return resp.ok;
  };

  const contractAgreementUpdate = async (
    contract?: contractTypes,
    signedName?: string,
    signedSurname?: string,
    signedId?: string,
    signedRole?: string,
    signedCellNo?: string,
    signedOtp?: string,
    signedTime?: string,
    signedPlace?: string,
  ) => {
    if (contract === undefined) {
      return undefined;
    }
    const resp = await post('/contracts/contract_complete', {
      contract_id: contract.id,
      uuid: contract.uuid,
      html_text: contract.html_text,
      signedName: signedName,
      signedSurname: signedSurname,
      signedId: signedId,
      signedRole: signedRole,
      signedCellNo: signedCellNo,
      signedOtp: signedOtp,
      signedTime: signedTime,
      signedPlace: signedPlace,
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
    } else {
      toast.error('We unable to submit your request due to server error.');
    }
  };

  const formValidate = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (signedName === '') {
      setErrorName('Please enter your name');
    } else {
      setErrorName('');
    }
    if (signedSurname === '') {
      setErrorSurname('Please enter your surname');
    } else {
      setErrorSurname('');
    }
    if (signedId.length < 13) {
      setErrorIdno('ID number must be 13 characters long');
    } else {
      setErrorIdno('');
    }
    if (signedCellNo === '') {
      setErrorCellNo('Please enter your Cell No');
    } else {
      setErrorCellNo('');
    }
    if (signedRole === '') {
      setErrorRole('Please enter your role');
    } else {
      setErrorRole('');
    }
    if (signedOtp === '') {
      setErrorOtp('Please enter a OTP Pin');
    } else {
      setErrorOtp('');
    }
    if (signedTime === '') {
      setErrorTime('Please select a date');
    } else {
      setErrorTime('');
    }
    if (signedPlace === '') {
      setErrorPlace('Please enter a place');
    } else {
      setErrorPlace('');
    }
    if (agreementContact === '') {
      setAcceptContactErr('Please enter your full name');
    } else {
      setAcceptContactErr('');
    }
  };

  const contractSubmit = async() => {
    let otpValid = await otpValidate(contract, signedOtp);

    if (otpValid && agreementCheck) {
      if (
        signedName &&
        signedSurname &&
        signedCellNo &&
        signedId.length > 12 &&
        signedRole &&
        signedTime &&
        signedPlace &&
        signedOtp &&
        agreementContact !== ''
      ) {
        contractAgreementUpdate(
          contract,
          signedName,
          signedSurname,
          signedId,
          signedRole,
          signedCellNo,
          signedOtp,
          signedTime,
          signedPlace,
        );
        setMessage('Your response has been succesfully sent.');
        downloadPDFDocs();
      }
    }
  };

  const otpValidate = async (
    contract?: contractTypes,
    signedOtp?: string,
  ) => {
    const resp = await post('/contracts/validate_otp', {
      signedOtp: signedOtp,
      uuid: contract?.uuid
    });

    if (!resp.ok) {
      setErrorOtp('Something went wrong. Please send OTP again.');
      return false;
    }

    const json = await resp.json();
    if (!json.exists) {
      setErrorOtp('The OTP you entered is invalid');
    }
    else {
      setErrorOtp('');
    }

    return json.exists;
  };

  const downloadPDFAgreement = async () => {
    let blob = await pdf(
      <AcceptTemplate
        agreementName={signedName}
        agreementSurname={signedSurname}
        agreementID={signedId}
        agreementRole={signedRole}
        agreementCell={signedCellNo}
        agreementPlace={signedPlace}
        agreementContact={agreementContact}
        agreementAddress={contract.signed_address}
        headerImage={headerImage.header}
      />
    ).toBlob();
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = async () => {
      let res = reader.result;
      const resp = await post('/contracts/contract_save_pdf_agreement', {
        uuid: contract?.uuid,
        signed_agreement: res
      });

      if (!resp.ok) {
        setMessage('Unexpected error occured. Please try saving the contract again or contact support for assistance.');
      }
      else {
        setMessage('');
      }
    };
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = 'signed_agreement_' + contract.uuid + '.pdf';
    link.click();
  };

  const downloadPDFContract = async () => {
    let blob = await pdf(
      <ContractTemplate props={contract}  line_detail={contractLines} headerImages={headerImage.header}/>
    ).toBlob();
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = async () => {
      let res = reader.result;
      const resp = await post('/contracts/contract_save_pdf_contract', {
        uuid: contract?.uuid,
        signed_contract: res
      });

      if (!resp.ok) {
        setMessage('Unexpected error occured. Please try saving the contract again or contact support for assistance.');
      }
      else {
        setMessage('');
      }
    };
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = 'signed_contract_' + contract.uuid + '.pdf';
    link.click();
  };

  const downloadPDFDocs = async () => {
    await downloadPDFContract();
    await downloadPDFAgreement();
  };

  useEffect(() => {
    fetchContractsApi(`/contracts/${id}/${debtorID}`, setContract, setContractLoaded);
    fetchContractsApi(`/contract/${id}/${debtorID}/contract_lines`, setContractLines, setContractLinesLoaded);
    fetchContractsApi(`/quotation/config/header_image`, setHeaderImage, setHeaderImageLoaded);
  }, [id, debtorID]);

  return (
    <>
    {contract && contractLoaded ?(
      <form onSubmit={formValidate}>
        <div className={styles.form_container}>
          <br />
          <label className={styles.heading}>PART B</label>
          <br />
          <>
          <br />
          <label>Name:</label>
          <input
            type="text"
            name="firstname"
            onChange={(event) => SetSignedName(event.target.value)}
          />
          <br />
          <span className={styles.errormsg}>{errorName}</span>
          <br />
          <label>Surname:</label>
          <input
            type="text"
            onChange={(event) => setSignedSurname(event.target.value)}
          />
          <br />
          <span className={styles.errormsg}>{errorSurname}</span>
          <br />
          <label>ID No:</label>
          <input
            type="text"
            onChange={(event) => setSignedId(event.target.value)}
            pattern="^-?[0-9]\d*"
          />
          <br />
          <span className={styles.errormsg}>{errorIdno}</span>
          <br />
          <label>Role:</label>
          <input
            type="text"
            onChange={(event) => setSignedRole(event.target.value)}
          />
          <br />
          <span className={styles.errormsg}>{errorRole}</span>
          <br />
          <label>Cellphone No:</label>
          <input
            type="text"
            className={styles.request_otp}
            onChange={(event) => setSignedCellNo(event.target.value)}
          />
          <button className={styles.btnotp_request} onClick={sendOTP}>
            <FontAwesomeIcon icon={faCheck} color="white" />
            Send OTP
          </button>
          <br />
          <span className={styles.errormsg}>{errorCellNo}</span>
          <br />
          <OTPInput state={[signedOtp, setSignedOtp]} setMessage={setMessage} disabled={disabled} />
          <br />
          <span className={styles.errormsg}>{errorOtp}</span>
          <br />
          <label>Signed Time:</label>
          <input
            type="date"
            defaultValue={CurrentDateTime()}
            onChange={(event) => setSignedTime(event.target.value)}
          />
          <br />
          <span className={styles.errormsg}>{errorTime}</span>
          <br />
          <label>Signed Place:</label>
          <input
            type="text"
            onChange={(event) => setSignedPlace(event.target.value)}
          />
          <br />
          <span className={styles.errormsg}>{errorPlace}</span>
          <br />
          <label>Address:</label>
          <textarea cols={1} rows={1}
            defaultValue={contract.signed_address} disabled={true}
          />
          </>
          </div>
          <br />
          <span className={styles.errormsg}>{message}</span>
          <div className={styles.acceptance}>
          <h4>Acceptance of Terms of Agreement</h4>
          <p>
          <input type="checkbox"
            name="agreement_checked"
            onChange={() => setAgreementCheck(!agreementCheck)}
            required={true}
          />
          <input name="agreement_contact"
            placeholder={"Enter Agreement Full Name"}
            onChange={(event) => setAgreementContact(event.target.value)}
            required={true}
          />
          , by clicking on the accept button, confirm that the information</p>
          <p>supplied is correct, that I have read and accept the terms of this GoodX Software</p>
          <p>Standard Licence, Services & Operator Agreement read together with the General</p>
          <p>Terms & Conditions, Policies and Standard Operating Procedures as amended from</p>
          <p>time to time on the official GoodX website, and that I have the authority to sign on</p>
          <p>behalf of the Client.</p>
          <span className={styles.acceptance_err}>{acceptContactErr}</span>
        </div>
        <button className={styles.btn_submit} onClick={contractSubmit}>
          <FontAwesomeIcon icon={faCheck} color="white" />
          Save / Submit
        </button>
        <ToastContainer />
        {contractLinesLoaded && isHeaderImageLoaded ? (
        <PDFViewer style={viewer.page}>
          <ContractTemplate props={contract}  line_detail={contractLines} headerImages={headerImage.header}/>
        </PDFViewer>
        ) : (
          LoadingContract
        )}
    </form>
    ):InvalidContract}
    </>
  );
};

export default Contracts;
